/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import LoginModalContainer from '../../../src/components/login-modal';
import { TierType, TokenType } from '../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { checkIcon } from '../../images';
import { Modal } from '../../components';
import { Paths } from '../../utils';
import { Game, GameRegisterInfo } from '../../core';
import { getUserTotalLots } from 'src/logic/actions';
import { powToNumber } from 'src/utils/number';
import { toast } from 'react-toastify';
interface Params {
  id: string;
}
 
const PoolRegistration = () => {
  const dispatch = useDispatch()
  const { id } = useParams<Params>();
  const [useremail, setuseremail] = useState('');
  const [username, setusername] = useState('');
  const [twiterhandle, settwiterhandle] = useState('');
  const [remarks, setremarks] = useState('');
  const [showMsg, setShowMsg] = useState(false);
  const [termscondition, settermscondition] = useState(false);
  const [updates, setupdates] = useState(false);
  const [receivealert, setreceivealert] = useState(true);
  const [submitname, setsubmitname] = useState(false);
  const [submitemail, setsubmitemail] = useState(false);
  const [gotoFeed, setgotoFeed] = useState(false);
  const [retweet, setretweet] = useState(false);
  const [join, setjoin] = useState(false);
  const [tier, setTier] = useState(-1);
  const [gameDetail, setGameDetail] = useState<any>();
  const [totalRaise, setTotalRaise] = useState('0');
  const [tierlist, setTierlist] = useState<Array<any>>([]);
 
  const {
    totalKroyaLot,
    totalAroyaLot,
    totalSroyaLot,
    totalMroyaLot,
    totalMoroyaLot,
    totalSNFTLot,
    totalMNFTLot,
    totalKNFTLot,
    totalANFTLot,
    totalMoNFTLot
  } = useSelector((state: any) => state.stakingLots);
 
  const updategotofeed = () => {
    if (gotoFeed === false) {
      setgotoFeed(true);
      window.open(`https://twitter.com/${gameDetail?.twitter}`, '_blank');
    }
  };
  const updateretweet = () => {
    if (retweet === false) {
      setretweet(true);
      window.open(`https://twitter.com/${gameDetail?.twitter}`, '_blank');
    }
  };
 
  const updatejoin = () => {
    if (join === false) {
      setjoin(true);
      window.open(`https://twitter.com/${gameDetail?.twitter}`, '_blank');
    }
  };
 
  const { userAddress, walletConnected, kycVerified } = useSelector(
    (state: any) => state.user
  );
  const history = useHistory();
  const [loginshow, setloginShow] = useState(false);
 
  useEffect(() => {
    if(walletConnected && kycVerified !== "VERIFIED"){
      toast('Please complete your KYC to perform this action!', {
        type: toast.TYPE.WARNING
      });
      history.push("/account/kyc");
    }
  }, [walletConnected, kycVerified]);
 
  const closeModal = () => {
    setShowMsg(false);
    // history.push(`${Paths.dashboard}`);
  };
 
  const getValuefortermsconditions = () => {
    if (termscondition === false) {
      settermscondition(true);
      setreceivealert(true);
    } else {
      settermscondition(false);
    }
  };
  const getValueforupdate = () => {
    if (updates === false) {
      setupdates(true);
    } else {
      setupdates(false);
    }
  };
 
  const getGameDetail = async () => {
    const { error, result } = await Game.findGameById(+id);
    if (!error && result) {
      setGameDetail(result);
      const poolInfo = result.poolInfo;
      if (poolInfo) {
        setTotalRaise(
          (
            +powToNumber(poolInfo.totalTokens) /
            Math.pow(10, poolInfo.tokenDecimals) /
            +poolInfo.tokenPrice
          ).toFixed(4)
        );
      }
    }
  };
 
  useEffect(() => {
    if (walletConnected && id) {
      getGameDetail();
      dispatch(getUserTotalLots(userAddress));
    }
  }, [walletConnected, id]);
 
  const updateTierList = async () => {
    const list = [];
    if (+totalSNFTLot + +totalSroyaLot > 0) {
      list.push({
        tokenId: TierType.settler,
        tokenQuantity: +totalSNFTLot + +totalSroyaLot
      });
    }
    if (+totalMNFTLot + +totalMroyaLot > 0) {
      list.push({
        tokenId: TierType.merchant,
        tokenQuantity: +totalMNFTLot + +totalMroyaLot
      });
    }
    if (+totalKNFTLot + +totalKroyaLot > 0) {
      list.push({
        tokenId: TierType.knight,
        tokenQuantity: +totalKNFTLot + +totalKroyaLot
      });
    }
    if (+totalANFTLot + +totalAroyaLot > 0) {
      list.push({
        tokenId: TierType.archon,
        tokenQuantity: +totalANFTLot + +totalAroyaLot
      });
    }
    if (+totalMoNFTLot + +totalMoroyaLot > 0) {
      list.push({
        tokenId: TierType.monarch,
        tokenQuantity: +totalMoNFTLot + +totalMoroyaLot
      });
    }
    setTierlist(list);
    if (list.length > 0) {
      setTier(list[0].tokenId);
    }
  };
 
  useEffect(() => {
    updateTierList();
  }, [
    totalKroyaLot,
    totalAroyaLot,
    totalSroyaLot,
    totalMroyaLot,
    totalMoroyaLot,
    totalSNFTLot,
    totalMNFTLot,
    totalKNFTLot,
    totalANFTLot,
    totalMoNFTLot
  ]);
 
  const getInputValue = async () => {
    if (walletConnected) {
      if (username === '') {
        setsubmitname(true);
      }
      if (useremail === '') {
        setsubmitemail(true);
      }
      if (username !== '' && useremail !== '') {
        const payload = {
          name: username,
          email: useremail,
          twitter: twiterhandle,
          isFollow: true,
          retweet: true,
          join: true,
          feedback: remarks,
          tier: tier
        };
        const result = await GameRegisterInfo.addRegisterInfo(+id, payload);
        if (!result.error) {
          setShowMsg(true);
        } else {
          toast(result.error, { type: toast.TYPE.ERROR });
        }
      } else {
        console.log('Failed');
      }
    } else {
      setloginShow(true);
    }
  };
 
  return (
    <>
      <div className="container">
        <div className="apply-wrap">
          <div className="game-apply-info">
            <div className="game-apply-img">
              <img src={gameDetail?.logo} alt="" />
            </div>
            <div className="game-apply-content">
              <h4>{gameDetail?.name}</h4>
              <span className="game-dark-info">
                {gameDetail?.poolInfo?.tokenSymbol}/
                {TokenType[gameDetail?.poolInfo?.tokenVarious || 0]}
              </span>
            </div>
          </div>
          <div className="game-apply-iteminfo">
            <div className="game-apply-list">
              <span className="apply-list-label">Total Raise</span>
              <span className="apply-list-labelinfo">
                {totalRaise}
                {TokenType[gameDetail?.poolInfo?.tokenVarious || 0]}
              </span>
            </div>
            <div className="game-apply-list">
              <span className="apply-list-label">Personal Allocation</span>
              <span className="apply-list-labelinfo">
                {gameDetail?.poolInfo?.min1}-
                {gameDetail?.poolInfo && gameDetail?.poolInfo?.max5 > 0
                  ? gameDetail?.poolInfo?.max5
                  : 'Unlimited'}
              </span>
            </div>
            <div className="game-apply-list">
              <span className="apply-list-label">IDO Date</span>
              <span className="apply-list-labelinfo">
                {gameDetail?.poolInfo
                  ? new Date(gameDetail?.poolInfo?.startTime).toDateString()
                  : 'XXXX'}
              </span>
            </div>
          </div>
 
          <div className="join-safelist-box">
            <h4>Join The Safelist</h4>
            <p>{gameDetail && gameDetail.description}</p>
 
            <div className="join-safelist-step">
              <h3>Basic Information</h3>
              <div className="submit-form-group">
                <label className="submit-label">Full Name</label>
                <input
                  type="text"
                  className="submit-form-control"
                  onChange={(event) => setusername(event.target.value)}
                  onClick={(event) => setsubmitname(false)}
                />
                {submitname === true && (
                  <span className="alert">Please input your name</span>
                )}
              </div>
              <div className="submit-form-group">
                <label className="submit-label">Email</label>
                <input
                  type="text"
                  className="submit-form-control"
                  onChange={(event) => setuseremail(event.target.value)}
                  onClick={() => setsubmitemail(false)}
                />
                {submitemail === true && (
                  <span className="alert">Please input your email</span>
                )}
              </div>
              <div className="submit-form-group">
                <label className="submit-label">
                  ERC20/BEP20 Wallet Address
                </label>
                <input
                  type="text"
                  className="submit-form-control"
                  value={userAddress}
                  disabled
                />
              </div>
              <div className="submit-form-group">
                <label className="submit-label">Your Twitter Handle</label>
                <input
                  type="text"
                  className="submit-form-control"
                  onChange={(event) => settwiterhandle(event.target.value)}
                />
              </div>
            </div>
 
            <div className="join-safelist-step">
              <h3>Available tier</h3>
              <div className="submit-form-group">
                <label className="submit-label">
                  Choose yor tier from the tier list
                </label>
                <select
                  className="submit-form-control"
                  onChange={(e) => setTier(+e.target.value)}
                >
                  {tierlist && tierlist.length > 0 ? (
                    tierlist.map((item: any, index: number) => (
                      <>
                        {item.tokenId === TierType.settler && (
                          <option key={index} value={TierType.settler}>
                            Settler * {item.tokenQuantity}
                          </option>
                        )}
                        {item.tokenId === TierType.merchant && (
                          <option key={index} value={TierType.merchant}>
                            Merchant * {item.tokenQuantity}
                          </option>
                        )}
                        {item.tokenId === TierType.knight && (
                          <option key={index} value={TierType.knight}>
                            Knight * {item.tokenQuantity}
                          </option>
                        )}
                        {item.tokenId === TierType.archon && (
                          <option key={index} value={TierType.archon}>
                            Archon * {item.tokenQuantity}
                          </option>
                        )}
                        {item.tokenId === TierType.monarch && (
                          <option key={index} value={TierType.monarch}>
                            Monarch * {item.tokenQuantity}
                          </option>
                        )}
                      </>
                    ))
                  ) : (
                    <option value="-1">No Tier Available</option>
                  )}
                </select>
                {submitname === true && (
                  <span className="alert">Please submit any Tier</span>
                )}
                {tierlist.length === 0 && (
                  <span className="d-block alert-white">
                    You have no tiers available, hence application cannot be
                    submitted. Purchase tier to submit it
                  </span>
                )}
              </div>
            </div>
 
            <div className="join-safelist-step">
              <h3>Social Media</h3>
              <div className="apply-media-list">
                <div className="apply-media-display">
                  <div className="apply-media-icon">
                    <svg
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24 4.72798C23.1 5.12798 22.2 5.42798 21.2 5.52798C22.2 4.92798 23 3.92798 23.4 2.82798C22.4 3.42798 21.4 3.82798 20.3 4.02798C19.4 3.02798 18.1 2.42798 16.7 2.42798C14 2.42798 11.8 4.62798 11.8 7.32798C11.8 7.72798 11.8 8.12798 11.9 8.42798C7.7 8.22798 4.1 6.22798 1.7 3.22798C1.2 4.02798 1 4.82798 1 5.72798C1 7.42798 1.9 8.92798 3.2 9.82798C2.4 9.82798 1.6 9.62798 1 9.22798C1 9.22798 1 9.22798 1 9.32798C1 11.728 2.7 13.728 4.9 14.128C4.5 14.228 4.1 14.328 3.6 14.328C3.3 14.328 3 14.328 2.7 14.228C3.3 16.228 5.1 17.628 7.3 17.628C5.6 18.928 3.5 19.728 1.2 19.728C0.8 19.728 0.4 19.728 0 19.628C2.2 21.028 4.8 21.828 7.5 21.828C16.6 21.828 21.5 14.328 21.5 7.82798C21.5 7.62798 21.5 7.42798 21.5 7.22798C22.5 6.52798 23.3 5.62798 24 4.72798Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="apply-social-info">
                    <h5>Follow</h5>
                    <span>{gameDetail?.name} Twitter</span>
                  </div>
                </div>
                <div className="apply-media-action">
                  <div className="feed-action">
                    <button
                      type="button"
                      className="feed-action-link btn-action-link"
                      onClick={updategotofeed}
                    >
                      Go to Feed
                    </button>
                  </div>
                  <div className="did-action">
                    {gotoFeed === false && (
                      <button
                      type="button"
                      className={`btn-action-link`}
                      onClick={()=> setgotoFeed(true)}
                    >
                    I DID THIS
                    </button>
                    )}
                    {gotoFeed === true && (
                      <img src={checkIcon.default} alt="" />
                    )}
                  </div>
 
                  {/* <div className="did-action">
                    <img src={checkIcon.default} alt="" />
                  </div> */}
                </div>
              </div>
              <div className="apply-media-list">
                <div className="apply-media-display">
                  <div className="apply-media-icon">
                    <svg
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24 4.72798C23.1 5.12798 22.2 5.42798 21.2 5.52798C22.2 4.92798 23 3.92798 23.4 2.82798C22.4 3.42798 21.4 3.82798 20.3 4.02798C19.4 3.02798 18.1 2.42798 16.7 2.42798C14 2.42798 11.8 4.62798 11.8 7.32798C11.8 7.72798 11.8 8.12798 11.9 8.42798C7.7 8.22798 4.1 6.22798 1.7 3.22798C1.2 4.02798 1 4.82798 1 5.72798C1 7.42798 1.9 8.92798 3.2 9.82798C2.4 9.82798 1.6 9.62798 1 9.22798C1 9.22798 1 9.22798 1 9.32798C1 11.728 2.7 13.728 4.9 14.128C4.5 14.228 4.1 14.328 3.6 14.328C3.3 14.328 3 14.328 2.7 14.228C3.3 16.228 5.1 17.628 7.3 17.628C5.6 18.928 3.5 19.728 1.2 19.728C0.8 19.728 0.4 19.728 0 19.628C2.2 21.028 4.8 21.828 7.5 21.828C16.6 21.828 21.5 14.328 21.5 7.82798C21.5 7.62798 21.5 7.42798 21.5 7.22798C22.5 6.52798 23.3 5.62798 24 4.72798Z"
                        fill="white"
                      />
                    </svg>
                  </div>
 
                  <div className="apply-social-info">
                    <h5>Retweet</h5>
                    <span>{gameDetail?.name} Twitter</span>
                  </div>
                </div>
                <div className="apply-media-action">
                  <div className="feed-action">
                    <button
                      type="button"
                      className="feed-action-link btn-action-link"
                      onClick={updateretweet}
                    >
                      Go to Tweet
                    </button>
                  </div>
                  <div className="did-action">
                    {retweet === false && (
                         <button
                         type="button"
                         className={`btn-action-link`}
                         onClick={()=> setretweet(true)}
                       >
                       I DID THIS
                       </button>
                    )}
                    {retweet === true && <img src={checkIcon.default} alt="" />}
                  </div>
                </div>
              </div>
              <div className="apply-media-list">
                <div className="apply-media-display">
                  <div className="apply-media-icon">
                    <svg
                      viewBox="0 0 24 25"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M24 4.72798C23.1 5.12798 22.2 5.42798 21.2 5.52798C22.2 4.92798 23 3.92798 23.4 2.82798C22.4 3.42798 21.4 3.82798 20.3 4.02798C19.4 3.02798 18.1 2.42798 16.7 2.42798C14 2.42798 11.8 4.62798 11.8 7.32798C11.8 7.72798 11.8 8.12798 11.9 8.42798C7.7 8.22798 4.1 6.22798 1.7 3.22798C1.2 4.02798 1 4.82798 1 5.72798C1 7.42798 1.9 8.92798 3.2 9.82798C2.4 9.82798 1.6 9.62798 1 9.22798C1 9.22798 1 9.22798 1 9.32798C1 11.728 2.7 13.728 4.9 14.128C4.5 14.228 4.1 14.328 3.6 14.328C3.3 14.328 3 14.328 2.7 14.228C3.3 16.228 5.1 17.628 7.3 17.628C5.6 18.928 3.5 19.728 1.2 19.728C0.8 19.728 0.4 19.728 0 19.628C2.2 21.028 4.8 21.828 7.5 21.828C16.6 21.828 21.5 14.328 21.5 7.82798C21.5 7.62798 21.5 7.42798 21.5 7.22798C22.5 6.52798 23.3 5.62798 24 4.72798Z"
                        fill="white"
                      />
                    </svg>
                  </div>
                  <div className="apply-social-info">
                    <h5>Join</h5>
                    <span>{gameDetail?.name} Twitter</span>
                  </div>
                </div>
                <div className="apply-media-action">
                  <div className="feed-action">
                    <button
                      type="button"
                      className="feed-action-link btn-action-link"
                      onClick={updatejoin}
                    >
                      Join Channel
                    </button>
                  </div>
                  <div className="did-action">
                    {join === false &&    <button
                         type="button"
                         className={`btn-action-link`}
                         onClick={()=> setjoin(true)}
                       >
                       I DID THIS
                       </button>}
                    {join === true && <img src={checkIcon.default} alt="" />}
                  </div>
                </div>
              </div>
            </div>
            <div className="join-safelist-step">
              <h3>About {gameDetail?.name}</h3>
              <div className="submit-form-group">
                <label className="submit-label">
                  How awesome is {gameDetail && gameDetail.name}?
                </label>
                <input
                  type="text"
                  className="submit-form-control"
                  onChange={(event) => setremarks(event.target.value)}
                />
              </div>
              <div className="submit-form-group">
                <label className="submit-label">
                  Do you agree with the{' '}
                  <a href="/terms">Terms and Conditions</a>
                </label>
                <div className="apply-checkbox-listnew">
                  <label className="checkbox-label">
                    <span>I accept the Terms of Service</span>
                    <input
                      type="checkbox"
                      id="terms"
                      checked={termscondition}
                      onClick={getValuefortermsconditions}
                    />
                    <span className="mark"></span>
                  </label>
                  {receivealert !== true && (
                    <span className="alert">
                      (Please select terms of service)
                    </span>
                  )}
                </div>
              </div>
              <div className="submit-form-group">
                <label className="submit-label">
                  Do you want to receive updates from{' '}
                  {gameDetail && gameDetail.name}
                </label>
                <div className="apply-checkbox-listnew">
                  <label className="checkbox-label">
                    <span>
                      Yes, I agree to receive updates from{' '}
                      {gameDetail && gameDetail.name} in the future
                    </span>
                    <input
                      type="checkbox"
                      id="agree"
                      alt=""
                      onClick={getValueforupdate}
                    />
                    <span className="mark1"></span>
                  </label>
                </div>
              </div>
 
              <div className="submit-apply-action">
                <button
                  type="button"
                  className={`btn-action-link ${
                    !termscondition && 'noncursor-pointer'
                  }`}
                  onClick={getInputValue}
                  disabled={!termscondition}
                >
                  Submit Your Application
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={showMsg}
        closeModal={() => {
          closeModal();
          history.push(`${Paths.account}/participating-game`);
        }}
        className='pb-5'
      >
        <div className="register-innerbg">
          <h3>Thank You</h3>
          <div className="successfully">
            <h4>Your registration request has been submitted successfully.</h4>
           <div className='d-flex justify-content-center align-items-center'>
           <button
                  type="button"
                  className={`btn-action-link mt-4 p-3`}
                  onClick={()=>{
                    closeModal();
                    history.push(`${Paths.account}/participating-game`);
                  }}
                >
                  Ok
                </button>
           </div>
          </div>
        </div>
      </Modal>
 
      <Modal show={loginshow} closeModal={closeModal}>
        <LoginModalContainer show={loginshow} closeModal={closeModal} />
      </Modal>
    </>
  );
};
 
export default PoolRegistration;
