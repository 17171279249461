/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import moment from "moment";
import ReactPlayer from "react-player";
import { Paths, TokenType, getPoolContract } from "../../utils";
import { Loader, Modal } from "../../components";
import {
  Newgamesidebar,
  topbackcover,
  topbackcover1,
  topbackcover2,
  Leftbackcover,
  Rightbackcover,
  Pit1,
  Pit2,
  Pit3,
  Pit4,
  placeHolder,
} from "../../images";
import LoginModalContainer from "../../components/login-modal";
import { Game, GameRegisterInfo } from "../../core";

import "./styles.scss";
import { powToNumber, withFiveDecimals } from "src/utils/number";

interface Params {
  id: string;
}

const GameDetails = () => {
  const { userAddress, walletConnected } = useSelector(
    (state: any) => state.user
  );
  const { id }: Params = useParams();

  const [isRegistered, setIsRegistered] = useState(false);
  const [ShowAlert, setShowAlert] = useState(false);
  const [showWallet, setshowWallet] = useState(false);
  const [whitelist, setWhitelist] = useState([]);
  const [gameDetail, setGameDetail] = useState<any>();
  const [totalRaise, setTotalRaise] = useState("");
  const [isWhitelisted, setWhitelisted] = useState(false);
  const [isVideoList, setIsVideoList] = useState([]);
  const [isSelectedVideo, setIsSelectedVideo] = useState("");
  const history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [walletConnected, userAddress]);
  useEffect(() => {
    if (walletConnected && gameDetail) {
      if (gameDetail.poolInfo && gameDetail.poolInfo.poolAddress) {
        getTokenAllocated();
        getWhitelistDetail();
        fetchWhiteListed();
      }
    }
  }, [walletConnected, gameDetail]);

  const closeModal = () => {
    setshowWallet(false);
    setShowAlert(false);
  };

  const getGameDetail = async () => {
    const { error, result } = await Game.findGameById(+id);
    if (!error && result) {
      setGameDetail(result);
      console.log('original video', result?.video)
      const parsedVideo = JSON.parse(result?.video || '[]');
      if(result?.videoLink && !!parsedVideo?.length){
        setIsSelectedVideo(result?.videoLink);
        console.log('video lis', result?.videoLink)
        setIsVideoList([result?.videoLink, ...parsedVideo]);
      }else{
        if(result?.videoLink){
          setIsVideoList([result?.videoLink]);
          setIsSelectedVideo(result?.videoLink);
        }else if(!!parsedVideo?.length){
          setIsVideoList([...parsedVideo]);
          setIsSelectedVideo(parsedVideo[0]);
        }
      }
      const poolInfo = result.poolInfo;
      if (poolInfo) {
        setTotalRaise(
          (
            +powToNumber(poolInfo.totalTokens) /
            Math.pow(10, poolInfo.tokenDecimals) /
            +poolInfo.tokenPrice
          ).toFixed(4)
        );
      }
    }
  };

  const getWhitelistDetail = async () => {
    const whitelist = await getPoolContract(gameDetail!.poolInfo?.poolAddress)
      .methods.getWhitelistedAddresses()
      .call();
      console.log({whitelist})
    setWhitelist(whitelist);
  };

  const fetchWhiteListed = async () => {
    if (gameDetail!.poolInfo?.poolAddress) {
      const whitelisted = await getPoolContract(
        gameDetail!.poolInfo?.poolAddress
      )
        .methods.isWhitelisted(userAddress)
        .call();
      setWhitelisted(whitelisted);
    }
  };

  const getTokenAllocated = async () => {
    await getPoolContract(gameDetail!.poolInfo?.poolAddress)
      .methods.tokensLeft()
      .call();
  };

  const checkIsRegistered = async () => {
    const result = await GameRegisterInfo.checkIsRegistered(+id);
    if (!result.error) {
      setIsRegistered(result.result);
    }
  };

  useEffect(() => {
    if (id) {
      getGameDetail();
      checkIsRegistered();
    }
  }, [id]);

  const goToRegistrationPage = () => {
    if (walletConnected) {
      if (userAddress === gameDetail?.user?.walletAddress) {
        history.push(`${Paths.gameDetails}/${id}/edit`);
      } else {
        history.push(`${Paths.poolregistration}/${id}`);
      }
    } else {
      setshowWallet(true);
    }
  };

  const handleJoinSale = () => {
    if (walletConnected) {
      history.push(`${Paths.poolDetails}/${id}`);
    } else {
      setshowWallet(true);
    }
  };

  return (
    <div className="game-details">
      <Modal show={showWallet} closeModal={closeModal}>
        <LoginModalContainer show={showWallet} closeModal={closeModal} />
      </Modal>
      {gameDetail && (
        <div className="game-details-banner-wrap">
          <img src={Newgamesidebar.default} alt="" />
        </div>
      )}
      {gameDetail ? (
        <div className="details-row">
          <div className="new-page-frame">
            <img src={topbackcover.default} alt="" />
          </div>
          <div className="new-page-frame1">
            <img src={topbackcover1.default} alt="" />
          </div>
          <div className="new-page-frame2">
            <img src={topbackcover2.default} alt="" />
          </div>
          <div className="container">
            <div className="game-detail-info">
              <div className="game-apply-img">
                <img
                  src={gameDetail.logo ? gameDetail.logo : gameDetail?.tokenURL ? gameDetail?.tokenURL :placeHolder.default}
                  alt=""
                />
              </div>
              <div className="game-apply-content">
                <h4>{gameDetail?.name}</h4>
                <span className="game-dark-info mt-1 mb-2">
                  {gameDetail?.poolInfo?.tokenSymbol || "XXXX"}/
                  {gameDetail?.poolInfo?.tokenVarious ? TokenType[gameDetail?.poolInfo?.tokenVarious] : "XXXX"}
                </span>
                <div className="game-submit-col-mobile">
                  <button
                    disabled={isRegistered}
                    onClick={() => goToRegistrationPage()}
                    className={`game-action-link ${
                      isRegistered && "noncursor-pointer"
                    }`}
                  >
                    {userAddress === gameDetail.user?.walletAddress
                      ? "Edit"
                      : isRegistered
                      ? "Applied"
                      : "Apply Now"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="New-game-left-back-cover">
            <img src={Leftbackcover.default} alt="" />
          </div>
          <div className="New-game-right-back-cover">
            <img src={Rightbackcover.default} alt="" />
          </div>

          <div className="game-details-container">
            <div className="container">
              <div className="game-details-grid">
                <div className="main-width">
                  <div className="border-box-wrap about-project-box">
                    <div className="project-video-wrap">
                      <ReactPlayer
                        key={isSelectedVideo + "react-player"}
                        url={isSelectedVideo}
                        className="react-player"
                        controls={true}
                      />
                    </div>
                    {!!isVideoList?.length && (
                      <div className="video-preview">
                        {isVideoList?.map((videoUrl, index) => (
                          <div
                            key={videoUrl + index}
                            onClick={() => setIsSelectedVideo(videoUrl)}
                            className="cursor-pointer"
                          >
                            <ReactPlayer
                              url={videoUrl}
                              className="react-player me-3 pe-none"
                              controls={false}
                              key={videoUrl + index}
                              height={"80px"}
                              width={"150px"}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  <div className="about-project-box mt-5">
                    <div className="about-top-row1 text-center">
                      <h3>About</h3>
                      <h3>{gameDetail?.name}</h3>
                      <div className="description">
                        {gameDetail.description}
                      </div>
                      <div className="social-links">
                        <a
                          rel="noreferrer"
                          href={"https://t.me/" + gameDetail.telegram}
                          target="_blank"
                        >
                          <svg
                            width="25"
                            height="22"
                            viewBox="0 0 25 22"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M24.7754 1.25693C24.7561 1.16254 24.7137 1.07529 24.6524 1.0041C24.5912 0.932901 24.5133 0.880307 24.4267 0.851685C24.1115 0.785447 23.7855 0.810285 23.4824 0.923623C23.4824 0.923623 2.45462 8.97825 1.25387 9.87028C0.994367 10.0621 0.908117 10.1732 0.865367 10.3051C0.657616 10.9398 1.30412 11.2195 1.30412 11.2195L6.72363 13.1019C6.81518 13.1187 6.90914 13.1127 6.99813 13.0843C8.23114 12.2546 19.4024 4.73632 20.0482 4.48374C20.1494 4.45097 20.2244 4.48374 20.2079 4.56367C19.9439 5.52764 10.2966 14.6645 10.2434 14.7205C10.2175 14.7431 10.1972 14.7721 10.1841 14.805C10.171 14.8378 10.1656 14.8736 10.1684 14.9091L9.66439 20.545C9.66439 20.545 9.45289 22.3035 11.0999 20.545C12.2676 19.2989 13.3881 18.2678 13.9499 17.7666C15.8137 19.1375 17.8192 20.6537 18.6839 21.4435C18.829 21.5937 19.001 21.7111 19.1897 21.7889C19.3783 21.8666 19.5798 21.903 19.7819 21.8959C20.6069 21.8623 20.8319 20.9007 20.8319 20.9007C20.8319 20.9007 24.6629 4.47095 24.7919 2.26886C24.8039 2.05305 24.8212 1.91476 24.8227 1.76689C24.8295 1.59538 24.8136 1.42371 24.7754 1.25693V1.25693Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                        <a
                          rel="noreferrer"
                          href={"https://twitter.com/" + gameDetail.twitter}
                          target="_blank"
                        >
                          <svg
                            viewBox="0 0 24 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M24 4.72798C23.1 5.12798 22.2 5.42798 21.2 5.52798C22.2 4.92798 23 3.92798 23.4 2.82798C22.4 3.42798 21.4 3.82798 20.3 4.02798C19.4 3.02798 18.1 2.42798 16.7 2.42798C14 2.42798 11.8 4.62798 11.8 7.32798C11.8 7.72798 11.8 8.12798 11.9 8.42798C7.7 8.22798 4.1 6.22798 1.7 3.22798C1.2 4.02798 1 4.82798 1 5.72798C1 7.42798 1.9 8.92798 3.2 9.82798C2.4 9.82798 1.6 9.62798 1 9.22798C1 9.22798 1 9.22798 1 9.32798C1 11.728 2.7 13.728 4.9 14.128C4.5 14.228 4.1 14.328 3.6 14.328C3.3 14.328 3 14.328 2.7 14.228C3.3 16.228 5.1 17.628 7.3 17.628C5.6 18.928 3.5 19.728 1.2 19.728C0.8 19.728 0.4 19.728 0 19.628C2.2 21.028 4.8 21.828 7.5 21.828C16.6 21.828 21.5 14.328 21.5 7.82798C21.5 7.62798 21.5 7.42798 21.5 7.22798C22.5 6.52798 23.3 5.62798 24 4.72798Z"
                              fill="white"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                    {/* <div className="project-info">
                      <ReactQuill
                        value={gameDetail?.fullDescription}
                        readOnly={true}
                        theme={"bubble"}
                      />
                    </div> */}
                    {/* <div className="pitchDecklink-wrapper mt-1">
                      <img src={PDFIcon.default} />
                      <a
                        rel="noreferrer"
                        href={gameDetail?.pitchDeckLink}
                        target="_blank"
                      >
                        {gameDetail?.pitchDeckLink.split("/")[4]}
                      </a>
                    </div> */}

{/* Pit1 */}
                    <div className="about-top-row1 text-center mt-2">
                      <h3>Pitchdeck</h3>
                      <div className="description">
                        {gameDetail?.pitchDeckLink}
                      </div>
                      <div className="row mt-3">
                        <div className="col-3">
                          <div className="pitch">

                          <img src={Pit1.default} alt="" />
                          <p>Sint optio dolorem. Nemo reiciendis quidem eaque alias esse</p>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="pitch">
                          <img src={Pit2.default} alt="" />
                          <p>Sint optio dolorem. Nemo reiciendis quidem eaque alias esse</p>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="pitch">
                          <img src={Pit3.default} alt="" />
                          <p>Sint optio dolorem. Nemo reiciendis quidem eaque alias esse</p>
                          </div>
                        </div>
                        <div className="col-3">
                          <div className="pitch">
                          <img src={Pit4.default} alt="" />
                          <p>Sint optio dolorem. Nemo reiciendis quidem eaque alias esse</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-box-wrap mt-3">
                      <div className="about-winnning-row">
                        <h3>Whitelist addresses</h3>
                        <div className="table-responsive">
                          <table className="table whitelist-table">
                            <thead className="whitelist-table_thead">
                              <tr>
                                <th>Address</th>
                              </tr>
                            </thead>
                            <tbody>
                              {whitelist.length > 0 &&
                                whitelist.map((address, index) => (
                                  <tr key={index}>
                                    <td>{address}</td>
                                  </tr>
                                ))}
                              {whitelist.length === 0 && (
                                <div className="loading-container">
                                  No winning address yet
                                </div>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="details-sidebar">
                  <div className="border-box-wrap">
                    <div className="details-sidebar-box">
                      <div className="sidebar-original-info">
                        <h3>
                          {gameDetail?.poolInfo?.tokenSymbol
                            ? gameDetail.poolInfo.tokenSymbol
                            : "XXXXXX"}{" "}
                          Pool
                        </h3>
                      </div>
                      <div className="sidebar-details-02">
                        {gameDetail.poolInfo ? (
                          <>
                            <div className="details-info-raised">
                              <div className="row">
                                <div className="col text-start">
                                  Total Raise
                                </div>
                                <div className="col text-end">{totalRaise} {TokenType[gameDetail.poolInfo.tokenVarious]}</div>
                              </div>
                              <div className="row">
                                <div className="col text-start">
                                  Participants
                                </div>
                                <div className="col text-end">{gameDetail.totalSafelist}</div>
                              </div>
                              <div className="row">
                                <div className="col text-start">
                                  IPO Token Price
                                </div>
                                <div className="col text-end">{withFiveDecimals(1 / parseInt(gameDetail.poolInfo.tokenPrice))} {TokenType[gameDetail.poolInfo.tokenVarious]}</div>
                              </div>
                              <div className="row">
                                <div className="col text-start">
                                  Token Sales
                                </div>
                                <div className="col text-end">{gameDetail.poolInfo.totalRaise ? gameDetail.poolInfo.totalRaise : 0} {TokenType[gameDetail.poolInfo.tokenVarious]}</div>
                              </div>
                              <div className="row">
                                <div className="col text-start">
                                  Total Tokens
                                </div>
                                <div className="col text-end">{(gameDetail.poolInfo.totalTokens) / Math.pow(10, gameDetail.poolInfo.tokenDecimals)} {gameDetail.poolInfo.tokenSymbol}</div>
                              </div>
                            </div>
                            <div className="New-details-info-percent">
                              <div
                                className="percent-count"
                                style={{ width: ((parseInt(gameDetail.poolInfo.totalRaise ? gameDetail.poolInfo.totalRaise : '0') / parseInt(totalRaise)) * 100) + '%' }}
                              ></div>
                            </div>
                            <div className="New-submit-col1">
                              {userAddress !==
                                gameDetail.user?.walletAddress && (
                                <button
                                  disabled={!isWhitelisted}
                                  onClick={handleJoinSale}
                                  className={`New-btn-action-link1 ${
                                    isWhitelisted
                                      ? "cursor-pointer"
                                      : "noncursor-pointer"
                                  }`}
                                >
                                  JOIN SALE
                                </button>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="New-game-dark-coin">
                            <h4>Pool Details:</h4>
                            <p>Coming Soon</p>
                          </div>
                        )}
                      </div>
                      <div className="sidebar-details-03">
                        {gameDetail.poolInfo ? (
                          <>
                            <span>
                              From&nbsp;
                              {moment(gameDetail.poolInfo.startTime).format(
                                "DD-MM-YYYY hh:mm"
                              )}{" "}
                              To &nbsp;
                              {moment(gameDetail.poolInfo.endTime).format(
                                "DD-MM-YYYY hh:mm"
                              )}{" "}
                            </span>
                          </>
                        ) : (
                          <div className="New-game-dark-coin">
                            <span>IPO info: Coming Soon</span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {!gameDetail.poolInfo &&
                    gameDetail.user?.walletAddress.toLowerCase() ===
                      userAddress.toLowerCase() && (
                      <div className="new-geme-details-sidebar-box">
                        <div className="New-game-dark-coin">
                          <h5>SET POOL</h5>
                        </div>
                        <div className="sidebar-details-03">
                          <span className="details-info-raised">
                            Set pool to start funding your project
                          </span>
                          <div className="New-submit-col1">
                            <Link
                              to={`/${id}${Paths.createPool}`}
                              className="New-btn-action-link1"
                            >
                              SET NOW
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="loading-container">
          <Loader />
        </div>
      )}

      <Modal show={ShowAlert} closeModal={closeModal}>
        <div className="register-innerbg">
          <div className="successfully">
            <h4>
              You have not registered or your address is not whitelisted for
              this pool
            </h4>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default GameDetails;
