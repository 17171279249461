import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";

import { BtnContainer, SecondaryBtnContainer } from "./style";
import {
  CreatePoolSteps,
  AccessType,
  AllocationType,
  useAppSelector,
  PoolTypes,
  Paths,
  DateHelper,
  getPoolContract,
  getpoolFactoryContract,
} from "../../utils";
import {
  Steps,
  SetPool,
  TokenDetails,
  SwapDetails,
  SetDates,
  PoolAllocation,
} from "./components";
import { SmallLine } from "../../images";
import { schema } from "./validation";
import { useParams } from "react-router-dom";
import { Game, PoolInfo } from "../../core";
import { LoadingSpinner } from "src/components";
import { ethers } from "ethers";

const initialValues = {
  poolName: "",
  poolType: PoolTypes.standard,
  tokenAddress: "",
  tokenSymbol: "",
  decimals: 18,
  tokenPrice: "",
  totalTokens: "",
  minCutoff: "",
  startDate: new Date().toISOString(),
  endDate: new Date().toISOString(),
  minAllocationType: AllocationType.limited,
  minAllocation: "1",
  maxAllocationTypes: [
    AllocationType.limited,
    AllocationType.limited,
    AllocationType.limited,
    AllocationType.limited,
    AllocationType.limited,
  ],
  maxAllocations: ["", "", "", "", ""],
  accessType: AccessType.public,
  allotEco: false,
  ecoRatio: "",
  ppRatio: "",
  holderRatio: "",
  tokenVarious: "0",
  isPrivate: false,
  privateRatio: 0
};

const formatToPlaces = (val: any, placesVal = "0") => {
  const valparts = (val && val.split(".")) || [];
  const places: any =
    placesVal && !!parseInt(placesVal, 10) && parseInt(placesVal, 10);
  let valResult = "";
  if (valparts.length > 1) {
    valResult += valparts[0];
    const secondpart = valparts[1];
    if (secondpart.length > places) {
      const remainingdigs = secondpart.slice(0, places);
      valResult += remainingdigs;
    } else {
      const zerosNeeded: any = places - secondpart.length;
      valResult +=
        secondpart +
        Array.from({ length: zerosNeeded })
          .map((_) => "0")
          .join("");
    }
  } else {
    valResult =
      val +
      Array.from({ length: places })
        .map((_) => "0")
        .join("");
  }
  return valResult;
};

interface Params {
  id: string;
}

export const EditPool = () => {
  const { id }: Params = useParams();

  const history = useHistory();

  const [values, setValues] = React.useState(initialValues);
  const [step, setStep] = useState(CreatePoolSteps.setPool);
  const [loading, setLoading] = useState(false);
  const [isChanged, setIsChanged] = React.useState(false);
  const [gameDetail, setGameDetail] = useState<Game>();

  const { walletConnected, kycVerified, userAddress } = useAppSelector(
    (state) => state.user
  );

  const getGameDetail = async () => {
    if (!walletConnected) return;

    const { error, result } = await Game.findGameById(+id);
    if (error) {
      toast(error, { type: toast.TYPE.INFO });
      history.push(Paths.account);
      return;
    }

    setGameDetail(result);

    if (result.poolInfo) {
      setValues({
        ...values,
        poolType: result.poolInfo.type ? PoolTypes.instant : PoolTypes.standard,
        poolName: result.poolInfo.name,
        tokenAddress: result.poolInfo.tokenAddress,
        decimals: result.poolInfo.tokenDecimals,
        tokenPrice: result.poolInfo.tokenPrice,
        tokenSymbol: result.poolInfo.tokenSymbol,
        totalTokens: `${
          parseInt(result.poolInfo.totalTokens) /
          Math.pow(10, result.poolInfo.tokenDecimals)
        }`,
        tokenVarious: result.poolInfo.tokenVarious.toString(),
        minCutoff: `${parseFloat(result.poolInfo.minRaise) / 100}`,
        startDate: result.poolInfo.startTime.toISOString(),
        endDate: result.poolInfo.endTime.toISOString(),
        maxAllocationTypes: [
          result.poolInfo.max1 > 0
            ? AllocationType.limited
            : AllocationType.unlimited,
          result.poolInfo.max2 > 0
            ? AllocationType.limited
            : AllocationType.unlimited,
          result.poolInfo.max3 > 0
            ? AllocationType.limited
            : AllocationType.unlimited,
          result.poolInfo.max4 > 0
            ? AllocationType.limited
            : AllocationType.unlimited,
          result.poolInfo.max5 > 0
            ? AllocationType.limited
            : AllocationType.unlimited,
            result.poolInfo.max6 > 0
              ? AllocationType.limited
              : AllocationType.unlimited,
        ],
        maxAllocations: [
          result.poolInfo.max1 > 0 ? `${result.poolInfo.max1}` : "-",
          result.poolInfo.max2 > 0 ? `${result.poolInfo.max2}` : "-",
          result.poolInfo.max3 > 0 ? `${result.poolInfo.max3}` : "-",
          result.poolInfo.max4 > 0 ? `${result.poolInfo.max4}` : "-",
          result.poolInfo.max5 > 0 ? `${result.poolInfo.max5}` : "-",
          result.poolInfo.max6 > 0 ? `${result.poolInfo.max6}` : "-",
        ],
        minAllocationType:
          result.poolInfo?.min1 > 0
            ? AllocationType.limited
            : AllocationType.unlimited,
        minAllocation:
          result.poolInfo?.min1 > 0 ? `${result.poolInfo?.min1}` : "-",
        isPrivate: result.poolInfo.isPrivate,
        privateRatio: result.poolInfo.ratio / 100,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getGameDetail();
  }, [walletConnected]);

  useEffect(() => {
    if (walletConnected && kycVerified !== "VERIFIED") {
      toast("Please complete your KYC to perform this action!", {
        type: toast.TYPE.WARNING,
      });
      history.push("/account/kyc");
    }
  }, [walletConnected, kycVerified, history]);

  const handleBack = () => {
    setIsChanged(false);
    setStep((currentStep) => currentStep - 1);
  };

  const handleCancel = (resetForm: any) => {
    setStep(CreatePoolSteps.setPool);
    resetForm();
  };

  const formatPayload = (vals: any) => {
    const payload: any = {};

    payload.minAllocation =
      AllocationType.unlimited === vals.minAllocationType
        ? 0
        : formatToPlaces(vals.minAllocation, vals.decimals);

    payload.totalTokens = formatToPlaces(
      vals.totalTokens.toString(),
      vals.decimals
    );
    payload.startDate = String(Math.floor(new Date(vals.startDate).getTime()));
    payload.endDate = String(Math.floor(new Date(vals.endDate).getTime()));
    payload.poolType = vals.poolType === PoolTypes.instant;

    if (vals.minCutoff >= 1 && vals.minCutoff < 10) {
      payload.minCutoff = 1 * 100;
    } else if (vals.minCutoff >= 10 && vals.minCutoff < 100) {
      payload.minCutoff = 10 * 100;
    } else if (vals.minCutoff >= 100 && vals.minCutoff < 1000) {
      payload.minCutoff = 100 * 100;
    }

    payload.tokenPrice = parseFloat(vals.tokenPrice) || 0;
    payload.tokenVarious = parseInt(vals.tokenVarious);
    payload.privateRatio = +vals.privateRatio * 100;
    payload.decimals = parseInt(vals.decimals);
    return payload;
  };

  async function upsertPool(values: any) {
    if (!walletConnected || !gameDetail) return;

    const formattedValues = formatPayload(values);
    const payload = { ...values, ...formattedValues };

    const { error } = await PoolInfo.upsertPool({
      gameId: parseInt(id),
      type: payload.poolType === "Standard" ? false : true,
      name: payload.poolName,
      tokenAddress: payload.tokenAddress,
      tokenDecimals: payload.decimals,
      tokenSymbol: payload.tokenSymbol,
      tokenPrice: payload.tokenPrice.toString(),
      totalTokens: payload.totalTokens,
      tokenVarious: payload.tokenVarious,
      minRaise: parseInt(payload.minCutoff.toString()),
      startTime: new Date(values.startDate),
      endTime: new Date(values.endDate),
      enableLock: true,
      min1: parseInt(values.minAllocation),
      max1:
        payload.maxAllocations[0] === "-"
          ? -1
          : parseInt(payload.maxAllocations[0]),
      max2:
        payload.maxAllocations[1] === "-"
          ? -1
          : parseInt(payload.maxAllocations[1]),
      max3:
        payload.maxAllocations[2] === "-"
          ? -1
          : parseInt(payload.maxAllocations[2]),
      max4:
        payload.maxAllocations[3] === "-"
          ? -1
          : parseInt(payload.maxAllocations[3]),
      max5:
        payload.maxAllocations[4] === "-"
          ? -1
          : parseInt(payload.maxAllocations[4]),
      max6:
        payload.maxAllocations[5] === "-"
          ? -1
          : parseInt(payload.maxAllocations[5]),
      currentStep: step,
      isPrivate: payload.isPrivate,
      ratio: payload.privateRatio,
    });

    if (error) {
      toast(error, { type: toast.TYPE.ERROR });
      return;
    } else {
      toast("Pool Info updated successfully", { type: toast.TYPE.SUCCESS });
    }
  }

  const handleSubmit = async () => {
    if (walletConnected) {
      if (isChanged) {
        toast("Please apply changes before proceeding", {
          type: toast.TYPE.INFO,
        });

        return;
      }
      try {
        history.push(Paths.account + "/my-project");
      } catch (e) {
        setLoading(false);
      }
    }
  };

  const handleNextClick = (values: any, actions: any) => {
    if (isChanged) {
      toast("Please apply changes before proceeding", {
        type: toast.TYPE.INFO,
      });

      return;
    }
    if (step === CreatePoolSteps.poolAllocation) {
      handleSubmit();
    } else {
      actions.setTouched({});
      setStep((currentStep) => currentStep + 1);
    }
  };

  const handleInteractSmartContract = async (values: any) => {
    const poolContract = await getPoolContract(gameDetail.poolInfo?.poolAddress);
    console.log('values', values);
    setLoading(true);
    switch (step) {
      case CreatePoolSteps.setPool:
        await poolContract.methods
          .setPoolType(values.poolType === "Standard" ? true : false).send({
            from: userAddress,
          }).on("transactionHash", (hash: string) => {
          })
          .on("receipt", async (receipt: any) => {
            await upsertPool(values);
            setLoading(false);
            setIsChanged(false);
            setStep((currentStep) => currentStep + 1);
          })
          .on("error", (error: any) => {
            setLoading(false);
            toast(
              "Transaction has failed, please contact admin to get support!",
              { type: toast.TYPE.WARNING }
            );
          });
        break;

      case CreatePoolSteps.tokenDetails:
        await poolContract.methods
          .setFundTokenInfo(
            values.tokenAddress,
          ).send({
            from: userAddress,
          }).on("transactionHash", (hash: string) => {
          })
          .on("receipt", async (receipt: any) => {
            await upsertPool(values);
            setLoading(false);
            setIsChanged(false);
            setStep((currentStep) => currentStep + 1);
          })
          .on("error", (error: any) => {
            setLoading(false);
            toast(
              "Transaction has failed, please contact admin to get support!",
              { type: toast.TYPE.WARNING }
            );
          });
          break;
        

      case CreatePoolSteps.swapDetails:
        const poolFactoryContract = await getpoolFactoryContract();

        const totalPrice = Math.pow(10, parseInt(values.decimals)) / +values.tokenPrice;

        const formattedTotalTokens = ethers.utils.parseUnits(
          values?.totalTokens.toString(),
          parseInt(values.decimals)
        );
        await poolFactoryContract.methods
          .changeTradeInfo(
            gameDetail.poolInfo.poolAddress,
            totalPrice.toString(),
            formattedTotalTokens,
            values.minCutoff,
            values.tokenVarious === "0" ? true : false,
            parseInt(values.tokenVarious)
          ).send({
            from: userAddress,
          }).on("transactionHash", (hash: string) => {
          })
          .on("receipt", async (receipt: any) => {
            await upsertPool(values);
            setLoading(false);
            setIsChanged(false);
            setStep((currentStep) => currentStep + 1);
          })
          .on("error", (error: any) => {
            setLoading(false);
            toast(
              "Transaction has failed, please contact admin to get support!",
              { type: toast.TYPE.WARNING }
            );
          });
        break;

      case CreatePoolSteps.setDates:
        await poolContract.methods
          .setStartEndDate(
            Math.floor(new Date(values.startDate).getTime() / 1000),
            Math.floor(new Date(values.endDate).getTime() / 1000),
          ).send({
            from: userAddress,
          }).on("transactionHash", (hash: string) => {
          })
          .on("receipt", async (receipt: any) => {
            await upsertPool(values);
            setLoading(false);
            setIsChanged(false);
            setStep((currentStep) => currentStep + 1);
          })
          .on("error", (error: any) => {
            setLoading(false);
            toast(
              "Transaction has failed, please contact admin to get support!",
              { type: toast.TYPE.WARNING }
            );
          });
        break;

      case CreatePoolSteps.poolAllocation:
        await poolContract.methods
          .setPoolAllocation(
            [0, 0, 0, 0, 0, 0].map(() => values.minAllocationType === AllocationType.unlimited ? 0 : ethers.utils.parseUnits(
              values.minAllocation,
              parseInt(values.decimals)
            ).toString()),
            values.maxAllocations.map((x: string) =>
              (x === "-" || x === "")  ? 0 : ethers.utils.parseUnits(
                x,
                parseInt(values.decimals)
              ).toString()
            ),
            values.privateRatio * 100
          ).send({
            from: userAddress,
          }).on("transactionHash", (hash: string) => {
          })
          .on("receipt", async (receipt: any) => {
            await upsertPool(values);
            setLoading(false);
            setIsChanged(false);
            setStep(CreatePoolSteps.setPool);
            history.push(Paths.account + "/my-project");
          })
          .on("error", (error: any) => {
            setLoading(false);
            toast(
              "Transaction has failed, please contact admin to get support!",
              { type: toast.TYPE.WARNING }
            );
          });
        break;

      default:
        return null;
    }
  }

  const renderStep = (
    handlePoolTypeSelect: (type: string) => void,
    handlePoolNameChange: (type: string) => void,
    values: any,
    handleAccessType: (type: string) => void,
    handleMinAllocationType: (type: string) => void,
    handleMaxAllocationType: (index: number, type: string) => void,
    handleTokenSymbol: (type: string) => void,
    handleTokenDecimal: (type: string) => void,
    handleTokenAddress: (type: string) => void
  ): JSX.Element | null => {
    switch (step) {
      case CreatePoolSteps.setPool:
        return (
          <SetPool
            handlePoolTypeSelect={handlePoolTypeSelect}
            handlePoolNameChange={handlePoolNameChange}
            values={values}
          />
        );

      case CreatePoolSteps.tokenDetails:
        return (
          <TokenDetails
            values={values}
            handleTokenAddress={handleTokenAddress}
            handleTokenSymbol={handleTokenSymbol}
            handleTokenDecimal={handleTokenDecimal}
          />
        );

      case CreatePoolSteps.swapDetails:
        return <SwapDetails setIsChanged={setIsChanged} values={values} />;

      case CreatePoolSteps.setDates:
        return <SetDates
                  setIsChanged={setIsChanged}
                  values={values} 
                />;

      case CreatePoolSteps.poolAllocation:
        return (
          <PoolAllocation
            values={values}
            setIsChanged={setIsChanged}
            handleAccessType={handleAccessType}
            handleMinAllocationType={handleMinAllocationType}
            handleMaxAllocationType={handleMaxAllocationType}
          />
        );

      default:
        return null;
    }
  };

  return (
    <>
      <div className="create-pool">
        <div className="container">
          <div className="pool-name-row">
            {loading && <LoadingSpinner />}
            <Formik
              enableReinitialize
              initialValues={values}
              validationSchema={schema[step]}
              onSubmit={handleNextClick}
            >
              {({ values, setFieldValue, resetForm, errors }) => {
                // console.log(errors);
                const handlePoolNameChange = (poolName: string) => {
                  setIsChanged(true);
                  setFieldValue("poolName", poolName);
                }
                const handlePoolTypeSelect = (poolType: string) => {
                  setIsChanged(true);
                  setFieldValue("poolType", poolType);
                  if (poolType === PoolTypes.instant) {
                    setFieldValue("minCutoff", "0");
                  }
                };

                const handleAccessType = (type: string) => {
                  setIsChanged(true);
                  setFieldValue("accessType", type);
                };

                const handleMinAllocationType = (type: string) => {
                  setIsChanged(true);
                  setFieldValue("minAllocationType", type);
                };

                const handleMaxAllocationType = (
                  index: number,
                  type: string
                ) => {
                  setIsChanged(true);
                  setFieldValue(`maxAllocationTypes[${index}]`, type);
                };

                const handleTokenSymbol = (type: string) => {
                  setFieldValue("tokenSymbol", type);
                };
                const handleTokenDecimal = (type: string) => {
                  setFieldValue("decimals", type);
                };

                const handleTokenAddress = (value: string) => {
                  setIsChanged(true);
                  setFieldValue("tokenAddress", value);
                }

                let amountRaised: any =
                  Number(values.totalTokens) / Number(values.tokenPrice);
                amountRaised = Number.isFinite(amountRaised)
                  ? amountRaised.toFixed(2)
                  : 0;

                return (
                  <>
                    <div className="pool-name-col">
                      <div className="pool-label">
                        Set Pool
                        <img src={SmallLine.default} alt="" />
                      </div>
                      <Form>
                        <Steps step={step} />
                        {renderStep(
                          handlePoolTypeSelect,
                          handlePoolNameChange,
                          values,
                          handleAccessType,
                          handleMinAllocationType,
                          handleMaxAllocationType,
                          handleTokenSymbol,
                          handleTokenDecimal,
                          handleTokenAddress
                        )}
                        <BtnContainer>
                          <button
                            type="button"
                            className="btn-action-link1 no-border"
                            onClick={() => handleCancel(resetForm)}
                          >
                            Cancel
                          </button>
                          <SecondaryBtnContainer>
                            { isChanged &&
                              <button
                                type="button"
                                className="btn-action-link1 backButton"
                                onClick={() => handleInteractSmartContract(values)}
                              >
                                Apply
                              </button>
                            } 
                            {step > CreatePoolSteps.setPool && (
                              <button
                                type="button"
                                className="btn-action-link1 backButton"
                                onClick={handleBack}
                              >
                                Back
                              </button>
                            )}

                            <button
                              type="submit"
                              className="btn-action-link1 backButton"
                            >
                              {step === CreatePoolSteps.poolAllocation
                                ? "Update Pool"
                                : "Next"}
                            </button>
                          </SecondaryBtnContainer>
                        </BtnContainer>
                      </Form>
                    </div>
                    <div className="pool-right-col">
                      {step > CreatePoolSteps.swapDetails && (
                        <div className="pool-preview-box">
                          <div className="pool-label">Preview</div>
                          <div className="pool-select-nameview">
                            <img
                              src={gameDetail?.logo}
                              className="token-logo"
                              alt=""
                            />
                            <div className="pool-name-colview">
                              <span className="pool-nameview-display">{`${values.poolName} POOL`}</span>
                              <span className="pool-nameview-selected">
                                {values.poolType}
                              </span>
                            </div>
                          </div>
                          <div className="pool-step-view">
                            <div className="step-view-col">
                              <span className="step-view-heading">
                                Token <br></br>
                                Price
                              </span>
                              <span className="step-view-info">
                                {values.tokenPrice}
                              </span>
                            </div>
                            <div className="step-view-col">
                              <span className="step-view-heading">
                                Amount <br></br>
                                of Tokens
                              </span>
                              <span className="step-view-info">
                                {values.totalTokens}
                              </span>
                            </div>
                            <div className="step-view-col">
                              <span className="step-view-heading">
                                Amount Raised
                              </span>
                              <span className="step-view-info">
                                {amountRaised ? `${amountRaised} BNB` : ""}
                              </span>
                            </div>
                          </div>
                          {step > CreatePoolSteps.poolAllocation && (
                            <>
                              <div className="pool-step-devider"></div>
                              <div className="pool-step-view">
                                <div className="step-view-col">
                                  <span className="step-view-heading">
                                    Min <br></br>
                                    Allocations
                                  </span>
                                  <span className="step-view-info">
                                    {values.minAllocation
                                      ? `${values.minAllocation}`
                                      : "-"}
                                  </span>
                                </div>
                                <div className="step-view-col">
                                  <span className="step-view-heading">
                                    Max <br></br>
                                    Allocations
                                  </span>
                                  <span className="step-view-info">
                                    {values.maxAllocations.map((x, index) => (
                                      <React.Fragment key={index}>
                                        {x ?? "-"}
                                      </React.Fragment>
                                    ))}
                                  </span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                      {step > CreatePoolSteps.setDates && (
                        <div className="pool-date-view">
                          <div className="pool-date-col">
                            <span className="pool-date-label">Start Date:</span>
                            <span className="pool-date-info">
                              {values.startDate
                                ? DateHelper.format(
                                    new Date(values.startDate),
                                    "MM-DD-YYYY hh:mm a"
                                  )
                                : "-"}
                            </span>
                          </div>
                          <div className="pool-date-col">
                            <span className="pool-date-label">End Date:</span>
                            <span className="pool-date-info">
                              {values.endDate
                                ? DateHelper.format(
                                    new Date(values.endDate),
                                    "MM-DD-YYYY hh:mm a"
                                  )
                                : "-"}
                            </span>
                          </div>
                        </div>
                      )}
                      <div
                        className={`pool-need-help ${
                          step > CreatePoolSteps.setPool ? "space-top" : ""
                        }`}
                      >
                        <div className="pool-label">
                          Need Help?
                          <img src={SmallLine.default} alt="" />
                        </div>
                        <iframe
                          src={`${gameDetail?.videoLink}?autoplay=1`}
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded youtube"
                        />
                      </div>
                    </div>
                  </>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </>
  );
};
