export const Color = {
  bluePrimary: "#0A0F12",
  blueSecondary: "#152040",
  blueTertiary: "#A2B8DB",
  blueQuartenary: "rgba(45, 210, 255, 0.1)",
  blueSenary: "#111B39",
  cyanPrimary: "#2DD2FF",
  cyanSecondary: "#77D3F1",
  cyanTertiary: "#70B6DE",
  cyanQuartenary: "#76c9df",
  cyanSenary: "#28E6F4",
  cyanLight: '#2DD2FF33',
  cyanSecondaryLight: '#2CFEE133',
  redPrimary: '#FF5F5F',
  white: '#ffffff',
  whitePrimary: "#ffffff",
  blackPrimary: "#000000",
  blackSecondary: "rgba(0, 0, 0, 0.6)",
  primaryRed: "#FF4646",
  yellowPrimary: "#826B49",
  purplePrimary: "#16244e",
  lightBluePrimary: "#242E4C",
  darkBluePrimary: "#010C2A",
  purple: "#293A99",
  purpleSecondary: "#b9c1d9",
  ethereumColor: "#627EEA",
  binanceColor: "#F6C748",
  maticColor: "#8F5AE8",
  grayPrimary: "#0A0F12",
  graySecondary: "rgba(255, 255, 255, 0.1)",
  grayThird: "#444952",
  grayFour: "#3D3F43",
  darkPrimary: "#9AA1AC",
  darkSecondary: "#23272E",
  silver: "#CDC8E7",
  bg1: '#0D193C',
  bg4: '#E5E5E5',
  bg2: '#25222F'
};

export const Shadow = {
  whiteShadow: "0 0 8px rgba(255, 255, 255, 0.45)",
}

export const Theme = {
  headerBackground: Color.grayPrimary,
  linkColor: Color.whitePrimary,
  linkBorder: Color.lightBluePrimary,
  primaryPageBg: Color.darkSecondary,
  secondaryPageBg: Color.darkBluePrimary,
  primaryBackground: Color.purple,
  textColorPrimary: Color.darkPrimary,
  textColorSecondary: Color.cyanSecondary,
  textColorTertiary: Color.blueTertiary,
  borderPrimary: Color.grayThird,
  borderSecondary: Color.grayFour,
  buttonPrimaryBg: "linear-gradient(180deg, #25D9FD 0%, #2CFEE1 100%)",
  buttonPrimaryColor: Color.whitePrimary,
  buttonSecondaryBg: Color.graySecondary,
  buttonBorderRadius: "30px",
  buttonBoxShadowPrimary: "0px 0px 10px rgba(45, 210, 255, 0.4)",
  error: Color.primaryRed,
  modalBg: Color.blackSecondary,
  modalShadow: "0px 0px 20px rgba(0, 0, 0, 0.4)",
  warning: Color.yellowPrimary,
  selectedWalletBg: Color.blueQuartenary,
  elementBorderRadius: "6px",
  componentBorderRadius: "12px",
  sidebarBg: Color.blackPrimary,
  graphColorPrimary: "#576DFF",
  graphColorSecondary: "#2CFEE1",
  disabledButton:
    "linear-gradient(180deg, rgba(37, 217, 253, 0.4) 0%, rgba(44, 254, 225, 0.4) 100%)",
};
