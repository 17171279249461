import {
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  GET_ETH_BALANCE_SUCCESS,
  GET_MROYA_BALANCE_SUCCESS,
  UPDATE_USDT_BALANCE,
  UPDATE_DAI_BALANCE,
} from "./constant";
import wallet from "../../utils/wallet";
import {
  fromDai,
  fromEth,
  fromUsdt,
  getDaiContract,
  getUsdcContract,
  getUsdtContract,
  Networks,
} from "../../utils";
import { getMroyaTokenContract } from "../../utils";

interface User {
  userId?: string;
  address: string;
  kycVerified?: string;
}

interface LoginSuccess {
  type: typeof LOGIN_SUCCESS;
  userId?: string;
  address: string;
  network: string;
  kycVerified?: string;
  networkInfo?: any; 
}

export const login = (user: User, network: string,networkInfo?: any): LoginSuccess => {
  return {
    type: LOGIN_SUCCESS,
    userId: user.userId,
    address: user.address,
    network,
    kycVerified: user.kycVerified,
    networkInfo,
  };
};

export const logout = () => {
  return {
    type: LOGOUT_SUCCESS,
  };
};

interface GetEthBalanceSuccess {
  type: typeof GET_ETH_BALANCE_SUCCESS;
  ethBalance: string;
}

export const getEthBalanceSuccess = (
  ethBalance: string
): GetEthBalanceSuccess => {
  return {
    type: GET_ETH_BALANCE_SUCCESS,
    ethBalance,
  };
};

export const getEthBalance = (userAddress: string) => async (dispatch: any) => {
  try {
    const balance = await wallet.web3.eth.getBalance(userAddress);

    dispatch(getEthBalanceSuccess(fromEth(balance)));
  } catch (e) {
    console.log("error in get eth balance ", e);
  }
};

interface GetMRoyaBalanceSuccess {
  type: typeof GET_MROYA_BALANCE_SUCCESS;
  mRoyaBalance: string;
}

const getMroyaBalanceSuccess = (
  mRoyaBalance: string
): GetMRoyaBalanceSuccess => {
  return {
    type: GET_MROYA_BALANCE_SUCCESS,
    mRoyaBalance,
  };
};

export const getMroyaBalance =
  (userAddress: string) => async (dispatch: any, getState: () => any) => {
    const { network } = getState().user;

    if (network === Networks.ropsten || network === Networks.mainnet) {
      try {
        const balance = await getMroyaTokenContract()
          .methods.balanceOf(userAddress)
          .call();

        dispatch(getMroyaBalanceSuccess(balance));
      } catch (e) {
        console.log("error in get mRoya balance ", e);
      }
    }
  };

interface GetMusdtBalanceSuccess {
  type: typeof UPDATE_USDT_BALANCE;
  usdtBalance: string;
}

const getMusdtBalanceSuccess = (
  usdtBalance: string
): GetMusdtBalanceSuccess => {
  return {
    type: UPDATE_USDT_BALANCE,
    usdtBalance,
  };
};

export const getMusdtBalance =
  (userAddress: string) => async (dispatch: any, getState: () => any) => {
    try {
      const balance = await getUsdtContract()
        .methods.balanceOf(userAddress)
        .call();

      dispatch(getMusdtBalanceSuccess(fromUsdt(balance)));
    } catch (e) {
      console.log("error in get musdt balance ", e);
    }
  };

export const getMusdcBalance =
  (userAddress: string) => async (dispatch: any, getState: () => any) => {
    try {
      await getUsdcContract()
        .methods.balanceOf(userAddress)
        .call();
      // dispatch(getUsdcBalanceSuccess(fromUsdc(balance)));
    } catch (e) {
      console.log("error in get musdc balance ", e);
    }
  };

interface GetMdaiBalanceSuccess {
  type: typeof UPDATE_DAI_BALANCE;
  daiBalance: string;
}

const getMdaiBalanceSuccess = (daiBalance: string): GetMdaiBalanceSuccess => {
  return {
    type: UPDATE_DAI_BALANCE,
    daiBalance,
  };
};

export const getMdaiBalance =
  (userAddress: string) => async (dispatch: any, getState: () => any) => {
    try {
      const balance = await getDaiContract()
        .methods.balanceOf(userAddress)
        .call();

      dispatch(getMdaiBalanceSuccess(fromDai(balance)));
    } catch (e) {
      console.log("error in get mdai balance ", e);
    }
  };

export type UserActions =
  | LoginSuccess
  | GetEthBalanceSuccess
  | GetMRoyaBalanceSuccess;
