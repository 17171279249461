import {
  DetailsWrapper,
  DetailContainer,
  DetailHeader,
  DetailsBox,
  DetailsBoxItem,
  DetailsBoxItemHeaer,
  DetailsBoxItemText,
  DetailsBoxItemLine
} from './style';

const Details: React.FC<any> = ({ pool, decimals, bnbPrice }) => {
  const minSwap = ((pool?.min1 / pool?.tokenPrice) * bnbPrice).toFixed(2);
  return (
    <DetailsWrapper>
      <DetailContainer>
        <DetailHeader>Pool Information</DetailHeader>
        <DetailsBox>
          <DetailsBoxItem>
            <DetailsBoxItemHeaer>Total Tokens</DetailsBoxItemHeaer>
            <DetailsBoxItemText>
              {(pool?.totalTokens / Math.pow(10, decimals)).toFixed(2)}
            </DetailsBoxItemText>
          </DetailsBoxItem>
          <DetailsBoxItem>
            <DetailsBoxItemHeaer>Min. Allocation</DetailsBoxItemHeaer>
            <DetailsBoxItemText>
              {(pool?.min1 / Math.pow(10, decimals)).toFixed(2)}
            </DetailsBoxItemText>
          </DetailsBoxItem>
          <DetailsBoxItem>
            <DetailsBoxItemHeaer>Max.Allocation</DetailsBoxItemHeaer>
            <DetailsBoxItemText>
              {(
                pool?.maximumAllocationPerWallet / Math.pow(10, decimals)
              ).toFixed(2)}
            </DetailsBoxItemText>
          </DetailsBoxItem>
        </DetailsBox>
        <DetailsBoxItemLine />
        <DetailsBox>
          <DetailsBoxItem style={{ flex: 0.33 }}>
            <DetailsBoxItemHeaer>Min Swap Level</DetailsBoxItemHeaer>
            <DetailsBoxItemText>$ {minSwap}</DetailsBoxItemText>
          </DetailsBoxItem>
          <DetailsBoxItem style={{ flex: 0.35 }}>
            <DetailsBoxItemHeaer>Access Type</DetailsBoxItemHeaer>
            <DetailsBoxItemText>
              {pool?.accessType ? 'Public' : 'Private'}
            </DetailsBoxItemText>
          </DetailsBoxItem>
        </DetailsBox>
      </DetailContainer>
    </DetailsWrapper>
  );
};

export default Details;
