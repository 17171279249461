import StakingLots from './staking-lots';
import TermsAndConditions from './terms-and-conditions';
import LandingZone from './landing-zone';
import SubmitGame from './submit-game';
import GameDetails from './game-details';
import PoolRegistration from './poolregistration';
import AllProjects from './all-projects';
import Dashboard from './dashboard';
import Nfts from './nfts';
import EditGame from './edit-game';
import { PageNotFound } from './404';
export {
  StakingLots,
  TermsAndConditions,
  LandingZone,
  SubmitGame,
  GameDetails,
  PoolRegistration,
  AllProjects,
  Dashboard,
  Nfts,
  EditGame,
  PageNotFound
};

export * from './edit-pool';
