import { ButtonContainer, ImageContainer, Link } from "./style";
import { CheckMarkIcon } from "../../../images";
import { Paths } from "../../../utils";

export const CongratScreen = ({ id }: { id: number }): JSX.Element => {
  return (
    <>
      <div className="submit-wrap">
        <div className="heading-row">
          <div className="headingbox">
            <h2>Your Project has been Submitted Successfully</h2>
          </div>
        </div>

        <ImageContainer>
          <img src={CheckMarkIcon.default} alt="" />
        </ImageContainer>

        <ButtonContainer>
          <Link
            to={Paths.dashboard}
            className="btn-action-link bottom-border-none"
          >
            BACK TO DASHBOARD
          </Link>
          <Link
            to={`${Paths.gameDetails}/${id}`}
            className="btn-action-link"
          >
            PROJECT PREVIEW
          </Link>
          <Link to={`${Paths.account}/my-game`} className="btn-action-link">
            CHECK YOUR PROJECTS
          </Link>
        </ButtonContainer>
      </div>
    </>
  );
};
