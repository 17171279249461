import gql from "graphql-tag";

export const ADD_GAME = gql`
  mutation addGame($data: AddGameInput!) {
    addGame(data: $data) {
      description
      email
      fullDescription
      id
      isActive
      isApproved
      logo
      name
      pitchDeckLink
      preferChat
      raiseAmount
      status
      telegram
      thumbnail
      tokenURL
      totalSafelist
      twitter
      updatedAt
      userId
      video
      videoLink
      visualMaterialLink
      visualMaterialURL
      websiteUrl
      createdAt
    }
  }
`;

export const GAME_LISTS = gql`
  query findGames {
    findGames {
      createdAt
      description
      email
      fullDescription
      id
      isActive
      isApproved
      logo
      name
      pitchDeckLink
      preferChat
      raiseAmount
      status
      telegram
      thumbnail
      tokenURL
      totalSafelist
      twitter
      updatedAt
      userId
      video
      videoLink
      visualMaterialLink
      visualMaterialURL
      websiteUrl
      poolInfo {
          createdAt
          currentStep
          enableLock
          endTime
          feeAddress
          feePercentage
          gameId
          id
          isPrivate
          max1
          max2
          max3
          max4
          max5
          min1
          minRaise
          name
          poolAddress
          poolType
          ratio
          startTime
          tokenAddress
          tokenDecimals
          tokenPrice
          tokenSymbol
          tokenVarious
          totalRaise
          totalTokens
          treasureAddress
          type
          updatedAt
      }
    }
  }
`;

export const GAME_LATEST_LISTS = gql`
  query findLatestGames {
    findLatestGames {
      logo
      preferChat
      description
      fullDescription
      email
      id
      isActive
      isApproved
      name
      pitchDeckLink
      raiseAmount
      status
      telegram
      twitter
      thumbnail
      videoLink
      userId
      websiteUrl
      poolInfo {
        type
        name
        tokenAddress
        poolAddress
        tokenDecimals
        tokenSymbol
        tokenPrice
        tokenVarious
        totalTokens
        minRaise
        startTime
        endTime
        enableLock
        min1
        max1
        max2
        max3
        max4
        max5
        isPrivate
        ratio
        currentStep
        createdAt
        feeAddress
        treasureAddress
        feePercentage
      }
    }
  }
`;

export const GAME_BY_ID = gql`
  query findGameById($id: Int!) {
    findGameById(id: $id) {
      createdAt
      description
      email
      fullDescription
      id
      isActive
      isApproved
      logo
      name
      pitchDeckLink
      preferChat
      raiseAmount
      status
      telegram
      tokenURL
      totalSafelist
      twitter
      updatedAt
      userId
      video
      videoLink
      visualMaterialLink
      visualMaterialURL
      websiteUrl
      poolInfo {
        createdAt
        currentStep
        enableLock
        endTime
        feeAddress
        feePercentage
        gameId
        id
        isPrivate
        max1
        max2
        max3
        max4
        max5
        min1
        minRaise
        name
        poolAddress
        poolType
        ratio
        startTime
        tokenAddress
        tokenDecimals
        tokenPrice
        tokenSymbol
        tokenVarious
        totalRaise
        totalTokens
        treasureAddress
        type
        updatedAt
      }
      thumbnail
      user {
        walletAddress
      }
    }
  }
`;

export const GAME_BY_USER = gql`
query FindGameByUser {
  findGameByUser {
      createdAt
      description
      email
      fullDescription
      id
      isActive
      isApproved
      logo
      name
      pitchDeckLink
      preferChat
      raiseAmount
      status
      telegram
      thumbnail
      tokenURL
      totalSafelist
      twitter
      updatedAt
      userId
      video
      videoLink
      visualMaterialLink
      visualMaterialURL
      websiteUrl
      poolInfo {
          createdAt
          currentStep
          enableLock
          endTime
          feeAddress
          feePercentage
          gameId
          id
          isPrivate
          max1
          max2
          max3
          max4
          max5
          min1
          minRaise
          name
          poolAddress
          poolType
          ratio
          startTime
          tokenAddress
          tokenDecimals
          tokenPrice
          tokenSymbol
          tokenVarious
          totalRaise
          totalTokens
          treasureAddress
          type
          updatedAt
      }
  }
}
`;

export const SAFELIST_BY_GAMEID = gql`
  query findSafelistById($id: Int!) {
    findSafelistById(id: $id) {
      name
      email
      twitter
      isFollow
      retweet
      join
      tier
      feedback
      createdAt
      updatedAt
      gameId
      userId
      user {
        status
        walletAddress
        # lastSignAt
      }
    }
  }
`;

export const FIND_PRIVATE_WHITELIST = gql`
  query findPrivateWhitelist($id: Int!) {
    findPrivateWhitelist(gameId: $id) {
      id
      walletAddress
      tier
      deposit
      claim
    }
  }
`;

export const FIND_PUBLIC_WHITELIST = gql`
  query findPublicWhitelist($id: Int!) {
    findPublicWhitelist(gameId: $id) {
      id
      walletAddress
      tier
      deposit
      claim
    }
  }
`;

export const TIERS_BY_WALLET = gql`
  query findTiersByWallet($wallet: String!) {
    findTiersByWallet(wallet: $wallet) {
      tokenId
      tokenQuantity
    }
  }
`;

export const APPROVE_GAME = gql`
  mutation approveGame($id: Int!) {
    approveGame(id: $id)
  }
`;

export const DECLINE_GAME = gql`
  mutation declineGame($id: Int!) {
    declineGame(id: $id)
  }
`;

export const UPDATE_GAME_BY_OWNER = gql`
  mutation updateGameByOwner($id: Int!, $data: UpdateGameInput!) {
    updateGameByOwner(id: $id, data: $data)
  }
`;
