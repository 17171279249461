import store from '../reducers/store';
import {
  GET_GAMES_SUCCESS,
  SET_GAME_APPROVE_SUCCESS,
  SET_GAME_DECLINE_SUCCESS
} from './constant';

export interface GameModel {
  logo: string;
  description: string;
  fullDescription: string;
  email: string;
  id: number;
  isActive: boolean;
  isApproved: boolean;
  name: string;
  pitchDeckLink: string;
  raiseAmount: string;
  status: string;
  telegram: string;
  twitter: string;
  thumbnail: string;
  videoLink: string;
  userId: number;
  websiteUrl: string;
  createdAt: Date;
  user?: {
    id: number;
    walletAddress: string;
  };
  totalSafelist: number;
  poolInfo?: PoolInfoModel;
}

export interface PoolInfoModel {
  type: boolean;
  tokenAddress: string;
  poolAddress: string;
  tokenSymbol: string;
  tokenDecimals: number;
  tokenPrice: string;
  tokenVarious: number;
  feePercentage: string;
  totalTokens: string;
  minRaise: number;
  startTime: Date;
  endTime: Date;
  enableLock: boolean;
  min1: number;
  max1: number;
  max2: number;
  max3: number;
  max4: number;
  max5: number;
  isPrivate: boolean;
  ratio: number;
  currentStep: number;
  createdAt: Date;
}

interface gatGamesSuccess {
  type: typeof GET_GAMES_SUCCESS;
  games: GameModel[];
}

interface approveGameSuccess {
  type: typeof SET_GAME_APPROVE_SUCCESS;
  games: GameModel[];
}

interface declineGameSuccess {
  type: typeof SET_GAME_DECLINE_SUCCESS;
  games: GameModel[];
}

export const getGames = (games: GameModel[]): gatGamesSuccess => {
  return {
    type: GET_GAMES_SUCCESS,
    games
  };
};

export const approveGame = (id: number): approveGameSuccess => {
  const games = store.getState().games.games.map((i: GameModel) => {
    if (+i.id === +id) {
      i.status = 'Approved';
      i.isApproved = true;
    }
    return i;
  });

  return {
    type: SET_GAME_APPROVE_SUCCESS,
    games
  };
};

export const declineGame = (id: number): declineGameSuccess => {
  const games = store.getState().games.games.map((i: GameModel) => {
    if (+i.id === +id) {
      i.status = 'Declined';
      i.isApproved = false;
    }
    return i;
  });

  return {
    type: SET_GAME_DECLINE_SUCCESS,
    games
  };
};
