import { Fragment, useState } from "react";
import {
  Header,
  WalletWrapper,
  WalletsContainer,
  WalletItem,
  WalletName,
  ConnectCont,
  Divider,
} from "./style";
import {
  Coinbase,
  TrustWallet,
  MetamaskLogo,
} from "../../../../images";
import { WalletTypes } from "../../../../utils";

interface Props {
  selectWallet: (wallet: number) => void;
}

const Select = (props: Props) => {
  const { selectWallet } = props;

  const [selected, setSelected] = useState(WalletTypes.default);

  const setSelectWallet = (selectedWallet: number) => {
    setSelected(selectedWallet);
  };

  const handleConnect = () => {
    if (selected) {
      selectWallet(selected);
    }
  };

  return (
    <Fragment>
      <Header>Connect Wallet</Header>
      <Divider/>
      <WalletWrapper>
        <WalletsContainer>
          <WalletItem
            selected={selected === WalletTypes.metamask}
            onClick={() => setSelectWallet(WalletTypes.metamask)}
          >
            <img
              src={MetamaskLogo.default}
              alt="metamask"
              className="metamask-icon"
            />
            <WalletName className="metamask-text">Metamask</WalletName>
          </WalletItem>
          <WalletItem
            selected={selected === WalletTypes.walletConnect}
            onClick={() => setSelectWallet(WalletTypes.walletConnect)}
          >
            <img src={Coinbase.default} alt="wallet connect"   className="metamask-icon"/>
            <WalletName className="walletconnect-text">
            Coinbase Wallet
            </WalletName>
          </WalletItem>
          <WalletItem
            selected={selected === WalletTypes.authereum}
            onClick={() => setSelectWallet(WalletTypes.authereum)}
          >
            <img src={TrustWallet.default} alt="authereum"  className="metamask-icon"/>
            <WalletName className="authereum-text">TrustWallet</WalletName>
          </WalletItem>
        </WalletsContainer>
      </WalletWrapper>
      <ConnectCont>
        {/* <ConnectBtn onClick={handleConnect}>
          <span data-text="Connect">Connect</span>
        </ConnectBtn> */}
        <button  onClick={handleConnect} className="btn-action-link" >Connect</button>
      </ConnectCont>
    </Fragment>
  );
};

export default Select;
