import { useState, useEffect } from 'react';
import Slider from 'react-slick';

import './style.scss';

import {
  NftModel,
  NftOptions,
  getConfig,
  getRoyaNftContract,
  useAppSelector
} from '../../utils';
import { NftCard, NewModal } from '../../components';
import { SuccessCheckmark102 } from '../../images';
import { WalletModel } from '../../core';
import { toast } from 'react-toastify';

const settings = {
  className: 'center',
  centerMode: true,
  infinite: false,
  centerPadding: '10px',
  slidesToShow: 1,
  speed: 500
};

const Nfts = () => {
  const { walletConnected, userAddress } = useAppSelector(
    (state) => state.user
  );

  const [showMsg, setshowMsg] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [nftModel, setNftModel] = useState<NftModel>();
  const [tokenQuantity, setTokenQuantity] = useState<number>(1);
  const [txHash, setTxHash] = useState('');
  const [totalMints, setTotalMints] = useState([])
  const [currentSoldNFTs, setCurrentSoldNFTs] = useState([])

  const handleBuy = (nft: NftModel, quantity: number) => {
    setshowMsg(true);
    setTokenQuantity(quantity);
    setNftModel(nft);
  };

  const closeModal = () => {
    setshowMsg(false);
    setIsSuccess(false);
  };

  const getNFTVals = async () => {
    const maxMints = await getRoyaNftContract().methods.getMaxMint().call()
    const currentSold = await getRoyaNftContract().methods.currentSold().call()
    if (maxMints && currentSold) {
      setTotalMints(maxMints)
      setCurrentSoldNFTs(currentSold)
    }
  }

  useEffect(() => {
    getNFTVals()
  }, [walletConnected, txHash])

  const buy = async () => {
    if (!walletConnected) {
      toast('Please connect your wallet to buy', { type: toast.TYPE.INFO });
      return;
    }
    const response: any = await WalletModel.generateSignature(
      nftModel!.buy - 1,
      tokenQuantity
    );

    const model = {
      from: userAddress,
      value: response.results.valueSent
    };

    const signature = response.results.signature;
    const valueSent = response.results.valueSent;
    const expireAt = response.results.expirationTime;

    getRoyaNftContract()
      .methods.buy(
        nftModel!.buy - 1,
        tokenQuantity,
        valueSent,
        expireAt,
        signature
      )
      .send(model)
      .on('transactionHash', (hash: string) => {
        setIsSuccess(true);
        setshowMsg(false);
        setTxHash(hash);
      })
      .on('error', (error: any) => {
        setshowMsg(false);
        toast(
          `There is an error on transaction - ${JSON.stringify(error.message)}`,
          { type: toast.TYPE.ERROR }
        );
      });
  };

  return (
    <>
      <div className="page-container ntfs">
        <div className="ntfs-wrapper">
          <div className="section-header">
            <div className="section-header-wrapper">
              <span>NFT Options</span>
            </div>
          </div>

          <div className="staking-listrow">
            <NftCard
              totalMint={totalMints[0]}
              currentSold={currentSoldNFTs[0]}
              model={NftOptions.settler}
              onClickBuy={(value, count) => handleBuy(value, count)}
            />
            <NftCard
              totalMint={totalMints[1]}
              currentSold={currentSoldNFTs[1]}
              model={NftOptions.merchant}
              onClickBuy={(value, count) => handleBuy(value, count)}
            />
            <NftCard
              totalMint={totalMints[2]}
              currentSold={currentSoldNFTs[2]}
              model={NftOptions.knight}
              onClickBuy={(value, count) => handleBuy(value, count)}
            />
            <NftCard
              totalMint={totalMints[3]}
              currentSold={currentSoldNFTs[3]}
              model={NftOptions.archon}
              onClickBuy={(value, count) => handleBuy(value, count)}
            />
            <NftCard
              totalMint={totalMints[4]}
              currentSold={currentSoldNFTs[4]}
              model={NftOptions.monarch}
              onClickBuy={(value, count) => handleBuy(value, count)}
            />
          </div>
          <div className="staking-listrow-slider-mobile d-none">
            <Slider {...settings}>
              <NftCard
                totalMint={totalMints[0]}
                currentSold={currentSoldNFTs[0]}
                model={NftOptions.settler}
                onClickBuy={(value, count) => handleBuy(value, count)}
              />
              <NftCard
                totalMint={totalMints[1]}
                currentSold={currentSoldNFTs[1]}
                model={NftOptions.merchant}
                onClickBuy={(value, count) => handleBuy(value, count)}
              />
              <NftCard
                totalMint={totalMints[2]}
                currentSold={currentSoldNFTs[2]}
                model={NftOptions.knight}
                onClickBuy={(value, count) => handleBuy(value, count)}
              />
              <NftCard
                totalMint={totalMints[3]}
                currentSold={currentSoldNFTs[3]}
                model={NftOptions.archon}
                onClickBuy={(value, count) => handleBuy(value, count)}
              />
              <NftCard
                totalMint={totalMints[4]}
                currentSold={currentSoldNFTs[4]}
                model={NftOptions.monarch}
                onClickBuy={(value, count) => handleBuy(value, count)}
              />
            </Slider>
          </div>

          <div className="allocation-pool-row">
            <h2>
              By purchasing our unique NFT you get a ticket to publish your
              game.
              <br />
              Alias ut fuga ducimus. Qui ut vitae ut doloribus tenetur
              necessitatibus.
            </h2>
          </div>
        </div>
        {showMsg && (
          <NewModal className="puchase-modal" onClose={closeModal}>
            <div className="purchase-modal-container">
              <h1>NFT Purchase</h1>
              <div className="purchase-body">
                <div className="purchase-body-title">
                  You sure you want to purchase this item?
                </div>
                <div className="purchase-body-detail">
                  <div className="detail-img">
                    <img src={nftModel?.img} alt="Tier01Icon" />
                  </div>
                  <div className="detail-container">
                    <div className="detail-row">
                      <span className="text-name">Title:</span>
                      <span className="text-des">{nftModel?.title}</span>
                    </div>
                    <div className="detail-row">
                      <span className="text-name">Price:</span>
                      <span className="text-des">
                        {nftModel && nftModel?.price * tokenQuantity} BNB
                      </span>
                    </div>
                    <div className="detail-row">
                      <span className="text-name">Amount:</span>
                      <span className="text-des">{tokenQuantity}</span>
                    </div>
                  </div>
                </div>
                <div className="purchase-body-footer">
                  <button type="button" className="btn-secondary" onClick={closeModal}>
                    Cancel
                  </button>
                  <button
                    type="button"
                    className="btn-primary"
                    onClick={() => {
                      buy();
                    }}
                  >
                    Buy
                  </button>
                </div>
              </div>
            </div>
          </NewModal>
        )}
        {isSuccess && (
          <NewModal className="puchase-modal" onClose={closeModal}>
            <div className="purchase-modal-container">
              <h1>NFT Purchase</h1>
              <div className="purchase-body succss-body">
                <img
                  src={SuccessCheckmark102.default}
                  alt="SuccessCheckGraIcon"
                />

                <p className="purchase-body-title success-text">
                  Your Purchase was Successful
                </p>
                <div className="purchase-body-footer">
                  <button
                    type="button"
                    className="btn-primary"
                    onClick={() => setIsSuccess(false)}
                  >
                    Confirm
                  </button>
                  <button
                    type="button"
                    className="btn-action-link ml-1 d-none"
                    onClick={() =>
                      window.open(
                        `${getConfig()['etherscanLink']}/tx/${txHash}`,
                        '_blank'
                      )
                    }
                  >
                    View Transaction
                  </button>
                </div>
              </div>
            </div>
          </NewModal>
        )}
      </div>
    </>
  );
};

export default Nfts;
