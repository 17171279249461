import { FooterIcon, Coingecko, MarketIcon } from '../../images';

import './styles.scss';

const Footer = () => {
  return (
    <footer>
      <div className="footercontainer">
        <div className="footerlogo">
          <a href="/">
            <img src={FooterIcon.default} alt="" />
          </a>
        </div>
        <p>All Rights Reserved Gamestarter Royale 2023</p>
        <div className="heading-row">
          <div className="headingbox">
            <h2>Follow us on:</h2>
          </div>
        </div>
        <div className="footer-social-link">
          <ul className="social-link">
            <li>
              <a href="https://twitter.com/Royalegamepad" target="_blank" rel="noreferrer">
                <svg
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 4.72798C23.1 5.12798 22.2 5.42798 21.2 5.52798C22.2 4.92798 23 3.92798 23.4 2.82798C22.4 3.42798 21.4 3.82798 20.3 4.02798C19.4 3.02798 18.1 2.42798 16.7 2.42798C14 2.42798 11.8 4.62798 11.8 7.32798C11.8 7.72798 11.8 8.12798 11.9 8.42798C7.7 8.22798 4.1 6.22798 1.7 3.22798C1.2 4.02798 1 4.82798 1 5.72798C1 7.42798 1.9 8.92798 3.2 9.82798C2.4 9.82798 1.6 9.62798 1 9.22798C1 9.22798 1 9.22798 1 9.32798C1 11.728 2.7 13.728 4.9 14.128C4.5 14.228 4.1 14.328 3.6 14.328C3.3 14.328 3 14.328 2.7 14.228C3.3 16.228 5.1 17.628 7.3 17.628C5.6 18.928 3.5 19.728 1.2 19.728C0.8 19.728 0.4 19.728 0 19.628C2.2 21.028 4.8 21.828 7.5 21.828C16.6 21.828 21.5 14.328 21.5 7.82798C21.5 7.62798 21.5 7.42798 21.5 7.22798C22.5 6.52798 23.3 5.62798 24 4.72798Z"
                    fill="white"
                  />
                </svg>
              </a>
              <span className="social-link-info">Twitter</span>
            </li>
            <li>
              <a href="https://t.me/RoyaleFinance" target="_blank" rel="noreferrer">
                <svg
                  viewBox="0 0 25 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.7745 0.559376C24.7552 0.462122 24.7127 0.37223 24.6514 0.298877C24.5902 0.225523 24.5123 0.171336 24.4257 0.141846C24.1106 0.0736005 23.7845 0.0991914 23.4814 0.215964C23.4814 0.215964 2.45364 8.51467 1.25289 9.43373C0.993391 9.63138 0.90714 9.74585 0.86439 9.88173C0.65664 10.5356 1.30314 10.8238 1.30314 10.8238L6.72266 12.7633C6.8142 12.7806 6.90817 12.7744 6.99716 12.7451C8.23016 11.8903 19.4014 4.1442 20.0472 3.88396C20.1484 3.8502 20.2234 3.88396 20.2069 3.96632C19.9429 4.95949 10.2957 14.3733 10.2424 14.4309C10.2165 14.4542 10.1962 14.4841 10.1831 14.5179C10.1701 14.5518 10.1647 14.5886 10.1674 14.6253L9.66341 20.432C9.66341 20.432 9.45191 22.2437 11.0989 20.432C12.2667 19.1481 13.3872 18.0857 13.9489 17.5694C15.8127 18.9817 17.8182 20.544 18.6829 21.3576C18.828 21.5124 19 21.6334 19.1887 21.7135C19.3774 21.7936 19.5788 21.8311 19.7809 21.8237C20.6059 21.7891 20.8309 20.7984 20.8309 20.7984C20.8309 20.7984 24.662 3.87079 24.791 1.60196C24.803 1.37961 24.8202 1.23714 24.8217 1.08479C24.8285 0.908082 24.8126 0.731201 24.7745 0.559376Z"
                    fill="white"
                  />
                </svg>
              </a>
              <span className="social-link-info">
                Official <br /> Telegram
              </span>
            </li>
            <li>
              <a href="https://t.me/Royalefinanceoffici" target="_blank" rel="noreferrer">
                <svg
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 4.72798C23.1 5.12798 22.2 5.42798 21.2 5.52798C22.2 4.92798 23 3.92798 23.4 2.82798C22.4 3.42798 21.4 3.82798 20.3 4.02798C19.4 3.02798 18.1 2.42798 16.7 2.42798C14 2.42798 11.8 4.62798 11.8 7.32798C11.8 7.72798 11.8 8.12798 11.9 8.42798C7.7 8.22798 4.1 6.22798 1.7 3.22798C1.2 4.02798 1 4.82798 1 5.72798C1 7.42798 1.9 8.92798 3.2 9.82798C2.4 9.82798 1.6 9.62798 1 9.22798C1 9.22798 1 9.22798 1 9.32798C1 11.728 2.7 13.728 4.9 14.128C4.5 14.228 4.1 14.328 3.6 14.328C3.3 14.328 3 14.328 2.7 14.228C3.3 16.228 5.1 17.628 7.3 17.628C5.6 18.928 3.5 19.728 1.2 19.728C0.8 19.728 0.4 19.728 0 19.628C2.2 21.028 4.8 21.828 7.5 21.828C16.6 21.828 21.5 14.328 21.5 7.82798C21.5 7.62798 21.5 7.42798 21.5 7.22798C22.5 6.52798 23.3 5.62798 24 4.72798Z"
                    fill="white"
                  />
                </svg>
              </a>
              <span className="social-link-info">
                Official <br /> Annoucments
              </span>
            </li>
            <li>
              <a href="https://medium.com/@officialroyale" target="_blank" rel="noreferrer">
                <svg
                  width="24"
                  height="25"
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.5 0.961426H1.5C1.10218 0.961426 0.720644 1.11946 0.43934 1.40077C0.158035 1.68207 0 2.0636 0 2.46143L0 23.4614C0 23.8593 0.158035 24.2408 0.43934 24.5221C0.720644 24.8034 1.10218 24.9614 1.5 24.9614H22.5C22.8978 24.9614 23.2794 24.8034 23.5607 24.5221C23.842 24.2408 24 23.8593 24 23.4614V2.46143C24 2.0636 23.842 1.68207 23.5607 1.40077C23.2794 1.11946 22.8978 0.961426 22.5 0.961426V0.961426ZM19.938 6.64793L18.651 7.88243C18.5953 7.92314 18.5518 7.9784 18.5253 8.04215C18.4989 8.10591 18.4905 8.17571 18.501 8.24393V17.3114C18.4905 17.3796 18.4989 17.4494 18.5253 17.5132C18.5518 17.577 18.5953 17.6322 18.651 17.6729L19.908 18.9074V19.1789H13.5855V18.9074L14.8875 17.6429C15.015 17.5154 15.015 17.4779 15.015 17.2814V9.95093L11.4 19.1474H10.9065L6.6915 9.95243V16.1114C6.67416 16.2395 6.68623 16.3698 6.72677 16.4924C6.76732 16.6151 6.83528 16.7269 6.9255 16.8194L8.619 18.8744V19.1459H3.819V18.8744L5.5125 16.8194C5.60199 16.7269 5.66844 16.6146 5.70649 16.4916C5.74453 16.3686 5.7531 16.2383 5.7315 16.1114V8.98793C5.74091 8.89006 5.72707 8.79136 5.69109 8.69987C5.65512 8.60837 5.59804 8.52667 5.5245 8.46143L4.0245 6.64793V6.37643H8.7L12.3 14.3009L15.483 6.37643H19.9395L19.938 6.64793Z"
                    fill="white"
                  />
                </svg>
              </a>
              <span className="social-link-info">Medium</span>
            </li>
            <li>
              <a href="https://www.coingecko.com/en/coins/royale" target="_blank" rel="noreferrer">
                <img src={Coingecko.default} alt="" />
              </a>
              <span className="social-link-info">CoinGecko</span>
            </li>
            <li>
              <a href="https://coinmarketcap.com/currencies/royale-finance/" target="_blank" rel="noreferrer">
                <img src={MarketIcon.default} alt="" />
              </a>
              <span className="social-link-info">CoinMarketCap</span>
            </li>
          </ul>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
