import React, { DetailedHTMLProps, VideoHTMLAttributes } from "react";
import axios from "axios";
import { GreyPlayIcon, LogoRemoveIcon } from "../../images";
import { endpoint_api } from "../../lib/network/apiClient";

interface Props {
  type?: "image" | "video" | "pdf";
  className?: string;
  imgUrl?: string;
  label: string;
  options?: Array<string>;
  text?: string;
  disabled?: boolean;
  setMediaURL: (mediaURL: string) => void;
  mediaURL: string;
}

export const UploadContainer: React.FC<Props> = ({
  type,
  className,
  imgUrl,
  label,
  options,
  text,
  setMediaURL,
  mediaURL,
  disabled = false,
}) => {
  const inputRef = React.useRef(null);

  const [dragActive, setDragActive] = React.useState(false);
  const [progress, setProgress] = React.useState(1);
  const [status, setStatus] = React.useState(true);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  const handleFile = async (files: any) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("filename", files[0].name);
    try {
      const result = await axios.post(`${endpoint_api}/upload`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (data) => {
          //@ts-ignore Set the progress value to show the progress bar
          const percent = Math.round((data.loaded * 100) / data.total);
          setProgress(percent);
        },
      });
      setMediaURL(decodeURIComponent(result.data));
    } catch (error) {
      alert("Please try again ");
    }
  };

  const videoRef =
    React.useRef<
      DetailedHTMLProps<VideoHTMLAttributes<HTMLVideoElement>, HTMLVideoElement>
    >();

  const handlePlay = () => {
    // @ts-ignore for playing video
    videoRef.current.play();
    setStatus(false);
  };

  const handleStop = () => {
    // @ts-ignore for playing video
    videoRef.current.stop();
    setStatus(true);
  };

  return (
    <>
      <div
        className={`upload-container ${className ?? ""}`}
        id="form-file-upload"
        onDragEnter={handleDrag}
      >
        {mediaURL ? (
          <>
            <div className="upload-container-media">
              {type === "image" && (
                <img src={mediaURL} alt="avatar" className="uploaded-img uploaded-image" />
              )}
              {type === "video" && (
                <>
                  <video
                    // @ts-ignore for playing video
                    ref={videoRef}
                    className="uploaded-img"
                    preload="metadata"
                    onEnded={() => setStatus(true)}
                  >
                    <source src={mediaURL} />
                  </video>
                  {status && (
                    <img
                      onClick={() => handlePlay()}
                      className="grey-play-button"
                      alt="card"
                      src={GreyPlayIcon.default}
                      width="105"
                      height="105"
                    />
                  )}{" "}
                  {!status && (
                    <img
                      onClick={() => handleStop()}
                      className="grey-play-button"
                      alt="card"
                      src={GreyPlayIcon.default}
                      width="105"
                      height="105"
                    />
                  )}
                </>
              )}
              {type === "pdf" && (
                <a style={{ color: 'white', textDecoration: 'underline !important' }} href={mediaURL} target='_blank' rel="noreferrer">
                  {mediaURL.split('/')[4]}
                </a>
              )}
              <img
                src={LogoRemoveIcon.default}
                alt="Remove media icon"
                height={44}
                width={44}
                onClick={() => {
                  setMediaURL("");
                  setProgress(1);
                }}
              />
            </div>
          </>
        ) : (
          <label
            htmlFor={type === "image" ? "image-upload" : "video-upload"}
            className={`upload-container-wrap ${dragActive ? "active" : ""}`}
          >
            {progress === 1 && (
              <>
                <p className="upload-container-wrap-label">{label}</p>
                <div className="upload-container-wrap-option">
                  {options &&
                    options.map((item, index) => (
                      <div
                        key={index}
                        className="upload-container-wrap-option-element"
                      >
                        <div className="upload-container-wrap-option-rectangle"></div>
                        <p>{item}</p>
                      </div>
                    ))}
                </div>
                <p className="upload-container-wrap-text">{text}</p>
              </>
            )}
            <input
              ref={inputRef}
              type="file"
              disabled={disabled}
              id={type === "image" ? "image-upload" : "video-upload"}
              multiple
              onChange={handleChange}
              accept={
                type === "image"
                  ? "image/png, image/jpg, image/jpeg"
                  : "video/mp4,video/webm"
              }
            />
            {progress > 1 && (
              <div className="upload-container-wrap-progress">
                <p>{progress} %</p>
                <div className="progress">
                  <div
                    className="progress-bar"
                    style={{ width: `${progress}%` }}
                  />
                </div>
              </div>
            )}
          </label>
        )}
        {dragActive && (
          <div
            id="drag-file-element"
            className="upload-container-drag absolute top-0 right-0 bottom-0 left-0 h-full w-full rounded"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </div>
    </>
  );
};
