import { useState } from "react";
import {
  CollapseContainer,
  CollapseTitle,
  CollapseBtnCont,
  Dash,
  DashRotate,
  CollapseContent,
} from "./style";

interface Props {
  title: string;
}

const Collapse = (props: Props) => {
  const { title } = props;

  const [open, setOpen] = useState(false);

  const renderButton = (): JSX.Element => {
    if (!open) {
      return (
        <>
          <Dash></Dash>
          <DashRotate></DashRotate>
        </>
      );
    } else {
      return <Dash></Dash>;
    }
  };

  return (
    <>
      <CollapseContainer onClick={() => setOpen(!open)}>
        <CollapseTitle>{title}</CollapseTitle>
        <CollapseBtnCont>{renderButton()}</CollapseBtnCont>
      </CollapseContainer>
      <CollapseContent open={open}>
        Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam no
        suscipit quaerendum. At nam minimum ponderum. Est audiam animal
        molestiae te. Ex duo eripuit mentitum.Globally network
        principle-centered channels and robust testing procedures. Continually
        actualize client-based channels without resource-leveling internal or
        "organic" sources. Professionally engage.
      </CollapseContent>
    </>
  );
};

export default Collapse;
