import styled, { css } from 'styled-components';
import { Color } from '../../utils';

export const Button = styled.button<{ secondary?: boolean; error?: boolean }>`
  min-width: 160px;
  padding: 0 20px;
  height: 44px;
  color: #ffffff;
  border: 1px solid transparent;
  border-radius: 30px;
  box-shadow: 0px 0px 10px rgba(45, 210, 255, 0.4);
  outline: none;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.3px;
  cursor: pointer;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
  }
  ${(props) =>
    props.secondary
      ? css`
          background: transparent;
          box-shadow: none;
          color: ${Color.cyanSecondary};
          border: 1px solid ${Color.cyanSecondary};
        `
      : null}
  ${(props) =>
    props.error
      ? css`
          background: transparent;
          box-shadow: none;
          color: ${Color.redPrimary};
          border: 1px solid ${Color.redPrimary};
        `
      : null}
`;
