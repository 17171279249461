import { TabsContainer, Tab, AciveLine } from "./style";
import { PoolDetailTab } from "../../../../utils";

interface Props {
  activeTab: number;
  handleTabChange: (tab: number) => void;
}

const Tabs = (props: Props) => {
  const { handleTabChange, activeTab } = props;

  return (
    <TabsContainer>
      <Tab
        onClick={() => handleTabChange(PoolDetailTab.detail)}
        active={activeTab === PoolDetailTab.detail}
      >
        Pool Details
      </Tab>
      <Tab
        onClick={() => handleTabChange(PoolDetailTab.about)}
        active={activeTab === PoolDetailTab.about}
      >
        About the Project
      </Tab>
      <AciveLine activeTab={activeTab} />
    </TabsContainer>
  );
};

export default Tabs;
