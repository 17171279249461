import gql from 'graphql-tag';

export const ADD_GAME_REGISTER = gql`
  mutation addRegisterInfo($gameId: Int!, $data: GameRegisterInfoDto!) {
    addRegisterInfo(gameId: $gameId, data: $data) {
      name
      email
      twitter
      isFollow
      retweet
      join
      feedback
      gameId
    }
  }
`;

export const GET_REGISTER_INFO_BY_GAMEID = gql`
  query getRegisterInfoByGameId($gameId: Int!) {
    getRegisterInfoByGameId(gameId: $gameId) {
      name
      email
      twitter
      isFollow
      retweet
      join
      tier
      feedback
      gameId
    }
  }
`

export const CHECK_IS_REGISTERED = gql`
  query checkIsRegistered($gameId: Int!) {
    checkRegistered(gameId: $gameId)
  }
`

export const GET_PARTICIPATING_GAMES_BY_USER = gql`
query GetParticipatingGamesByUser {
  getParticipatingGamesByUser {
      createdAt
      email
      feedback
      gameId
      id
      isFollow
      join
      name
      retweet
      tier
      twitter
      updatedAt
      userId
      game {
          createdAt
          description
          email
          fullDescription
          id
          isActive
          isApproved
          logo
          name
          pitchDeckLink
          preferChat
          raiseAmount
          status
          telegram
          thumbnail
          tokenURL
          totalSafelist
          twitter
          updatedAt
          userId
          video
          videoLink
          visualMaterialLink
          visualMaterialURL
          websiteUrl
          poolInfo {
              createdAt
              currentStep
              enableLock
              endTime
              feeAddress
              feePercentage
              gameId
              id
              isPrivate
              max1
              max2
              max3
              max4
              max5
              min1
              minRaise
              name
              poolAddress
              poolType
              ratio
              startTime
              tokenAddress
              tokenDecimals
              tokenPrice
              tokenSymbol
              tokenVarious
              totalRaise
              totalTokens
              treasureAddress
              type
              updatedAt
          }
      }
  }
}
`;

export const GET_PARTICIPATING_GAMES_BY_GAME_ID = gql`
  query getParticipatingGamesByGameId($gameId: Int!) {
    getParticipatingGamesByGameId(gameId: $gameId) {
      name
      email
      twitter
      isFollow
      retweet
      join
      tier
      feedback
      gameId
      game {
        id
        name
        thumbnail
        description
        status
        raiseAmount
        logo
        preferChat
        videoLink
        websiteUrl
        pitchDeckLink
        email
        telegram
        twitter
        isActive
        isApproved
        poolInfo {
          type
          tokenAddress
          poolAddress
          tokenSymbol
          tokenPrice
          tokenVarious
          totalTokens
          minRaise
          startTime
          endTime
          enableLock
          min1
          max1
          max2
          max3
          max4
          max5
          isPrivate
          ratio
          currentStep
          createdAt
        }
      }
    }
  }
`;
