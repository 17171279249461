import { plainToInstance, Transform } from "class-transformer";
import { get } from "lodash";

import { Api } from "../../../lib/network";
import { BaseModel } from "../base";

import {
  ADD_GAME,
  APPROVE_GAME,
  DECLINE_GAME,
  GAME_BY_ID,
  GAME_LATEST_LISTS,
  GAME_LISTS,
  TIERS_BY_WALLET,
  GAME_BY_USER,
  SAFELIST_BY_GAMEID,
  FIND_PRIVATE_WHITELIST,
  FIND_PUBLIC_WHITELIST,
  UPDATE_GAME_BY_OWNER,
} from "./queries";
import { DateHelper } from "src/utils";
import { User } from "../user";
import { PoolInfo } from "../pool-info";
import { GameRegisterInfo } from "../game-register";

export class Game extends BaseModel {
  userId: number;
  name: string;
  thumbnail: string;
  description: string;
  fullDescription: string;
  status: string;
  raiseAmount: string;
  logo: string;
  preferChat: string;
  videoLink: string;
  websiteUrl: string;
  pitchDeckLink: string;
  email: string;
  telegram: string;
  twitter: string;
  isActive: boolean = false;
  isApproved: boolean = false;
  totalSafelist: number;
  video: string;
  visualMaterialLink: string;
  @Transform(({ value }) => DateHelper.parse(value)) statusDate: Date;

  @Transform(({ value }) => User.fromJson(value)) user?: User;
  @Transform(({ value }) => PoolInfo.fromJson(value)) poolInfo?: PoolInfo;
  @Transform(({ value }) => GameRegisterInfo.fromJson(value))
  registeredUsers: Array<GameRegisterInfo>;

  static fromJson(json: any) {
    return plainToInstance(Game, json);
  }
  static fromJsonArray(arrJson: any[]) {
    return arrJson ? arrJson.map((x) => Game.fromJson(x)) : [];
  }

  static async add(payload: any) {
    const { data, ...rest } = await Api.request(ADD_GAME, {
      data: payload,
    });

    return { ...rest, results: get(data, "addGame", {}) };
  }

  static async findGames() {
    const { data, ...rest } = await Api.request(GAME_LISTS, {});
    return {
      ...rest,
      results: Game.fromJsonArray(get(data, "findGames", [])),
      totalCount: get(data, "findGames.totalCount", 0),
    };
  }

  static async findGameById(id: number) {
    const { data, ...rest } = await Api.request(GAME_BY_ID, {
      id: id,
    });

    return {
      ...rest,
      result: Game.fromJson(get(data, "findGameById")),
    };
  }

  static async findGameByUser() {
    const { data, ...rest } = await Api.request(GAME_BY_USER, {});

    return {
      ...rest,
      result: Game.fromJson(get(data, "findGameByUser")),
    };
  }

  static async findSafelistById(gameId: number) {
    const { data, ...rest } = await Api.request(SAFELIST_BY_GAMEID, {
      id: gameId,
    });

    return {
      ...rest,
      results: get(data, "findSafelistById", []),
    };
  }

  static async findTiersByWallet(wallet: string) {
    const { data, ...rest } = await Api.request(TIERS_BY_WALLET, {
      wallet: wallet,
    });

    return {
      ...rest,
      results: get(data, "findTiersByWallet", []),
    };
  }

  static async findPrivateWhitelist(id: number) {
    const { data } = await Api.request(FIND_PRIVATE_WHITELIST, {
      id,
    });

    return get(data, "findPrivateWhitelist", []);
  }

  static async findPublicWhitelist(id: number) {
    const { data, ...rest } = await Api.request(FIND_PUBLIC_WHITELIST, {
      id,
    });
    return {
      ...rest,
      results: get(data, "findPublicWhitelist", []),
    };
  }

  static async findLatestGames() {
    const { data, ...rest } = await Api.request(GAME_LATEST_LISTS, {});

    return {
      ...rest,
      results: Game.fromJsonArray(get(data, "findLatestGames", [])),
    };
  }

  static async approve(data: any) {
    const { data: _, ...rest } = await Api.request(APPROVE_GAME, {
      id: data,
    });
    return { ...rest };
  }

  static async decline(data: any) {
    const { data: _, ...rest } = await Api.request(DECLINE_GAME, {
      id: data,
    });
    return { ...rest };
  }

  static async updateGameByOwner(id: number, payload: any) {
    const { data, ...rest } = await Api.request(UPDATE_GAME_BY_OWNER, {
      id: id,
      data: payload,
    });

    console.log({ data });

    return { ...rest, results: get(data, "updateGameByOwner", {}) };
  }
}
