import { RoyaStakeModel } from 'src/utils';

export const renderAllocationItem = (model: RoyaStakeModel) => (
  <div className="tier-com-applications-list-item">
    <div className="tier-applications-col">
      <div className="applications-original-img1">
        <img src={model.icon} alt={model.title} />
      </div>
      <span>{model.title}</span>
    </div>
    <div className="tier-applications-col">{model.slot}</div>
    <div className="tier-applications-col">{model.stake}</div>
    <div className="tier-applications-col">{model.allocation} BNB</div>
  </div>
);

export const renderAllocationItemMobile = (model: RoyaStakeModel) => (
  <div className="tier-com-applications-list-item">
    <div className="tier-com-applications-list-item-header">
      <img src={model.icon} alt={model.title} />
      <span>{model.title}</span>
    </div>
    <div className="tier-com-applications-list-item-row">
      <span>SLOTS</span>
      <span>{model.slot}</span>
    </div>
    <div className="tier-com-applications-list-item-row">
      <span>STAKED ROYA</span>
      <span>{model.stake}</span>
    </div>
    <div className="tier-com-applications-list-item-row">
      <span>ALLOCATION PER TICKET</span>
      <span>${model.allocation}</span>
    </div>
  </div>
);
