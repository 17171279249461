/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import {
  AddressCont,
  DateAndRateText,
  HeaderPrimary,
  RegisterText,
} from "../pool-status/style";
import { CopyIcon } from "../../../../images";
import {
  ButtonContainer,
  DetailsContainer,
  InfoContainer,
  ProgressBar,
  TimeLeft,
} from "./style";

import { GameModel } from "src/logic/actions";
import { GameRegisterInfo } from "src/core";
import { TokenType, getEtherScanLink, getPoolContract } from "src/utils";
import "./style.scss"

interface Props {
  gameDetail?: GameModel;
  pool: any;
  setShow: (val: any) => void;
  show: boolean;
  isSuccess: boolean;
  startedStatus: any;
}

const PoolDetPoolDetailsNew: React.FC<Props> = ({
  pool,
  setShow,
  startedStatus,
  isSuccess,
  gameDetail,
}) => {
  const { walletConnected, userAddress } = useSelector(
    (state: any) => state.user
  );
  const { id } = useParams<any>();
  const [showAlert, setShowAlert] = useState(false);
  const [partingUsers, setPartingUsers] = useState(0);
  const regStartDate = new Date(pool?.startTime).getTime();
  const regEndDate = new Date(pool?.endTime).getTime();
  const regStarted: boolean = new Date(regStartDate).getTime() < Date.now();
  const regEnded: boolean = new Date(regEndDate).getTime() < Date.now();
  const remainingTime: boolean =
    new Date(regEndDate).getTime() - 1000 * 60 * 60 * 24 * 1 < Date.now();
  const regStartTime = moment(new Date(regStartDate)).fromNow();
  const regEndTime = moment(new Date(regEndDate)).fromNow();

  useEffect(() => {
    if (showAlert) {
      setTimeout(() => toast("Copied", { type: toast.TYPE.INFO }), 300);
    }
  }, [showAlert]);

  const getProgress = () => {
    const startDate = new Date(pool?.startTime as string);
    const endDate = new Date(pool?.endTime as string);
    const total = +endDate - +startDate;
    const elaps = Date.now() - +startDate;
    if (Date.now() - +endDate < 0) {
      return Math.round((elaps / total) * 100);
    } else {
      return 0;
    }
  };

  const handleRedeem = async () => {
    if (gameDetail && startedStatus === 2) {
      if (isSuccess) {
        await getPoolContract(gameDetail?.poolInfo?.poolAddress)
        .methods.redeemTokens()
        .send({ from: userAddress });
      } else {
        await getPoolContract(gameDetail?.poolInfo?.poolAddress)
        .methods.redeemGivenMinimumGoalNotAchieved()
        .send({ from: userAddress });
      }
    }
  };

  const renderButton = (): JSX.Element | null => {
    if (startedStatus === 0) {
      return (
        <>
          <button
            type="button"
            disabled
            className="btn-action-link1 w-full noncursor-pointer"
          >
            Coming Soon
          </button>
        </>
      );
    } else if (startedStatus === 1) {
      return (
        <>
          <button
            type="button"
            onClick={() => setShow(true)}
            className="btn-action-link w-full"
          >
            {pool?.type ? "Swap" : "Register for the sale"}
          </button>
        </>
      );
    } else if (startedStatus === 2) {
      return (
        <>
          <button
            type="button"
            disabled={pool && pool.type}
            onClick={() => handleRedeem()}
            className="btn-action-link w-full"
          >
            {pool?.type
              ? "Finished"
              : isSuccess
              ? "Redeem"
              : "Redeem Deposited Amount"}
          </button>
        </>
      );
    } else {
      return null;
    }
  };

  const getParticipatingGamesByGameId = async () => {
    const result = await GameRegisterInfo.getParticipatingGamesByGameId(+id);
    if (!result.error && result.results.length > 0) {
      setPartingUsers(result.results.length);
    }
  };

  useEffect(() => {
    getParticipatingGamesByGameId();
  }, [walletConnected, userAddress, id]);
  return (
    <DetailsContainer>
      <AddressCont
        onClick={() => {
          window.navigator.clipboard.writeText(
            `${getEtherScanLink()}/address/${pool?.poolAddress}` || ""
          );
          setShowAlert(true);
        }}
      >
        <div>{pool?.poolAddress || "Coming soon"}</div>
        <div> <img src={CopyIcon.default} alt="copy" /> Copy </div>
      </AddressCont>
      <InfoContainer>
          <div className="col-6">
            <div className="first-sales-col">
            <HeaderPrimary>Fundraised amount</HeaderPrimary>
            <DateAndRateText>
              {pool?.startTime
                ? `${pool?.totalRaise ? pool?.totalRaise : 0} ${TokenType[pool?.tokenVarious || 0]}`
                : "Coming Soon"}
            </DateAndRateText>
            </div>
          </div>
          {/* <div className="divider" /> */}
          <div className="col-6">
            <HeaderPrimary>Participants</HeaderPrimary>
            <DateAndRateText>{partingUsers}</DateAndRateText>
          </div>
      </InfoContainer>
      <RegisterText>
        {pool
          ? regEnded
            ? ("IPO Finished - " + (isSuccess ? 'Success' : 'Failed') )
            : regStarted
            ? `IPO Finished Ends ${regEndTime}`
            : `IPO Finished Starts ${regStartTime}`
          : "Coming soon"}
      </RegisterText>
      {!regEnded && (
        <ProgressBar progress={getProgress()}>
          <span className="filled"></span>
        </ProgressBar>
      )}
    <div className="r-button-group">
      <div className="r-btn-col"><ButtonContainer>{renderButton()}</ButtonContainer></div>
      <div className="r-btn-col"><ButtonContainer><button
            type="button"
            disabled
            className="btn-action-link1 w-full noncursor-pointer"
          >
            View Rules
          </button></ButtonContainer></div>

    </div>
      {pool && remainingTime && !regEnded && (
        <TimeLeft> {regEndTime} hours left before IPO closes</TimeLeft>
      )}
      <div className="r-bottom-pera">
        <p><span>Provably fair : </span> Our algo will select people in a truly decentralized and random fashion. You will only be allowed to participate in the sale if you are registered and selected.</p>
      </div>
      <div className="footer-social-link ">
          <ul className="social-link">
            <li>
              <a href="https://twitter.com/Royalegamepad" target="_blank" rel="noreferrer">
                <svg
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 4.72798C23.1 5.12798 22.2 5.42798 21.2 5.52798C22.2 4.92798 23 3.92798 23.4 2.82798C22.4 3.42798 21.4 3.82798 20.3 4.02798C19.4 3.02798 18.1 2.42798 16.7 2.42798C14 2.42798 11.8 4.62798 11.8 7.32798C11.8 7.72798 11.8 8.12798 11.9 8.42798C7.7 8.22798 4.1 6.22798 1.7 3.22798C1.2 4.02798 1 4.82798 1 5.72798C1 7.42798 1.9 8.92798 3.2 9.82798C2.4 9.82798 1.6 9.62798 1 9.22798C1 9.22798 1 9.22798 1 9.32798C1 11.728 2.7 13.728 4.9 14.128C4.5 14.228 4.1 14.328 3.6 14.328C3.3 14.328 3 14.328 2.7 14.228C3.3 16.228 5.1 17.628 7.3 17.628C5.6 18.928 3.5 19.728 1.2 19.728C0.8 19.728 0.4 19.728 0 19.628C2.2 21.028 4.8 21.828 7.5 21.828C16.6 21.828 21.5 14.328 21.5 7.82798C21.5 7.62798 21.5 7.42798 21.5 7.22798C22.5 6.52798 23.3 5.62798 24 4.72798Z"
                    fill="white"
                  />
                </svg>
              </a>
             
            </li>
            <li>
              <a href="https://t.me/RoyaleFinance" target="_blank" rel="noreferrer">
                <svg
                  viewBox="0 0 25 22"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.7745 0.559376C24.7552 0.462122 24.7127 0.37223 24.6514 0.298877C24.5902 0.225523 24.5123 0.171336 24.4257 0.141846C24.1106 0.0736005 23.7845 0.0991914 23.4814 0.215964C23.4814 0.215964 2.45364 8.51467 1.25289 9.43373C0.993391 9.63138 0.90714 9.74585 0.86439 9.88173C0.65664 10.5356 1.30314 10.8238 1.30314 10.8238L6.72266 12.7633C6.8142 12.7806 6.90817 12.7744 6.99716 12.7451C8.23016 11.8903 19.4014 4.1442 20.0472 3.88396C20.1484 3.8502 20.2234 3.88396 20.2069 3.96632C19.9429 4.95949 10.2957 14.3733 10.2424 14.4309C10.2165 14.4542 10.1962 14.4841 10.1831 14.5179C10.1701 14.5518 10.1647 14.5886 10.1674 14.6253L9.66341 20.432C9.66341 20.432 9.45191 22.2437 11.0989 20.432C12.2667 19.1481 13.3872 18.0857 13.9489 17.5694C15.8127 18.9817 17.8182 20.544 18.6829 21.3576C18.828 21.5124 19 21.6334 19.1887 21.7135C19.3774 21.7936 19.5788 21.8311 19.7809 21.8237C20.6059 21.7891 20.8309 20.7984 20.8309 20.7984C20.8309 20.7984 24.662 3.87079 24.791 1.60196C24.803 1.37961 24.8202 1.23714 24.8217 1.08479C24.8285 0.908082 24.8126 0.731201 24.7745 0.559376Z"
                    fill="white"
                  />
                </svg>
              </a>
              
            </li>
            <li>
              <a href="https://t.me/Royalefinanceoffici" target="_blank" rel="noreferrer">
                <svg
                  viewBox="0 0 24 25"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24 4.72798C23.1 5.12798 22.2 5.42798 21.2 5.52798C22.2 4.92798 23 3.92798 23.4 2.82798C22.4 3.42798 21.4 3.82798 20.3 4.02798C19.4 3.02798 18.1 2.42798 16.7 2.42798C14 2.42798 11.8 4.62798 11.8 7.32798C11.8 7.72798 11.8 8.12798 11.9 8.42798C7.7 8.22798 4.1 6.22798 1.7 3.22798C1.2 4.02798 1 4.82798 1 5.72798C1 7.42798 1.9 8.92798 3.2 9.82798C2.4 9.82798 1.6 9.62798 1 9.22798C1 9.22798 1 9.22798 1 9.32798C1 11.728 2.7 13.728 4.9 14.128C4.5 14.228 4.1 14.328 3.6 14.328C3.3 14.328 3 14.328 2.7 14.228C3.3 16.228 5.1 17.628 7.3 17.628C5.6 18.928 3.5 19.728 1.2 19.728C0.8 19.728 0.4 19.728 0 19.628C2.2 21.028 4.8 21.828 7.5 21.828C16.6 21.828 21.5 14.328 21.5 7.82798C21.5 7.62798 21.5 7.42798 21.5 7.22798C22.5 6.52798 23.3 5.62798 24 4.72798Z"
                    fill="white"
                  />
                </svg>
              </a>
             
            </li>
          
           
          </ul>
        </div>
    </DetailsContainer>
  );
};

export default PoolDetPoolDetailsNew;
