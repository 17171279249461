/* eslint-disable import/no-anonymous-default-export */
import Web3 from "web3";
import { WalletTypes, getConfig, configNetwork } from "./constants";
import store from "../logic/reducers";
import { login, logout } from "../logic/actions";
import WalletConnectProvider from "@walletconnect/web3-provider";
import Authereum from "authereum";
//@ts-ignore
import MEWconnect from "@myetherwallet/mewconnect-web-client";
import detectEthereumProvider from "@metamask/detect-provider";

import { Networks } from "./constants";
import { signMessageAsync } from "./signature";
import { WalletModel } from "../core/models/wallet";
import { ethers } from "ethers";

class Wallet {
  web3;
  walletType = 0;

  constructor() {
    this.web3 = new Web3(Web3.givenProvider);
  }

  async setProvider(type: number) {
    let provider;
    switch (type) {
      case WalletTypes.metamask:
        provider = await detectEthereumProvider();

        const { ethereum } = window;
        // console.log(ethereum)
        if (provider === ethereum) {
          // console.log("provider is ethereum")
          //@ts-ignore
          this.web3.setProvider(provider);
          this.walletType = type;
        }

        break;

      case WalletTypes.walletConnect:
        provider = new WalletConnectProvider({
          infuraId: getConfig().infuraId,
        });
        this.walletType = type;

        //@ts-ignore
        this.web3.setProvider(provider);
        console.log("curr provider ", this.web3.currentProvider);
        break;

      case WalletTypes.authereum:
        const authereum = new Authereum(configNetwork);
        provider = authereum.getProvider();
        this.web3.setProvider(provider);
        this.walletType = type;
        break;

      case WalletTypes.mewWallet:
        const ETH_JSONRPC_URL =
          "wss://ropsten.infura.io/ws/v3/287b5d14c20f4b7d9411d165fac6a688";
        const CHAIN_ID = 1;
        const mewConnect = new MEWconnect.Provider();
        provider = mewConnect.makeWeb3Provider(CHAIN_ID, ETH_JSONRPC_URL, true);
        this.web3.setProvider(provider);
        this.walletType = type;
        break;

      default:
        throw new Error("Invalid wallet type");
    }
  }

  reconnect = async (type: number, library: any) => {
    switch (type) {
      case WalletTypes.metamask:
        //@ts-ignore
        await this.web3.currentProvider.request({
          method: "eth_requestAccounts",
        });

        //@ts-ignore
        await this.web3.currentProvider.request({
          method: "eth_chainId",
        });

        //@ts-ignore
        this.web3.currentProvider.on("chainChanged", () => {
          window.location.reload();
        });

        //@ts-ignore
        this.web3.currentProvider.on(
          "accountsChanged",
          async (accounts: string[]) => {
            if (accounts.length) {
              const address = accounts[0];

              const chainIdWalletConnect = await this.web3.eth.getChainId();

              const dataMsg = await WalletModel.getNonceByWallet(address);
              const message = `royale.finance will use this cryptographic signature for verifying that you are the owner of current wallet address ${dataMsg}`;
              let provider;
              if (window.ethereum) {
                provider = new ethers.providers.Web3Provider(window.ethereum);
              }
              const signer = await provider?.getSigner(address);
              const signature = await signMessageAsync(
                signer,
                address,
                message
              );
              if (signature) {
                const result = await WalletModel.authByWallet(
                  signature,
                  address
                );
                console.log({ result });
                if (!result.error) {
                  store.dispatch(
                    login(
                      { address },
                      this.web3.utils.toHex(chainIdWalletConnect.toString())
                    )
                  );
                  localStorage.setItem(
                    "royale-accessToken",
                    result.results.accessToken
                  );
                  localStorage.setItem(
                    "royale-refreshToken",
                    result.results.refreshToken
                  );
                } else {
                  console.log("error in signatre", result.error);
                }
              }
            }
          }
        );

        break;

      case WalletTypes.walletConnect:
        //@ts-ignore
        await this.web3.currentProvider.enable();

        await this.web3.eth.getChainId();

        //@ts-ignore
        this.web3.currentProvider.on("chainChanged", () => {
          window.location.reload();
        });

        //@ts-ignore
        this.web3.currentProvider.on(
          "accountsChanged",
          async (accounts: string[]) => {
            if (accounts.length) {
              const address = accounts[0];
              const chainIdWalletConnect = await this.web3.eth.getChainId();
              const dataMsg = await WalletModel.getNonceByWallet(address);
              const message = `royale.finance will use this cryptographic signature for verifying that you are the owner of current wallet address ${dataMsg}`;
              let provider;
              if (window.ethereum) {
                provider = new ethers.providers.Web3Provider(window.ethereum);
              }
              const signer = await provider?.getSigner(address);
              const signature = await signMessageAsync(
                signer,
                address,
                message
              );
              if (signature) {
                const result = await WalletModel.authByWallet(
                  signature,
                  address
                );
                // console.log({ result });
                if (!result.error) {
                  store.dispatch(
                    login(
                      { address },
                      this.web3.utils.toHex(chainIdWalletConnect.toString())
                    )
                  );
                  localStorage.setItem(
                    "royale-accessToken",
                    result.results.accessToken
                  );
                  localStorage.setItem(
                    "royale-refreshToken",
                    result.results.refreshToken
                  );
                } else {
                  console.log("error in signatre", result.error);
                }
              }
            }
          }
        );

        break;

      case WalletTypes.authereum:
        //@ts-ignore
        await this.web3.currentProvider.enable();
        break;

      case WalletTypes.mewWallet:
        // console.log('current provider ', this.web3.currentProvider);
        //@ts-ignore
        await this.web3.currentProvider.enable();
        break;

      default:
        throw new Error("Invalid wallet type");
    }
  };

  login = async (type: number, library: any) => {
    let accounts: Array<string>;
    switch (type) {
      case WalletTypes.metamask:
        //@ts-ignore
        try {
          accounts = await this.web3.currentProvider.request({
            method: "eth_requestAccounts",
          });
        } catch (error) {
          if (
            error?.message ===
            "Already processing eth_requestAccounts. Please wait."
          ) {
            accounts = await this.web3.currentProvider.send(
              {
                method: "wallet_requestPermissions",
                params: [{ eth_accounts: {} }],
              },
              (error, response) => {
                if (error) console.error(error);
                else {
                  if (!!response?.result.length) {
                    return response?.result[0]?.caveats[0]?.value;
                  }
                }
              }
            );
          }
        }
        //@ts-ignore
        const chainId = await this.web3.currentProvider.request({
          method: "eth_chainId",
        });
        // console.log("chainId ", chainId);
        if (accounts.length) {
          const address = accounts[0];
          const dataMsg = await WalletModel.getNonceByWallet(address);
          const message = `royale.finance will use this cryptographic signature for verifying that you are the owner of current wallet address ${dataMsg.results}`;
          let provider;
          if (window.ethereum) {
            provider = new ethers.providers.Web3Provider(window.ethereum);
          }
          const signer = await provider?.getSigner(address);
          const networkInfo = await provider?.getNetwork();
          const signature = await signMessageAsync(signer, address, message);
          if (signature) {
            const result = await WalletModel.authByWallet(signature, address);
            if (!result.error) {
              store.dispatch(login({ address }, chainId,networkInfo?.chainId));
              localStorage.setItem(
                "royale-accessToken",
                result.results.accessToken
              );
              localStorage.setItem(
                "royale-refreshToken",
                result.results.refreshToken
              );
            } else {
              console.log("error in signatre", result.error);
            }
          }
        } else {
          throw new Error("No account found");
        }

        //@ts-ignore
        this.web3.currentProvider.on(
          "accountsChanged",
          async (accounts: Array<string>) => {
            // console.log('accounts on change', accounts);

            //@ts-ignore
            const chainId = await this.web3.currentProvider.request({
              method: "eth_chainId",
            });

            if (accounts.length) {
              const address = accounts[0];
              const dataMsg = await WalletModel.getNonceByWallet(address);
              const message = `royale.finance will use this cryptographic signature for verifying that you are the owner of current wallet address ${dataMsg}`;
              let provider;
              if (window.ethereum) {
                provider = new ethers.providers.Web3Provider(window.ethereum);
              }
              const signer = await provider?.getSigner(address);
              const networkInfo = await provider?.getNetwork();
              const signature = await signMessageAsync(
                signer,
                address,
                message
              );
              if (signature) {
                const result = await WalletModel.authByWallet(
                  signature,
                  address
                );
                if (!result.error) {
                  store.dispatch(login({ address }, chainId,networkInfo?.chainId));
                  localStorage.setItem(
                    "royale-accessToken",
                    result.results.accessToken
                  );
                  localStorage.setItem(
                    "royale-refreshToken",
                    result.results.refreshToken
                  );
                } else {
                  console.log("error in signatre", result.error);
                }
              }
            }
          }
        );

        //@ts-ignore
        this.web3.currentProvider.on("chainChanged", () => {
          window.location.reload();
        });

        break;

      case WalletTypes.walletConnect:
        //@ts-ignore
        accounts = await this.web3.currentProvider.enable();

        const chainIdWalletConnect = await this.web3.eth.getChainId();

        if (accounts.length) {
          const address = accounts[0];
          const dataMsg = await WalletModel.getNonceByWallet(address);
          const message = `royale.finance will use this cryptographic signature for verifying that you are the owner of current wallet address ${dataMsg}`;
          let provider;
          if (window.ethereum) {
            provider = new ethers.providers.Web3Provider(window.ethereum);
          }
          const signer = await provider?.getSigner(address);
          const signature = await signMessageAsync(signer, address, message);
          if (signature) {
            const result = await WalletModel.authByWallet(signature, address);
            if (!result.error) {
              store.dispatch(
                login(
                  { address },
                  this.web3.utils.toHex(chainIdWalletConnect.toString())
                )
              );
              localStorage.setItem(
                "royale-accessToken",
                result.results.accessToken
              );
              localStorage.setItem(
                "royale-refreshToken",
                result.results.refreshToken
              );
            } else {
              console.log("error in signatre", result.error);
            }
          }
        } else {
          throw new Error("No account found");
        }

        //@ts-ignore
        this.web3.currentProvider.on("chainChanged", () => {
          window.location.reload();
        });

        //@ts-ignore
        this.web3.currentProvider.on(
          "accountsChanged",
          async (accounts: string[]) => {
            if (accounts.length) {
              const address = accounts[0];

              const chainIdWalletConnect = await this.web3.eth.getChainId();

              const dataMsg = await WalletModel.getNonceByWallet(address);
              const message = `royale.finance will use this cryptographic signature for verifying that you are the owner of current wallet address ${dataMsg}`;
              let provider;
              if (window.ethereum) {
                provider = new ethers.providers.Web3Provider(window.ethereum);
              }
              const signer = await provider?.getSigner(address);
              const signature = await signMessageAsync(
                signer,
                address,
                message
              );
              if (signature) {
                const result = await WalletModel.authByWallet(
                  signature,
                  address
                );
                if (!result.error) {
                  store.dispatch(
                    login(
                      { address },
                      this.web3.utils.toHex(chainIdWalletConnect.toString())
                    )
                  );
                  localStorage.setItem(
                    "royale-accessToken",
                    result.results.accessToken
                  );
                  localStorage.setItem(
                    "royale-refreshToken",
                    result.results.refreshToken
                  );
                } else {
                  console.log("error in signatre", result.error);
                }
              }
            }
          }
        );

        break;

      case WalletTypes.authereum:
        //@ts-ignore
        accounts = await this.web3.currentProvider.enable();
        if (accounts.length) {
          const address = accounts[0];
          const dataMsg = await WalletModel.getNonceByWallet(address);
          const message = `royale.finance will use this cryptographic signature for verifying that you are the owner of current wallet address ${dataMsg}`;
          const signer = library.getSigner(address);
          const signature = await signMessageAsync(signer, address, message);
          if (signature) {
            const result = await WalletModel.authByWallet(signature, address);
            if (!result.error) {
              store.dispatch(login({ address }, Networks.mainnet));
              localStorage.setItem(
                "royale-accessToken",
                result.results.accessToken
              );
              localStorage.setItem(
                "royale-refreshToken",
                result.results.refreshToken
              );
            } else {
              console.log("error in signatre", result.error);
            }
          }
        } else {
          throw new Error("No account found");
        }
        break;

      case WalletTypes.mewWallet:
        // console.log('current provider ', this.web3.currentProvider);
        //@ts-ignore
        accounts = await this.web3.currentProvider.enable();

        // console.log('accounts ', accounts);

        if (accounts.length) {
          const address = accounts[0];
          const dataMsg = await WalletModel.getNonceByWallet(address);
          const message = `royale.finance will use this cryptographic signature for verifying that you are the owner of current wallet address ${dataMsg}`;
          const signer = library.getSigner(address);
          const signature = await signMessageAsync(signer, address, message);
          if (signature) {
            const result = await WalletModel.authByWallet(signature, address);
            if (!result.error) {
              store.dispatch(login({ address }, ""));
              localStorage.setItem(
                "royale-accessToken",
                result.results.accessToken
              );
              localStorage.setItem(
                "royale-refreshToken",
                result.results.refreshToken
              );
            } else {
              console.log("error in signatre", result.error);
            }
          }
        } else {
          throw new Error("No account found");
        }
        break;

      default:
        throw new Error("Invalid wallet type");
    }
  };

  disconnect = async () => {
    switch (this.walletType) {
      case WalletTypes.metamask:
        //@ts-ignore
        await this.web3.currentProvider._handleDisconnect();
        store.dispatch(logout());
        break;

      case WalletTypes.walletConnect:
        store.dispatch(logout());
        break;

      case WalletTypes.authereum:
        //@ts-ignore
        await this.web3.currentProvider.disable();
        store.dispatch(logout());
        break;

      case WalletTypes.mewWallet:
        //@ts-ignore
        await this.web3.currentProvider.disconnect();
        store.dispatch(logout());
        break;

      default:
        throw new Error("Invalid wallet type");
    }
  };
}

export default new Wallet();
