/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import { Dropdown, Modal } from "../../components";
import { UploadIcon1 } from "../../images";
import { UploadContainer } from "./upload";
import { Game } from "../../core";
import { CongratScreen } from "./congrat-screen/congrat-screen";
import { UploadLogoContainer } from "./uploadLogo";
import { toast } from "react-toastify";
import {
  isHttpValid,
  validURL,
  validateEmail,
  validateYouTubeUrl,
} from "../../utils/authUtil";
import LoginModalContainer from "../../components/login-modal";
import "./style.scss";

interface Params {
  id: string;
}

const initialFormData = {
  name: undefined,
  description: undefined,
  fullDescription: "",
  status: undefined,
  raiseAmount: undefined,
  videoLink: undefined,
  websiteUrl: undefined,
  pitchDeckLink: undefined,
  email: undefined,
  telegram: undefined,
  twitter: undefined,
  thumbnailURL: "",
  tokenURL: "",
  visualMaterialURL: "",
};

const EditGame = () => {
  const [showMsg, setShowMsg] = useState(false);
  const [formData, updateFormData] = useState<any>(initialFormData);
  const [logoUrl, setLogoUrl] = useState("");
  const [thumbnailUrl, setThumbnailUrl] = useState("");
  const [selectedOption, setSelectedOption] = useState("Any way");
  const [show, setShow] = useState(false);
  const [gameInfo, setGameInfo] = useState<any>();
  const [materialImg, setMaterialImg] = useState("");
  const [isVideoList, setIsVideoList] = useState([]);

  const history = useHistory();
  const { walletConnected, userAddress } = useSelector(
    (state: any) => state.user
  );
  const { id }: Params = useParams();

  const handleChange = (e: any) => {
    updateFormData({
      ...formData,
      // Trimming any whitespace
      [e.target.name]: e.target.value,
    });
  };

  const closeModal = () => {
    setShow(false);
  };

  const handleUpdate = async (e: any) => {
    e.preventDefault();
    if (!walletConnected) {
      setShow(true);
      return;
    }
    if (userAddress !== gameInfo.user.walletAddress) {
      toast("You have no permission to update this project.", {
        type: toast.TYPE.INFO,
      });
      return;
    }

    if (formData.name === "" || formData.name === undefined) {
      toast("Please input project name", { type: toast.TYPE.INFO });
    } else if (
      formData.description === "" ||
      formData.description === undefined
    ) {
      toast("Please input project description", { type: toast.TYPE.INFO });
    } else if (formData.status === "" || formData.status === undefined) {
      toast("Please select current status", { type: toast.TYPE.INFO });
    } else if (
      formData.raiseAmount === "" ||
      formData.raiseAmount === undefined
    ) {
      toast("Please input fundraise goal", { type: toast.TYPE.INFO });
    } else if (
      (formData.videoLink === "" ||
        formData.videoLink === undefined ||
        validateYouTubeUrl(formData.videoLink) === false) &&
      isVideoList?.length === 0
    ) {
      toast(
        "Please add video link correctly, you need to add full YouTube video link",
        { type: toast.TYPE.INFO }
      );
    } else if (
      isVideoList?.length === 0 &&
      (formData.videoLink === "" || formData.videoLink === undefined)
    ) {
      toast("Please Upload Project explainer video", { type: toast.TYPE.INFO });
    } else if (
      formData.pitchDeckLink === "" ||
      formData.pitchDeckLink === undefined
      // || validateCloudStorageUrl(formData.pitchDeckLink) === false
    ) {
      toast(
        "Please add pitchDeck link correctly, you need to add full pitchDeck link",
        { type: toast.TYPE.INFO }
      );
    } else if (
      (formData.thumbnailURL === "" || formData.thumbnailURL === undefined) &&
      !thumbnailUrl
      // ||validateThumbnailLink(formData.thumbnailURL) === false
    ) {
      toast(
        "Please add thumbnail link correctly, you need to add full thumbnail link",
        { type: toast.TYPE.INFO }
      );
    } else if (
      (formData.tokenURL === "" || formData.tokenURL === undefined) &&
      !logoUrl
      // ||validateThumbnailLink(formData.tokenURL) === false
    ) {
      toast(
        "Please add Token link correctly, you need to add full Token link",
        {
          type: toast.TYPE.INFO,
        }
      );
    } else if (
      (formData.visualMaterialURL === "" ||
        formData.visualMaterialURL === undefined) &&
      materialImg?.length === 0
      // ||validateThumbnailLink(formData.visualMaterialURL) === false
    ) {
      toast(
        "Please add materials link correctly, you need to add full materials link",
        {
          type: toast.TYPE.INFO,
        }
      );
    } else if (
      (thumbnailUrl === "" || thumbnailUrl === undefined) &&
      (formData.thumbnailURL === "" || formData.thumbnailURL === undefined)
    ) {
      toast("Please add thumbnail of your project", { type: toast.TYPE.INFO });
    } else if (
      (materialImg.length === 0 || materialImg === undefined) &&
      (formData.visualMaterialURL === "" ||
        formData.visualMaterialURL === undefined)
    ) {
      toast("Please add visual material of your project", {
        type: toast.TYPE.INFO,
      });
    } else if (
      formData.websiteUrl === "" ||
      formData.websiteUrl === undefined ||
      isHttpValid(formData.websiteUrl) === false ||
      validURL(formData.websiteUrl) === false
    ) {
      toast("Please add website link correctly", { type: toast.TYPE.INFO });
    } else if (
      formData.email === "" ||
      formData.email === undefined ||
      validateEmail(formData.email)
    ) {
      toast("Please add email address to contact correctly", {
        type: toast.TYPE.INFO,
      });
    } else if (formData.telegram === "" || formData.telegram === undefined) {
      toast("Please add telegram id to contact", { type: toast.TYPE.INFO });
    } else if (formData.twitter === "" || formData.twitter === undefined) {
      toast("Please add twitter id to verify", { type: toast.TYPE.INFO });
    } else {
      const payload = {
        name: formData.name,
        description: formData.description,
        fullDescription: formData.fullDescription,
        thumbnail: thumbnailUrl,
        status: formData.status,
        raiseAmount: formData.raiseAmount,
        logo: logoUrl,
        videoLink: formData.videoLink,
        websiteUrl: formData.websiteUrl,
        pitchDeckLink: formData.pitchDeckLink,
        email: formData.email,
        telegram: formData.telegram,
        twitter: formData.twitter,
        preferChat: selectedOption,
        isActive: true,
        thumbnailURL: formData.thumbnailURL,
        tokenURL: formData.tokenURL,
        video: JSON.stringify(isVideoList),
        visualMaterialLink: JSON.stringify(materialImg),
        visualMaterialURL: formData.visualMaterialURL,
      };
      const result = await Game.updateGameByOwner(+id, payload);
      if (!result.error) {
        setShowMsg(true);
        history.push("/account/my-project");
        // TODO: need to store redux
      } else {
        alert(result.errorCode);
      }
    }
  };

  const findGameById = async () => {
    const { error, result } = await Game.findGameById(+id);
    if (!error) {
      const visualMaterialLink = JSON.parse(result.visualMaterialLink);
      updateFormData(result);
      setThumbnailUrl(result.thumbnail);
      setLogoUrl(result.logo);
      setGameInfo(result);
      setMaterialImg(visualMaterialLink);
      setSelectedOption(result?.preferChat);
      setIsVideoList(JSON.parse(result?.video));
    }
  };

  useEffect(() => {
    if (walletConnected && id) {
      findGameById();
    }
  }, [walletConnected, userAddress]);

  return (
    <>
      {!showMsg && (
        <div className="container">
          <div className="submit-wrap">
            <div className="heading-row">
              <div className="headingbox">
                <h2>Edit Your Game</h2>
              </div>
            </div>
            <h3>Royale crowdfunding platform and marketplace</h3>
            <div className="submit-row">
              <div className="submit-container">
                <div className="submit-box-wrap">
                  <div className="border-box-wrap">
                    <div className="submit-inner-box">
                      <p className="bright-text text-left w-100">
                        About Your Game
                      </p>
                      <div className="submit-form-group">
                        <label className="submit-label">Game Name</label>
                        <input
                          type="text"
                          placeholder="Enter the name of your project"
                          className="submit-form-control"
                          name="name"
                          value={formData.name}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="submit-form-group">
                        <label className="submit-label">Game Description</label>
                        <input
                          type="text"
                          placeholder="Tell us about your project in a few senteces: genre, idea, concept."
                          className="submit-form-control"
                          name="description"
                          value={formData.description}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      {/* <div className="submit-form-group">
                        <label className="submit-label">Full Description</label>
                        <ReactQuill
                          modules={quillModules}
                          formats={quillFormats}
                          value={formData.fullDescription}
                          onChange={handleFullDescriptionChange}
                        />
                      </div> */}
                      <div className="submit-form-group">
                        <label className="submit-label">Project Status</label>
                        <input
                          type="text"
                          placeholder="Describe the stage your project is at."
                          className="submit-form-control"
                          name="status"
                          value={formData.status}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="submit-form-group">
                        <label className="submit-label">Expected budget</label>
                        <input
                          type="text"
                          placeholder="Enter the amount you need to deliver your project (e.g. “$50k”)."
                          className="submit-form-control"
                          name="raiseAmount"
                          value={formData.raiseAmount}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="submit-form-group">
                        <label className="submit-label">Website URL</label>
                        <input
                          type="text"
                          placeholder="Your website address."
                          className="submit-form-control"
                          name="websiteUrl"
                          value={formData.websiteUrl}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>

                      <div className="submit-form-group">
                        <label className="submit-label">Pitchdeck link</label>
                        <input
                          type="text"
                          placeholder="Google drive / Dropbox link."
                          className="submit-form-control"
                          name="pitchDeckLink"
                          value={formData.pitchDeckLink}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="submit-form-group">
                        <label className="submit-label">
                          Preferred way of communication
                        </label>
                        <Dropdown
                          initialValue={selectedOption}
                          handleSelect={(e) => setSelectedOption(e)}
                          optionItems={["Any way", "Email", "Telegram"]}
                          className="w-100 drop-down"
                        />
                      </div>
                      <div className="submit-form-group">
                        <label className="submit-label">Contact email</label>
                        <input
                          type="text"
                          placeholder="We will contact you by your email."
                          className="submit-form-control"
                          name="email"
                          value={formData.email}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="submit-form-group">
                        <label className="submit-label">Telegram handle</label>
                        <input
                          type="text"
                          placeholder="We may contact you on Telegram."
                          className="submit-form-control"
                          name="telegram"
                          value={formData.telegram}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="submit-form-group">
                        <label className="submit-label">Twitter handle</label>
                        <input
                          type="text"
                          placeholder="We may contact you on Twitter."
                          className="submit-form-control"
                          name="twitter"
                          value={formData.twitter}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="upload-wrap">
                        <UploadContainer
                          type="image"
                          imgUrl={gameInfo && gameInfo.thumbnail.split(',')[0]}
                          label="Upload Thumbnail Image"
                          options={["PNG or JPG", "up to 20 MB"]}
                          text="Upload a thumbnail image that will be shown on your game
                          card"
                          setMediaURL={setThumbnailUrl}
                          mediaURL={thumbnailUrl.split(',')[0]}
                        />
                      </div>
                      <div className="upload-wrap">
                        <UploadLogoContainer
                          type="image"
                          imgUrl={UploadIcon1.default}
                          label="Upload Token Logo Image"
                          options={["PNG or JPG", "up to 20 MB"]}
                          text="Upload Token logo"
                          setMediaURL={setLogoUrl}
                          mediaURL={logoUrl}
                        />
                      </div>
                      <div className="upload-wrap">
                        <UploadContainer
                          type="image"
                          imgUrl={materialImg}
                          label="Upload Visual Material"
                          options={["PNG or JPG", "up to 20 MB"]}
                          text="Upload a logo, game screenshots or artwork"
                          setMediaURL={setMaterialImg}
                          mediaURL={materialImg}
                        />
                      </div>
                      <div className="submit-form-group">
                        <label className="submit-label">
                          Project explainer video
                        </label>
                        <input
                          type="text"
                          placeholder="Add an explainer YouTube video via Youtube embed option"
                          className="submit-form-control"
                          name="videoLink"
                          value={formData.videoLink}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                      <div className="submit-form-group submit-form-review">
                        <p>
                          We will review your submissions within 48 hours. If
                          your project potentially fits our platform, we will
                          contact you through your provided email address or on
                          Telegram to arrange a call.
                        </p>
                      </div>
                      <div className="submit-action-row">
                        <button
                          type="button"
                          className="btn-action-link"
                          onClick={handleUpdate}
                        >
                          Update your game
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {showMsg && <CongratScreen id={0} />}
      <Modal show={show} closeModal={closeModal}>
        <LoginModalContainer show={show} closeModal={closeModal} />
      </Modal>
    </>
  );
};

export default EditGame;
