import { ErrorMessage } from 'formik';
import { useEffect } from 'react';
import wallet from '../../../../utils/wallet';
import { FieldContainer, InputField, ErrorContainer } from '../../style';

// eslint-disable-next-line @typescript-eslint/no-var-requires
const userTokenAbi = require('../../../../utils/abis/user-token.json');

interface Props {
  values: any;
  handleTokenSymbol: (type: string) => void;
  handleTokenDecimal: (type: string) => void;
  handleTokenAddress: (type: string) => void;
}

const TokenDetails: React.FC<Props> = (props) => {
  const { values, handleTokenDecimal, handleTokenSymbol } = props;
  const fetchTokenData = async () => {
    const token = new wallet.web3.eth.Contract(
      userTokenAbi.abi,
      values.tokenAddress
    );
    try {
      const decimalsValue = await token.methods.decimals().call();
      const symbolValue = await token.methods.symbol().call();
      handleTokenSymbol(symbolValue);
      handleTokenDecimal(decimalsValue);
    } catch (err) {
      alert(
        'unable to fetch token symbol and decimals or invalid address provided or your wallet is not connected.'
      );
    }
  };
  useEffect(() => {
    if (wallet.web3.utils.isAddress(values.tokenAddress)) {
      fetchTokenData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.tokenAddress]);
  return (
    <>
      <div className="pool-step-01">
        <div className="pool-name-box">
          <div className="step-form-control">
            <label>Token Address</label>
            <FieldContainer>
              <InputField
                name="tokenAddress"
                placeholder="Token Address"
                className="submit-form-control"
                onChange={(e) =>  props.handleTokenAddress(e.target.value)}
              />
            </FieldContainer>
            <ErrorContainer>
              <ErrorMessage name="tokenAddress" />
            </ErrorContainer>
          </div>
          <div className="step-form-control">
            <label>Token Symbol</label>
            <InputField
              name="tokenSymbol"
              placeholder="Token Symbol"
              className="submit-form-control"
            />
            <ErrorContainer>
              <ErrorMessage name="tokenSymbol" />
            </ErrorContainer>
          </div>
          <div className="step-form-control">
            <label>Decimals</label>
            <InputField
              name="decimals"
              placeholder="Decimals"
              className="submit-form-control"
            />
            <ErrorContainer>
              <ErrorMessage name="decimals" />
            </ErrorContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default TokenDetails;
