/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from 'react';
import { PlusWhiteIcon, MinusWhiteIcon } from '../../images';
import { NftModel } from '../../utils';
import { ProgressBar } from '../progress-bar';

import './style.scss';

interface Props {
  model: NftModel;
  onClickBuy: (value: any, count: number) => void;
  totalMint: string
  currentSold: string
}

export const NftCard: React.FC<Props> = ({
  model,
  onClickBuy,
  totalMint = '0',
  currentSold = '0'
}) => {
  const [count, setCount] = useState<number>(1);

  const decrementCount = () => {
    setCount(count - 1 < 1 ? 1 : count - 1);
  };

  const incrementCount = () => {
    setCount(count + 1);
  };

  return (
    <div className="nft-card">
      <div className="nft-card-colbox">
        <div className="nft-card-imgbox">
          <img
            src={model.img}
            className={model.buy > 3 ? 'origin' : ''}
            alt={model.title}
          />
        </div>
        <div className="nft-card-content-info">
          <div className="nft-card-token">{model.price} BNB</div>
          <div className="nft-card-description">
            <div>{currentSold} sold / {totalMint} total</div>
            <ProgressBar value={+currentSold / +totalMint} />
          </div>
        </div>
      </div>
      <div className="nft-card-discount-row nft-card-discount-box">
        <div className="input-container">
          <button
            className="count-minus"
            onClick={decrementCount}
            disabled={count === 1 && true}
          >
            <img src={MinusWhiteIcon.default} alt="minus white" />
          </button>
          <span className="count-text">{count}</span>
          <button
            className="count-plus"
            onClick={incrementCount}
            disabled={count === 100 && true}
          >
            <img src={PlusWhiteIcon.default} alt="plus white" />
          </button>
        </div>
        <button
          type="button"
          className="btn-buy"
          onClick={() => onClickBuy(model, count)}
        >
          Buy
        </button>
      </div>
    </div>
  );
};
