import { AboutWrapper, AboutContainer, AboutHeader, AboutText } from "./style";
import { Collapse } from "../../../../components";

const About = () => {
  return (
    <AboutWrapper>
      <AboutContainer>
        <AboutHeader>About Tesla motors pool</AboutHeader>
        <AboutText>
          Et has minim elitr intellegat. Mea aeterno eleifend antiopam ad, nam
          no suscipit quaerendum. At nam minimum ponderum. Est audiam animal
          molestiae te. Ex duo eripuit mentitum.Globally network
          principle-centered channels and robust testing procedures. Continually
          actualize client-based channels without resource-leveling internal or
          "organic" sources. Professionally engage.
        </AboutText>
        <Collapse title={"Market strategy"} />
        <Collapse title={"Product"} />
        <Collapse title={"Revenue streams"} />
        <Collapse title={"PARTNERSHIPS & INVESTORS"} />
        <Collapse title={"Annual growth"} />
      </AboutContainer>
    </AboutWrapper>
  );
};

export default About;
