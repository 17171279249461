import { PageNotFoundGra } from 'src/images';
import './style.scss';

export const PageNotFound = () => {
  return (
    <>
      <div className="container">
        <div className="page-not-found-container flex flex-column justify-center items-center">
          <img src={PageNotFoundGra.default} alt="404" />
          <p>Page Not Found</p>
          <a href="/" className="btn-action-link">
            Return Home
          </a>
        </div>
      </div>
    </>
  );
};
