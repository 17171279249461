import { configNetwork } from "./constants";

export const getContractAddress = () => {
  return ContractAddress[configNetwork];
};

export const ContractAddress = {
  mainnet: {
    mDai: "0x6522765f3Ad4FDb1cA04DCa5C7106c8f2fEA7d9C",
    mUsdc: "0x6522765f3Ad4FDb1cA04DCa5C7106c8f2fEA7d9C",
    mUsdt: "0x6522765f3Ad4FDb1cA04DCa5C7106c8f2fEA7d9C",

    royaReserve: "0x7fac371c6222caf705b21489a0a5600a2a773a35",

    rpToken: "0xee7C29f912760611365108aCe4AE86Bc76b8488e",
    royaleLP: "0xFDC4ca4f70C59D3Cd306BeB3Ecd83b1d1C71172c",

    rptStaking: "0x7124aEE4cD902E738e6B682F7e256aC3E591A9D8",
    mRoya: "0xA9a933E0d816B36Dbf7Da87F27AC3BC4DCC2582E",

    royaToken: "0x7eaf9c89037e4814dc0d9952ac7f888c784548db",
    royaNFT: "0x448cb15b00ced3ac47467e33493d602f34c8e77e",

    settlerLot: "0xFb9DB01073EBcf041F21640009B8fcf9EAA38e0D",
    merchantLot: "0x6796EfE7E57b4C3bEccD179CBD06c4275cbDd6D8",
    kingLot: "0x0d7660EC0558A386d7Cb370CbA9e4Ab5B3534a4e",
    queenLot: "0x323d78fAd9ea2b3c9B07c3E48d630AAc85e48E57",
    royaleFlushLot: "0x7D6b1197457EcDaCb1a7c61Ad1749223b5667988",
    settlerNFTLot: "0x26eb9EdB3f7f74172011766dB5EE685415237D7b",
    merchantNFTLot: "0xCd94D76D4CDfE569e30615dbe62bC24dDD66ca9A",
    knightNFTLot: "0x81cda479b28185908D57588746d8807E93cDC467",
    archonNFTLot: "0xA52c58f6a27535dD8D36787688c9eb8CC8920eE5",
    monarchNFTLot: "0xDBB035839Fc1a317Fcf4173882A7230c481a7F09",
    kingNFTLot: "0xD76e741488EafDCf16276fbdCa23653941C63aef",
    queenNFTLot: "0xA52c58f6a27535dD8D36787688c9eb8CC8920eE5",
    royaleFlushNFTLot: "0xDBB035839Fc1a317Fcf4173882A7230c481a7F09",
    tokenSwap: "0x62901adb942943e7af78a2aE34AA1aE6710203da",
    poolFactory:"0x6cb8b7f1eCb5a8a96d73d70776415995BE19e73A",
  },
  sepolia: {
    poolFactory:"0x6cb8b7f1eCb5a8a96d73d70776415995BE19e73A",
    royaHolderProfile: "0xD2Fa8932AD2985242BaE38EEc78d4bFb91e63fB0",

    mDai: "0x21e283C686E7dfDf030e1E4c3f2b8A8a4F5D979C",
    mUsdc: "0x21e283C686E7dfDf030e1E4c3f2b8A8a4F5D979C",
    mUsdt: "0x21e283C686E7dfDf030e1E4c3f2b8A8a4F5D979C",

    royaReserve: "0x543D175A2cFAb9ced91760A451058Bc4902aA238",

    rpToken: "0xa479108372F2f3bB95D5C630B79Cb6eA6cC5BdA3",
    royaleLP: "0xE877fa26e433a87f80F4063F8a1Dca212eC757bc",

    rptStaking: "0xcf51f1c8B99Fc888eDB9815032A7E6b7152c9a7a",
    mRoya: "0x0000000000000000000000000000000000000000",

    royaToken: "0xb3603734eD5a53064bF61a7Dd86b853e999A4C47",
    royaNFT: "0x94c8455685ea584bd62cc82828f190b17faefd09",

    settlerLot: "0xF38d99b92F7cA7A5482F67bc9857aF6F80a51138",
    merchantLot: "0xbA4B254c374c93898e85CF36d806055e0FdB8682",
    knightLot: "0x9B4917BF892b02506395231eAbCb3FAC536e3715",
    archonLot: "0x2A07c9b0481C3769DE1A038895A421D41eF51D65",
    monarchLot: "0xd3AA38598Eb3B4c5Cc4ed826657897Aa7F7E0775",
    settlerNFTLot: "0x26eb9EdB3f7f74172011766dB5EE685415237D7b",
    merchantNFTLot: "0xCd94D76D4CDfE569e30615dbe62bC24dDD66ca9A",
    knightNFTLot: "0x81cda479b28185908D57588746d8807E93cDC467",
    royaleFlushNFTLot: "0xDBB035839Fc1a317Fcf4173882A7230c481a7F09",
    kingNFTLot: "0xD76e741488EafDCf16276fbdCa23653941C63aef",
    queenNFTLot: "0xA52c58f6a27535dD8D36787688c9eb8CC8920eE5",
    archonNFTLot: "0xA52c58f6a27535dD8D36787688c9eb8CC8920eE5",
    monarchNFTLot: "0xDBB035839Fc1a317Fcf4173882A7230c481a7F09",
    tokenSwap: "0x0000000000000000000000000000000000000000",
    kingLot:"0x95fF47ec85CA2d5f31DD0421371d764F83Fc3D65",
    queenLot: "0x81cda479b28185908D57588746d8807E93cDC467",
    royaleFlushLot: "0xEcFd76eBeC950671F7a938B74561eF7F94F25848",
  },
  
};

export const apiUrl = "https://testing-launchpad.royale.finance/api";

// export const TierAddress = {
//   Tier1:"0xBe8f2B95fAa9Ce06f122B20e05F3c98D696d5746",
//   Tier2: "0xA6329b20FD6Fdd4CD6acEeCC6B70F7B6e2288caB",
//   Tier3: "0x2200F901a146Fb2295Fc79fA7bcA0eEb13A49Dcc",
//   Tier4: "0xAB75d37A2d71C1A73f55e4d22D35B12DDA67909e",
//   Tier5: "0xEcFd76eBeC950671F7a938B74561eF7F94F25848"
// }

export const stableCoinList = [
  '0x21e283C686E7dfDf030e1E4c3f2b8A8a4F5D979C',  // USDT
  '0x21e283C686E7dfDf030e1E4c3f2b8A8a4F5D979C',   // USDC
  '0x21e283C686E7dfDf030e1E4c3f2b8A8a4F5D979C',
  '0x21e283C686E7dfDf030e1E4c3f2b8A8a4F5D979C',
]