import styled from "styled-components";
import { NavLink } from "react-router-dom";
import { Color } from "../../../utils";

export const ButtonContainer = styled.div`
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  gap: 12px;
  justify-content: center;
  @media (max-width: 700px) {
    display: grid;
  }

  .btn-action-link {
    display: inline-block;
    padding: 22px 22px;
    background: rgba(255, 255, 255, 0.02);
    border-right: none;

    &.bottom-border-none {
      background: linear-gradient(
        223.88deg,
        rgba(255, 255, 255, 0) 1.79%,
        rgba(255, 255, 255, 0.15) 98.47%
      );
      backdrop-filter: blur(20px);
    }
  }
`;

export const ImageContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  text-align: center;
  padding: 64px 0;

  img {
    width: 218px;
  }
`;

export const Link = styled(NavLink).attrs((props) => ({
  activeClassName: props.activeClassName || "active",
}))`
  display: block;
  align-items: center;
  text-decoration: none;
  padding: 10px 20px;
  color: white;
  border-right: 4px solid transparent;
  background: transparent;
  &.${(props) => props.activeClassName} {
    color: ${Color.cyanPrimary};
    background: ${Color.lightBluePrimary};
    border-right: 4px solid ${Color.cyanPrimary};
  }

  @media (min-width: 991px) {
    display: inline-flex;
  }
`;
