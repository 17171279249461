import styled from 'styled-components';
import { Theme } from '../../utils';
import { Modalbg } from 'src/images';

interface Props {
  show: boolean;
}

export const ModalWrapper = styled.div<Props>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${Theme.modalBg};
  display: ${(props) => (props.show ? 'block' : 'none')};
  z-index: ${(props) => (props.show ? '3' : '-3')};
  opacity: ${(props) => (props.show ? '1' : '0')};
  display:flex;
  align-items:center;
  justify-content:center;
`;

export const ModalBorder = styled.div<{ modalWidth?: string }>`
  position: relative;
  padding: 1px;
  width: ${(props) => (props.modalWidth ? props.modalWidth : 'auto')};
  border-radius: 12px;
  margin: 105px auto 0;

  @media screen and (max-width: 800px) {
    width: 95vw;
    padding: 1px;
    height: auto;
  }
`;

export const ModalContainer = styled.div<{ modalWidth?: string }>`
  width: ${(props) => (props.modalWidth ? props.modalWidth : '455px')};
  padding: 27px 35px 82px 35px;
  box-shadow: ${Theme.modalShadow};
  border-radius: ${Theme.componentBorderRadius};
  position: relative;
  // background: #171524;
  background: url(${Modalbg.default});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  @media (max-width: 800px) {
    width: 100%;
    margin: 0;
    padding: 50px 30px 41px 30px;
  }

  @media (max-width: 500px) {
    max-height: 99%;
    overflow-y: auto;
  }
`;

export const CrossIconContainer = styled.div`
  position: absolute;
  top: -25px;
  right: -30px;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  img {
    width: 100%;
    height: 100%;
  }

  @media (max-width: 600px) {
    width: 30px;
    height: 30px;
    top: 10px;
    left: 53%;
    transform: translateX(-50%);
  }
`;
