import gql from "graphql-tag";

export const USER = gql`
  query me {
    me {
      id
      walletAddress
      kycVerified
      nonce
    }
  }
`;
export const SUBSCRIBE = gql`
mutation Subscribe($email: String!) {
  subscribe(email: $email) {
    email
  }
}
`;
