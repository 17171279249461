import styled from "styled-components";

export const DetailsContainer = styled.div`
  background: linear-gradient(223.88deg, rgba(255, 255, 255, 0) 1.79%, rgba(255, 255, 255, 0.08) 98.47%);
  backdrop-filter: blur(20px);
  border-radius: 24px;
  padding: 5%;
`;

export const InfoContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 3%;
  
  div {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  
  .divider {
    border: 1px solid #E1E1E1;
    transform: rotate(90deg);
    width: 46px;
    height: 0;
  }
`;

export const TimeLeft = styled.div`
  width: 100%;
  color: #000000;
  padding: 6px;
  font-family: 'Cairo';
  background: #CDC8E7;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  border-radius: 4px;
  text-align: center;
  margin-top: 20px;
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 10px;
`;

export const ProgressBar = styled.div<{ progress: number;}>`
  background: rgba(255, 255, 255, 0.04);
  border-radius: 97px;
  height: 11px;
  
  .filled {
    display: block;
    height: inherit;
    background: linear-gradient(88.83deg, #EEEEEE -290.8%, #FFFEE2 -272.69%, rgba(255, 255, 255, 0.950883) -259.54%, #DF68FD -244.29%, rgba(255, 255, 255, 0.815523) -226.25%, #B7EEE5 -202.79%, #20282E -142%, #29353C -122.65%, rgba(255, 255, 255, 0.596267) -113.78%, #C9FBFE -93.45%, #79A2F2 -77.51%, rgba(255, 255, 255, 0.741036) -63.62%, #79A2F2 -47.78%, #5D23FD -19.47%, #FFFFFF 10.99%, #51555E 99.71%, #699CFF 127%, #FFFFFF 133.99%, #79A2F2 141.27%, #FFFFFF 147.64%);
    border-radius: 97px;
    width: ${(props) => (props.progress ? `${props.progress}%` : 0)};
  }
`;
