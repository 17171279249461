/* eslint-disable @typescript-eslint/no-explicit-any */
import { plainToInstance } from 'class-transformer';
import { get } from 'lodash';

import { Api } from '../../../lib/network';
import { BaseModel } from '../base';
import { SUBSCRIBE, USER } from './queries';

export class Subscribe extends BaseModel {
  walletAddress: string;
  kycVerified: string;
  nonce: string;

  static fromJson(json: any) {
    return plainToInstance(Subscribe, json);
  }
  static fromJsonArray(arrJson: any[]) {
    return arrJson ? arrJson.map((x) => Subscribe.fromJson(x)) : [];
  }

  static async fetchUserInfo() {
    const { data, ...rest } = await Api.request(USER, {});
    return { data: get(data, 'me', null), ...rest };
  }

  static async add(payload: any) {
    const { data, ...rest } = await Api.request(SUBSCRIBE, {
      ...payload,
    });

    return { ...rest, results: get(data, "subscribe", {}) };
  }
}
