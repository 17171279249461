import React from 'react';

import './styles.scss';

import clsxm from 'src/lib/clsxm';

interface Props {
  className?: string;
  value: number;
}

export const ProgressBar: React.FC<Props> = ({ className = '', value }) => {
  return (
    <div className={clsxm('progress-bar', className)}>
      <div
        className="filled"
        style={{ width: `${Math.round(value * 100.0)}%` }}
      ></div>
    </div>
  );
};
