import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { Input, LoadingSpinner, NewModal } from 'src/components';
import { PoolInfo } from 'src/core';
import { getPoolContract } from 'src/utils';

interface Props {
  poolInfo: any;
  onClose: () => void;
}

export const SetFeeAddress: React.FC<Props> = ({ onClose, poolInfo }) => {
  const [walletAddress, setWalletAddress] = React.useState('');
  const [loading, setLoading] = useState(false);
  const { walletConnected, userAddress } = useSelector(
    (store: any) => store.user
  );
  async function handleSubmit() {
    if (walletConnected) {
      setLoading(true);
      getPoolContract(poolInfo.poolAddress)
        .methods.setFeeAddress(walletAddress)
        .send({
          from: userAddress
        })
        .on('transactionHash', (hash: string) => {
          console.log('transaction hash unstake rpt ', hash);
        })
        .on('receipt', (receipt: any) => {
          toast('Set Fee Address successfully!', { type: toast.TYPE.SUCCESS });
          PoolInfo.upsertPool({
            ...poolInfo,
            feeAddress: walletAddress
          });
          setLoading(false);
          onClose();
        })
        .on('error', (error: any) => {
          setLoading(false);
          toast(
            'Transaction has failed, please contact admin to get support!',
            { type: toast.TYPE.WARNING }
          );
        });
    }
  }

  return (
    <NewModal
      title="Set Fee Address"
      actions={[
        { type: 'primary', label: 'Submit', onClick: () => handleSubmit() },
        { type: 'secondary', label: 'Cancel' }
      ]}
      onClose={() => onClose()}
    >
      {loading && <LoadingSpinner />}
      <Input
        className="modal-input"
        placeholder="Wallet address"
        value={walletAddress}
        onChange={(event) => setWalletAddress(event.target.value.trim())}
      />
    </NewModal>
  );
};
