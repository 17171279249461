import {
  SELL_SETTLER_ROYA_LOT_START,
  SELL_SETTLER_ROYA_LOT_SUCCESS,
  SELL_SETTLER_ROYA_LOT_FAILURE,
  SELL_MERCHANT_ROYA_LOT_START,
  SELL_MERCHANT_ROYA_LOT_SUCCESS,
  SELL_KNIGHT_ROYA_LOT_START,
  SELL_KNIGHT_ROYA_LOT_SUCCESS,
  SELL_KNIGHT_ROYA_LOT_FAILURE,
  SELL_ARCHON_ROYA_LOT_START,
  SELL_ARCHON_ROYA_LOT_SUCCESS,
  SELL_ARCHON_ROYA_LOT_FAILURE,
  SELL_MONARCH_ROYA_LOT_START,
  SELL_MONARCH_ROYA_LOT_SUCCESS,
  SELL_MONARCH_ROYA_LOT_FAILURE,
  BUY_MERCHANT_ROYA_LOT_START,
  BUY_MERCHANT_ROYA_LOT_SUCCESS,
  BUY_MERCHANT_ROYA_LOT_FAILURE,
  BUY_SETTLER_ROYA_LOT_START,
  BUY_SETTLER_ROYA_LOT_SUCCESS,
  BUY_SETTLER_ROYA_LOT_FAILURE,
  BUY_KNIGHT_ROYA_LOT_SUCCESS,
  BUY_KNIGHT_ROYA_LOT_START,
  BUY_KNIGHT_ROYA_LOT_FAILURE,
  BUY_ARCHON_ROYA_LOT_START,
  BUY_ARCHON_ROYA_LOT_SUCCESS,
  BUY_ARCHON_ROYA_LOT_FAILURE,
  BUY_MONARCH_ROYA_LOT_START,
  BUY_MONARCH_ROYA_LOT_SUCCESS,
  BUY_MONARCH_ROYA_LOT_FAILURE,
  GET_USER_TOTAL_LOTS_SUCCESS,
  CLOSE_SUCCESS_MSG,
  SET_NFT_ID,
  SELL_LOCKED_QUEEN_NFT_START,
  GET_LAST_BOUGHT_SETTLER_LOT_SUCCESS,
  GET_LAST_BOUGHT_MERCHANT_LOT_SUCCESS,
  GET_LAST_BOUGHT_KNIGHT_LOT_SUCCESS,
  GET_LAST_BOUGHT_ARCHON_LOT_SUCCESS,
  GET_LAST_BOUGHT_MONARCH_LOT_SUCCESS,
  getRoyaBalance,
} from "../actions";
import {
  toRoya,
  LotPrice,
  getRoyaNftContract,
  getRoyaTokenContract,
  getKnightLotContractAddress,
  getArchonLotContractAddress,
  getMonarchLotContractAddress,
  getSettlerLotContractAddress,
  getMerchantLotContractAddress,
  getMerchantLotContract,
  getSettlerLotContract,
  getSettlerNFTLotContractAddress,
  getSettlerNFTLotContract,
  getMerchantNFTLotContract,
  getMerchantNFTLotContractAddress,
  getArchonNFTLotContract,
  getKnightNFTLotContract,
  getMonarchNFTLotContract,
  getKnightNFTLotContractAddress,
  getArchonNFTLotContractAddress,
  getMonarchNFTLotContractAddress,
  getKnightLotContract,
  getMonarchLotContract,
  getArchonContract,
} from "../../utils";
import {
  GET_LAST_BOUGHT_ARCHON_NFT_LOT_SUCCESS,
  GET_LAST_BOUGHT_KNIGHT_NFT_LOT_SUCCESS,
  GET_LAST_BOUGHT_MERCHANT_NFT_LOT_SUCCESS,
  GET_LAST_BOUGHT_MONARCH_NFT_LOT_SUCCESS,
  GET_LAST_BOUGHT_SETTLER_NFT_LOT_SUCCESS,
  SELL_LOCKED_MERCHANT_NFT_START,
  SELL_MERCHANT_ROYA_LOT_FAILURE,
} from "./constant";

export const setNftId = (nftId: number) => ({
  type: SET_NFT_ID,
  nftId,
});

export const closeSuccessMsg = () => ({
  type: CLOSE_SUCCESS_MSG,
});

const buySettlerroyaLotStart = () => ({
  type: BUY_SETTLER_ROYA_LOT_START,
});

const buySettlerroyaLotSuccess = (lotAmount: number) => ({
  type: BUY_SETTLER_ROYA_LOT_SUCCESS,
  lotAmount,
});

const buySettlerroyaLotFailure = () => ({
  type: BUY_SETTLER_ROYA_LOT_FAILURE,
});

const buyMerchantroyaLotStart = () => ({
  type: BUY_MERCHANT_ROYA_LOT_START,
});

const buyMerchantroyaLotSuccess = (lotAmount: number) => ({
  type: BUY_MERCHANT_ROYA_LOT_SUCCESS,
  lotAmount,
});

const buyMerchantroyaLotFailure = () => ({
  type: BUY_MERCHANT_ROYA_LOT_FAILURE,
});

const buyKnightroyaLotStart = () => ({
  type: BUY_KNIGHT_ROYA_LOT_START,
});

const buyKnightroyaLotSuccess = (lotAmount: number) => ({
  type: BUY_KNIGHT_ROYA_LOT_SUCCESS,
  lotAmount,
});

const buyKnightroyaLotFailure = () => ({
  type: BUY_KNIGHT_ROYA_LOT_FAILURE,
});

export const buySettlerroyaLot =
  (
    userAddress: string,
    lot: number,
    nftDiscount: boolean,
    closeModal?: () => void
  ) =>
  (dispatch: any) => {
    dispatch(buySettlerroyaLotStart());
    if (!nftDiscount) {
      getRoyaNftContract()
        .methods.setApprovalForAll(getSettlerNFTLotContractAddress(), true)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash approve settlerlot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt approve settlerlot", receipt);
        })
        .on("error", (error: any) => {
          console.log("error approve settlerlot", error);
          dispatch(buySettlerroyaLotFailure());
        })
        .then(() => {
          getSettlerNFTLotContract()
            .methods.buy(lot)
            .send({
              from: userAddress,
            })
            .on("transactionHash", (hash: string) => {
              console.log("transactionHash approve settlerlot nft", hash);
            })
            .on("receipt", (receipt: string) => {
              console.log("receipt buy settlerlot nft", receipt);
              dispatch(buySettlerroyaLotSuccess(lot));
              dispatch(getUserTotalLots(userAddress));
              dispatch(getRoyaBalance(userAddress));

              if (closeModal) {
                closeModal();
              }
            })
            .on("error", (error: any) => {
              console.log("error approve settlerlot nft", error);
              dispatch(buySettlerroyaLotFailure());
            });
        });
    } else {
      getRoyaTokenContract()
        .methods.approve(
          getSettlerLotContractAddress(),
          toRoya((LotPrice.settlerLot * lot).toString())
        )
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash approve settlerlot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt approve settlerlot", receipt);
        })
        .on("error", (error: any) => {
          console.log("error approve settlerlot", error);
          dispatch(buySettlerroyaLotFailure());
        })
        .then(() => {
          getSettlerLotContract()
            .methods.buy(lot)
            .send({
              from: userAddress,
            })
            .on("transactionHash", (hash: string) => {
              console.log("transactionHash buy settlerlot", hash);
            })
            .on("receipt", (receipt: string) => {
              console.log("receipt buy settlerlot", receipt);
              dispatch(buySettlerroyaLotSuccess(lot));
              dispatch(getUserTotalLots(userAddress));
              dispatch(getRoyaBalance(userAddress));
              if (closeModal) {
                closeModal();
              }
            })
            .on("error", (error: any) => {
              console.log("error buy settlerlot", error);
              dispatch(buySettlerroyaLotFailure());
            });
        });
    }
  };

export const buyMerchantroyaLot =
  (
    userAddress: string,
    lot: number,
    nftDiscount: boolean,
    closeModal?: () => void
  ) =>
  (dispatch: any) => {
    dispatch(buyMerchantroyaLotStart());
    if (!nftDiscount) {
      getRoyaNftContract()
        .methods.setApprovalForAll(getMerchantNFTLotContractAddress(), true)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash approve merchant lot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt approve merchant lot", receipt);
        })
        .on("error", (error: any) => {
          console.log("error approve merchant lot", error);
          dispatch(buyMerchantroyaLotFailure());
        })
        .then(() => {
          getMerchantNFTLotContract()
            .methods.buy(lot)
            .send({
              from: userAddress,
            })
            .on("transactionHash", (hash: string) => {
              console.log("transactionHash approve merchant lot nft", hash);
            })
            .on("receipt", (receipt: string) => {
              console.log("receipt buy merchant lot nft", receipt);
              dispatch(buyMerchantroyaLotSuccess(lot));
              dispatch(getUserTotalLots(userAddress));
              dispatch(getRoyaBalance(userAddress));

              if (closeModal) {
                closeModal();
              }
            })
            .on("error", (error: any) => {
              console.log("error approve merchant lot nft", error);
              dispatch(buyMerchantroyaLotFailure());
            });
        });
    } else {
      getRoyaTokenContract()
        .methods.approve(
          getMerchantLotContractAddress(),
          toRoya((LotPrice.merchantLot * lot).toString())
        )
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash approve marchantlot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt approve marchantlot", receipt);
        })
        .on("error", (error: any) => {
          console.log("error approve marchantlot", error);
          dispatch(buyMerchantroyaLotFailure());
        })
        .then(() => {
          getMerchantLotContract()
            .methods.buy(lot)
            .send({
              from: userAddress,
            })
            .on("transactionHash", (hash: string) => {
              console.log("transactionHash buy  marchantlot", hash);
            })
            .on("receipt", (receipt: string) => {
              console.log("receipt buy marchantlot", receipt);
              dispatch(buyMerchantroyaLotSuccess(lot));
              dispatch(getUserTotalLots(userAddress));
              dispatch(getRoyaBalance(userAddress));
              if (closeModal) {
                closeModal();
              }
            })
            .on("error", (error: any) => {
              console.log("error buy marchantlot", error);
              dispatch(buyMerchantroyaLotFailure());
            });
        });
    }
  };
export const buyKnightroyaLot =
  (
    userAddress: string,
    lot: number,
    nftDiscount: boolean,
    closeModal?: () => void
  ) =>
  (dispatch: any) => {
    dispatch(buyKnightroyaLotStart());
    if (!nftDiscount) {
      getRoyaNftContract()
        .methods.setApprovalForAll(getKnightNFTLotContractAddress(), true)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash approve merchant lot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt approve merchant lot", receipt);
        })
        .on("error", (error: any) => {
          console.log("error approve merchant lot", error);
          dispatch(buyKnightroyaLotFailure());
        })
        .then(() => {
          getMerchantNFTLotContract()
            .methods.buy(lot)
            .send({
              from: userAddress,
            })
            .on("transactionHash", (hash: string) => {
              console.log("transactionHash approve merchant lot nft", hash);
            })
            .on("receipt", (receipt: string) => {
              console.log("receipt buy merchant lot nft", receipt);
              dispatch(buyKnightroyaLotSuccess(lot));
              dispatch(getUserTotalLots(userAddress));
              dispatch(getRoyaBalance(userAddress));

              if (closeModal) {
                closeModal();
              }
            })
            .on("error", (error: any) => {
              console.log("error approve merchant lot nft", error);
              dispatch(buyKnightroyaLotFailure());
            });
        });
    } else {
      getRoyaTokenContract()
        .methods.approve(
          getKnightLotContractAddress(),
          toRoya((LotPrice.queenLot * lot).toString())
        )
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash approve queenlot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt approve queenlot", receipt);
        })
        .on("error", (error: any) => {
          console.log("error approve queenlot", error);
          dispatch(buyKnightroyaLotFailure());
        })
        .then(() => {
          getKnightLotContract()
            .methods.buy(lot)
            .send({
              from: userAddress,
            })
            .on("transactionHash", (hash: string) => {
              console.log("transactionHash buy  queenlot", hash);
            })
            .on("receipt", (receipt: string) => {
              console.log("receipt buy queenlot", receipt);
              dispatch(buyKnightroyaLotSuccess(lot));
              dispatch(getUserTotalLots(userAddress));
              dispatch(getRoyaBalance(userAddress));
              if (closeModal) {
                closeModal();
              }
            })
            .on("error", (error: any) => {
              console.log("error buy queenlot", error);
              dispatch(buyKnightroyaLotFailure());
            });
        });
    }
  };

export const buyArchonroyaLot =
  (
    userAddress: string,
    lot: number,
    nftDiscount: boolean,
    closeModal?: () => void
  ) =>
  (dispatch: any) => {
    dispatch(buyArchonroyaLotStart());

    if (!nftDiscount) {
      getRoyaNftContract()
        .methods.setApprovalForAll(getArchonNFTLotContractAddress(), true)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash approve king lot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt approve king lot", receipt);
        })
        .on("error", (error: any) => {
          console.log("error approve king lot", error);
          dispatch(buyArchonroyaLotFailure());
        })
        .then(() => {
          getArchonNFTLotContract()
            .methods.buy(lot)
            .send({
              from: userAddress,
            })
            .on("transactionHash", (hash: string) => {
              console.log("transactionHash approve king lot nft", hash);
            })
            .on("receipt", (receipt: string) => {
              console.log("receipt buy king lot nft", receipt);
              dispatch(buyArchonroyaLotSuccess(lot));
              dispatch(getUserTotalLots(userAddress));
              dispatch(getRoyaBalance(userAddress));

              if (closeModal) {
                closeModal();
              }
            })
            .on("error", (error: any) => {
              console.log("error approve king lot nft", error);
              dispatch(buyArchonroyaLotFailure());
            });
        });
    } else {
      getRoyaTokenContract()
        .methods.approve(
          getArchonLotContractAddress(),
          toRoya((LotPrice.kingLot * lot).toString())
        )
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash approve kinglot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt approve kinglot", receipt);
        })
        .on("error", (error: any) => {
          console.log("error approve kinglot", error);
          dispatch(buyArchonroyaLotFailure());
        })
        .then(() => {
          getArchonContract()
            .methods.buy(lot)
            .send({
              from: userAddress,
            })
            .on("transactionHash", (hash: string) => {
              console.log("transactionHash buy kinglot", hash);
            })
            .on("receipt", (receipt: string) => {
              console.log("receipt buy kinglot", receipt);
              dispatch(buyArchonroyaLotSuccess(lot));
              dispatch(getUserTotalLots(userAddress));
              dispatch(getRoyaBalance(userAddress));
              if (closeModal) {
                closeModal();
              }
            })
            .on("error", (error: any) => {
              console.log("error buy kinglot", error);
              dispatch(buyArchonroyaLotFailure());
            });
        });
    }
  };

export const buyMonarchroyaLot =
  (
    userAddress: string,
    lot: number,
    nftDiscount: boolean,
    closeModal?: () => void
  ) =>
  (dispatch: any) => {
    dispatch(buyMonarchroyaLotStart());

    if (!nftDiscount) {
      getRoyaNftContract()
        .methods.setApprovalForAll(getMonarchNFTLotContractAddress(), true)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash approve flush lot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt approve flush lot", receipt);
        })
        .on("error", (error: any) => {
          console.log("error approve flush lot", error);
          dispatch(buyMonarchroyaLotFailure());
        })
        .then(() => {
          getMonarchNFTLotContract()
            .methods.buy(lot)
            .send({
              from: userAddress,
            })
            .on("transactionHash", (hash: string) => {
              console.log("transactionHash approve flush lot nft", hash);
            })
            .on("receipt", (receipt: string) => {
              console.log("receipt buy flush lot nft", receipt);
              dispatch(buyMonarchroyaLotSuccess(lot));
              dispatch(getUserTotalLots(userAddress));
              dispatch(getRoyaBalance(userAddress));

              if (closeModal) {
                closeModal();
              }
            })
            .on("error", (error: any) => {
              console.log("error approve flush lot nft", error);
              dispatch(buyMonarchroyaLotFailure());
            });
        });
    } else {
      console.log("without discount");

      getRoyaTokenContract()
        .methods.approve(
          getMonarchLotContractAddress(),
          toRoya((LotPrice.flushLot * lot).toString())
        )
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash approve flushlot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt approve flushlot", receipt);
        })
        .on("error", (error: any) => {
          console.log("error approve flushlot", error);
          dispatch(buyMonarchroyaLotFailure());
        })
        .then(() => {
          getMonarchLotContract()
            .methods.buy(lot)
            .send({
              from: userAddress,
            })
            .on("transactionHash", (hash: string) => {
              console.log("transactionHash buy flushlot", hash);
            })
            .on("receipt", (receipt: string) => {
              console.log("receipt buy flushlot", receipt);
              dispatch(buyMonarchroyaLotSuccess(lot));
              dispatch(getUserTotalLots(userAddress));
              dispatch(getRoyaBalance(userAddress));
              if (closeModal) {
                closeModal();
              }
            })
            .on("error", (error: any) => {
              console.log("error buy flushlot", error);
              dispatch(buyMonarchroyaLotFailure());
            });
        });
    }
  };

const buyArchonroyaLotStart = () => ({
  type: BUY_ARCHON_ROYA_LOT_START,
});

const buyArchonroyaLotSuccess = (lotAmount: number) => ({
  type: BUY_ARCHON_ROYA_LOT_SUCCESS,
  lotAmount,
});

const buyArchonroyaLotFailure = () => ({
  type: BUY_ARCHON_ROYA_LOT_FAILURE,
});

const buyMonarchroyaLotStart = () => ({
  type: BUY_MONARCH_ROYA_LOT_START,
});

const buyMonarchroyaLotSuccess = (lotAmount: number) => ({
  type: BUY_MONARCH_ROYA_LOT_SUCCESS,
  lotAmount,
});

const buyMonarchroyaLotFailure = () => ({
  type: BUY_MONARCH_ROYA_LOT_FAILURE,
});

const sellSettlerroyaLotStart = () => ({
  type: SELL_SETTLER_ROYA_LOT_START,
});

const sellSettlerroyaLotSuccess = (lotAmount: number) => ({
  type: SELL_SETTLER_ROYA_LOT_SUCCESS,
  lotAmount,
});

const sellSettlerroyaLotFailure = () => ({
  type: SELL_SETTLER_ROYA_LOT_FAILURE,
});

const sellMerchantroyaLotStart = () => ({
  type: SELL_MERCHANT_ROYA_LOT_START,
});

const sellMerchantroyaLotSuccess = (lotAmount: number) => ({
  type: SELL_MERCHANT_ROYA_LOT_SUCCESS,
  lotAmount,
});

const sellMerchantroyaLotFailure = () => ({
  type: SELL_MERCHANT_ROYA_LOT_FAILURE,
});

const sellKnightroyaLotStart = () => ({
  type: SELL_KNIGHT_ROYA_LOT_START,
});

const sellKnightroyaLotSuccess = (lotAmount: number) => ({
  type: SELL_KNIGHT_ROYA_LOT_SUCCESS,
  lotAmount,
});

const sellKnightroyaLotFailure = () => ({
  type: SELL_KNIGHT_ROYA_LOT_FAILURE,
});

export const sellSettlerroyaLot =
  (userAddress: string, lot: number, nftDiscount: boolean) =>
  (dispatch: any) => {
    dispatch(sellSettlerroyaLotStart());
    if (!nftDiscount) {
      console.log("sell with nftdiscount qroya");

      getSettlerNFTLotContract()
        .methods.unstake(lot)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash sell queenlot nftid", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt sell queenlot nftid", receipt);
          dispatch(sellSettlerroyaLotSuccess(lot));
          dispatch(getUserTotalLots(userAddress));
          dispatch(getRoyaBalance(userAddress));
        })
        .on("error", (error: any) => {
          console.log("error sell queenlot nftid", error);
          dispatch(sellSettlerroyaLotFailure());
        });
    } else {
      getSettlerLotContract()
        .methods.unstake(lot)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash sell queenlot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt sell queenlot", receipt);
          dispatch(sellSettlerroyaLotSuccess(lot));
          dispatch(getUserTotalLots(userAddress));
          dispatch(getRoyaBalance(userAddress));
        })
        .on("error", (error: any) => {
          console.log("error sell queenlot", error);
          dispatch(sellSettlerroyaLotFailure());
        });
    }
  };
export const sellMerchantroyaLot =
  (userAddress: string, lot: number, nftDiscount: boolean) =>
  (dispatch: any) => {
    dispatch(sellMerchantroyaLotStart());
    if (!nftDiscount) {
      console.log("sell with nftdiscount qroya");

      getMerchantNFTLotContract()
        .methods.unstake(lot)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash sell merchant lot nftid", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt sell merchant lot nftid", receipt);
          dispatch(sellMerchantroyaLotSuccess(lot));
          dispatch(getUserTotalLots(userAddress));
          dispatch(getRoyaBalance(userAddress));
        })
        .on("error", (error: any) => {
          console.log("error sell merchant lot nftid", error);
          dispatch(sellMerchantroyaLotFailure());
        });
    } else {
      dispatch(sellMerchantroyaLotStart());
      getMerchantLotContract()
        .methods.unstake(lot)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash sell merchant lot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt sell merchant lot", receipt);
          dispatch(sellMerchantroyaLotSuccess(lot));
          dispatch(getUserTotalLots(userAddress));
          dispatch(getRoyaBalance(userAddress));
        })
        .on("error", (error: any) => {
          console.log("error sell merchant lot", error);
          dispatch(sellMerchantroyaLotFailure());
        });
    }
  };

export const sellKnightroyaLot =
  (userAddress: string, lot: number, nftDiscount: boolean) =>
  (dispatch: any) => {
    dispatch(sellKnightroyaLotStart());
    if (!nftDiscount) {
      getKnightNFTLotContract()
        .methods.unstake(lot)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash sell knight lot nftid", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt sell knight lot nftid", receipt);
          dispatch(sellKnightroyaLotSuccess(lot));
          dispatch(getUserTotalLots(userAddress));
          dispatch(getRoyaBalance(userAddress));
        })
        .on("error", (error: any) => {
          console.log("error sell knight lot nftid", error);
          dispatch(sellKnightroyaLotFailure());
        });
    } else {
      getKnightLotContract()
        .methods.unstake(lot)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash sell knight lot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt sell knight lot", receipt);
          dispatch(sellKnightroyaLotSuccess(lot));
          dispatch(getUserTotalLots(userAddress));
          dispatch(getRoyaBalance(userAddress));
        })
        .on("error", (error: any) => {
          console.log("error sell knight lot", error);
          dispatch(sellKnightroyaLotFailure());
        });
    }
  };

const sellArchonroyaLotStart = () => ({
  type: SELL_ARCHON_ROYA_LOT_START,
});

const sellArchonroyaLotSuccess = (lotAmount: number) => ({
  type: SELL_ARCHON_ROYA_LOT_SUCCESS,
  lotAmount,
});

const sellArchonroyaLotFailure = () => ({
  type: SELL_ARCHON_ROYA_LOT_FAILURE,
});

export const sellArchonroyaLot =
  (userAddress: string, lot: number, nftDiscount: boolean) =>
  (dispatch: any) => {
    dispatch(sellArchonroyaLotStart());

    if (!nftDiscount) {
      getArchonNFTLotContract()
        .methods.unstake(lot)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash sell archon lot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt sell archon lot", receipt);
          dispatch(sellArchonroyaLotSuccess(lot));
          dispatch(getUserTotalLots(userAddress));
          dispatch(getRoyaBalance(userAddress));
        })
        .on("error", (error: any) => {
          console.log("error sell kinglot", error);
          dispatch(sellArchonroyaLotFailure());
        });
    } else {
      getArchonContract()
        .methods.unstake(lot)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash sell archon lot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt sell archon lot", receipt);
          dispatch(sellArchonroyaLotSuccess(lot));
          dispatch(getUserTotalLots(userAddress));
          dispatch(getRoyaBalance(userAddress));
        })
        .on("error", (error: any) => {
          console.log("error sell archon lot", error);
          dispatch(sellArchonroyaLotFailure());
        });
    }
  };

const sellMonarchroyaLotStart = () => ({
  type: SELL_MONARCH_ROYA_LOT_START,
});

const sellMonarchroyaLotSuccess = (lotAmount: number) => ({
  type: SELL_MONARCH_ROYA_LOT_SUCCESS,
  lotAmount,
});

const sellMonarchroyaLotFailure = () => ({
  type: SELL_MONARCH_ROYA_LOT_FAILURE,
});

export const sellMonarchroyaLot =
  (userAddress: string, lot: number, nftDiscount: boolean) =>
  (dispatch: any) => {
    dispatch(sellMonarchroyaLotStart());

    if (!!nftDiscount) {
      getMonarchNFTLotContract()
        .methods.unstake(lot)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash sell monarch lot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt sell monarch lot", receipt);
          dispatch(sellMonarchroyaLotSuccess(lot));
          dispatch(getUserTotalLots(userAddress));
          dispatch(getRoyaBalance(userAddress));
        })
        .on("error", (error: any) => {
          console.log("error sell monarch lot", error);
          dispatch(sellMonarchroyaLotFailure());
        });
    } else {
      getMonarchLotContract()
        .methods.unstake(lot)
        .send({
          from: userAddress,
        })
        .on("transactionHash", (hash: string) => {
          console.log("transactionHash sell monarch lot", hash);
        })
        .on("receipt", (receipt: string) => {
          console.log("receipt sell monarch lot", receipt);
          dispatch(sellMonarchroyaLotSuccess(lot));
          dispatch(getUserTotalLots(userAddress));
          dispatch(getRoyaBalance(userAddress));
        })
        .on("error", (error: any) => {
          console.log("error sell monarch lot", error);
          dispatch(sellMonarchroyaLotFailure());
        });
    }
  };

const getUserTotalLotsSuccess = (
  qLot: string,
  kLot: string,
  fLot: string,
  sLot: string,
  mLot: string,
  sNFTLot: string,
  mNFTLot: string,
  kNFTLot: string,
  aNFTLot: string,
  moNFTLot: string
) => ({
  type: GET_USER_TOTAL_LOTS_SUCCESS,
  qLot,
  kLot,
  fLot,
  sLot,
  mLot,
  sNFTLot,
  mNFTLot,
  kNFTLot,
  aNFTLot,
  moNFTLot,
});

export const getUserTotalLots =
  (userAddress: string) => async (dispatch: any) => {
    try {
      // Currently, we have only two test token that's why we only execute below two methods. 
      // We don't have all of these tokens for testing. Currently, we have only 2 test tokens which we have deployed because of that we have commented all other tokens.
      const requests = [
        // getKnightLotContract().methods.balanceOf(userAddress).call(),
        // getArchonContract().methods.balanceOf(userAddress).call(),
        // getMonarchLotContract().methods.balanceOf(userAddress).call(),
        getSettlerLotContract().methods.balanceOf(userAddress).call(),
        // getMerchantLotContract().methods.balanceOf(userAddress).call(),
        // getSettlerNFTLotContract().methods.balanceOf(userAddress).call(),
        // getMerchantNFTLotContract().methods.balanceOf(userAddress).call(),
        // getKnightNFTLotContract().methods.balanceOf(userAddress).call(),
        // getArchonNFTLotContract().methods.balanceOf(userAddress).call(),
        // getMonarchNFTLotContract().methods.balanceOf(userAddress).call(),
      ];
      const responses = await Promise.all(requests);
      const [
        settlerLot,
        queenLot,
        kingLot,
        flushLot,
        merchantLot,
        settlerNFTLot,
        merchantNFTLot,
        knightNFTLot,
        archonNFTLot,
        monarchNFTLot,
      ] = responses;
      dispatch(
        getUserTotalLotsSuccess(
          queenLot ? queenLot : 0,
          kingLot ? kingLot : 0,
          flushLot ? flushLot : 0,
          settlerLot ? settlerLot : 0,
          merchantLot ? merchantLot :0,
          settlerNFTLot ? settlerNFTLot : 0,
          merchantNFTLot ? merchantNFTLot : 0,
          knightNFTLot ? knightNFTLot :0,
          archonNFTLot ? archonNFTLot : 0,
          monarchNFTLot ? monarchNFTLot : 0
        )
      );
    } catch (e) {
      console.log("error in get user total lots", e);
    }
  };

const getlastboughtsettlerlot = (settlerlot: number) => ({
  type: GET_LAST_BOUGHT_SETTLER_LOT_SUCCESS,
  settlerlot,
});
const getlastboughtmerchantlot = (merchantlot: number) => ({
  type: GET_LAST_BOUGHT_MERCHANT_LOT_SUCCESS,
  merchantlot,
});
const getlastboughtknightlot = (knightlot: number) => ({
  type: GET_LAST_BOUGHT_KNIGHT_LOT_SUCCESS,
  knightlot,
});
const getlastboughtarchonlot = (archonlot: number) => ({
  type: GET_LAST_BOUGHT_ARCHON_LOT_SUCCESS,
  archonlot,
});
const getlastboughtmonarchlot = (monarchlot: number) => ({
  type: GET_LAST_BOUGHT_MONARCH_LOT_SUCCESS,
  monarchlot,
});
const getlastboughtsettlerNFTlot = (settlerlot: number) => ({
  type: GET_LAST_BOUGHT_SETTLER_NFT_LOT_SUCCESS,
  settlerlot,
});
const getlastboughtmerchantNFTlot = (merchantlot: number) => ({
  type: GET_LAST_BOUGHT_MERCHANT_NFT_LOT_SUCCESS,
  merchantlot,
});
const getlastboughtknightNFTlot = (knightlot: number) => ({
  type: GET_LAST_BOUGHT_KNIGHT_NFT_LOT_SUCCESS,
  knightlot,
});
const getlastboughtarchonNFTlot = (archonlot: number) => ({
  type: GET_LAST_BOUGHT_ARCHON_NFT_LOT_SUCCESS,
  archonlot,
});
const getlastboughtmonarchNFTlot = (monarchlot: number) => ({
  type: GET_LAST_BOUGHT_MONARCH_NFT_LOT_SUCCESS,
  monarchlot,
});

function getDifferenceInDays(date1: any, date2: any) {
  const diffInMs = Math.abs(date2 - date1);
  return diffInMs / (1000 * 60 * 60 * 24);
}

export const getStakeDateforAllTiers =
  (userAddress: string) => async (dispatch: any) => {
    const todaydate = new Date();

    const settlerlotlastBoughtdate = await getSettlerLotContract()
      .methods.lastBoughtTimestamp(userAddress)
      .call();
    // const merchantlotlastBoughtdate = await getMerchantLotContract()
    //   .methods.lastBoughtTimestamp(userAddress)
    //   .call();
    // const knightlotlastBoughtdate = await getKnightLotContract()
    //   .methods.lastBoughtTimestamp(userAddress)
    //   .call();
    // const archonlotlastBoughtdate = await getArchonContract()
    //   .methods.lastBoughtTimestamp(userAddress)
    //   .call();
    // const monarchlotlastBoughtdate = await getMonarchLotContract()
    //   .methods.lastBoughtTimestamp(userAddress)
    //   .call();
    // const settlerNFTlotlastBoughtdate = await getSettlerNFTLotContract()
    //   .methods.lastBoughtTimestamp(userAddress)
    //   .call();
    // const merchantNFTlotlastBoughtdate = await getMerchantNFTLotContract()
    //   .methods.lastBoughtTimestamp(userAddress)
    //   .call();
    // const knightNFTlotlastBoughtdate = await getKnightNFTLotContract()
    //   .methods.lastBoughtTimestamp(userAddress)
    //   .call();
    // const archonNFTlotlastBoughtdate = await getArchonNFTLotContract()
    //   .methods.lastBoughtTimestamp(userAddress)
    //   .call();
    // const monarchNFTlotlastBoughtdate = await getMonarchNFTLotContract()
    //   .methods.lastBoughtTimestamp(userAddress)
    //   .call();

    const settlerbuydate = new Date(settlerlotlastBoughtdate * 1000);
    // const merchantbuydate = new Date(merchantlotlastBoughtdate * 1000);
    // const knightbuydate = new Date(knightlotlastBoughtdate * 1000);
    // const archonbuydate = new Date(archonlotlastBoughtdate * 1000);
    // const monarchbuydate = new Date(monarchlotlastBoughtdate * 1000);
    // const settlerNFTBuyDate = new Date(settlerNFTlotlastBoughtdate * 1000);
    // const merchantNFTBuyDate = new Date(merchantNFTlotlastBoughtdate * 1000);
    // const knightNFTBuyDate = new Date(knightNFTlotlastBoughtdate * 1000);
    // const archonNFTBuyDate = new Date(archonNFTlotlastBoughtdate * 1000);
    // const monarchNFTBuyDate = new Date(monarchNFTlotlastBoughtdate * 1000);

    const getsettlerlotbuydifference = getDifferenceInDays(
      settlerbuydate,
      todaydate
    );
    // const getmerchantlotbuydifference = getDifferenceInDays(
    //   merchantbuydate,
    //   todaydate
    // );
    // const getknightlotbuydifference = getDifferenceInDays(
    //   knightbuydate,
    //   todaydate
    // );
    // const getarchonlotbuydifference = getDifferenceInDays(
    //   archonbuydate,
    //   todaydate
    // );
    // const getmonarchlotbuydifference = getDifferenceInDays(
    //   monarchbuydate,
    //   todaydate
    // );
    // const getsettlerNFTlotbuydifference = getDifferenceInDays(
    //   settlerNFTBuyDate,
    //   todaydate
    // );
    // const getmerchantNFTlotbuydifference = getDifferenceInDays(
    //   merchantNFTBuyDate,
    //   todaydate
    // );
    // const getknightNFTlotbuydifference = getDifferenceInDays(
    //   knightNFTBuyDate,
    //   todaydate
    // );
    // const getarchonNFTlotbuydifference = getDifferenceInDays(
    //   archonNFTBuyDate,
    //   todaydate
    // );
    // const getmonarchNFTlotbuydifference = getDifferenceInDays(
    //   monarchNFTBuyDate,
    //   todaydate
    // );

    dispatch(getlastboughtsettlerlot(getsettlerlotbuydifference));
    // dispatch(getlastboughtmerchantlot(getmerchantlotbuydifference));
    // dispatch(getlastboughtknightlot(getknightlotbuydifference));
    // dispatch(getlastboughtarchonlot(getarchonlotbuydifference));
    // dispatch(getlastboughtmonarchlot(getmonarchlotbuydifference));
    // dispatch(getlastboughtsettlerNFTlot(getsettlerNFTlotbuydifference));
    // dispatch(getlastboughtmerchantNFTlot(getmerchantNFTlotbuydifference));
    // dispatch(getlastboughtknightNFTlot(getknightNFTlotbuydifference));
    // dispatch(getlastboughtarchonNFTlot(getarchonNFTlotbuydifference));
    // dispatch(getlastboughtmonarchNFTlot(getmonarchNFTlotbuydifference));
  };
