import {
  MyProjectsIcon,
  ParticipatedIcon,
  ProfileIcon,
  FingerprintGra,
} from "../../../images";
import { Wrapper, Link, MobileWrapper, MobileLink } from "./style";

export const Sidebar = (): JSX.Element => (
  <Wrapper>
    <Link to={"/account"} exact>
      <img src={ProfileIcon.default} alt="Profile" />
      Profile
    </Link>
    <Link to={"/account/my-project"} exact>
      <img src={MyProjectsIcon.default} alt="My projects" />
      My Game List
    </Link>
    <Link to={"/account/participating-game"}>
      <img src={ParticipatedIcon.default} alt="Participated" />
      Participating Games
    </Link>

    <Link to={"/account/kyc"}>
      <img src={FingerprintGra.default} alt="kyc" />
      KYC
    </Link>
  </Wrapper>
);

export const MobileSidebar = (): JSX.Element => (
  <MobileWrapper>
    <MobileLink to={"/account"} exact>
      <img src={ProfileIcon.default} alt="Profile" />
      Profile
    </MobileLink>
    <MobileLink to={"/account/my-project"} exact>
      <img src={MyProjectsIcon.default} alt="My projects" />
      My Project
    </MobileLink>
    <MobileLink to={"/account/participating-game"}>
      <img src={ParticipatedIcon.default} alt="Participated" />
      Participating Projects
    </MobileLink>

    <MobileLink to={"/account/kyc"}>
      <img src={FingerprintGra.default} alt="Participated" />
      KYC
    </MobileLink>
  </MobileWrapper>
);
