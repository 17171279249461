import { ErrorMessage } from 'formik';
import { PoolTypes } from '../../../../utils';
import { InputField, ErrorContainer } from '../../style';
import React from 'react';

interface Props {
  values: any;
  handlePoolTypeSelect: (type: string) => void;
  handlePoolNameChange: (type: string) => void;
}

const SetPool: React.FC<Props> = ({ handlePoolTypeSelect, handlePoolNameChange, values }) => {
  return (
    <>
      <div className="pool-step-01">
        <div className="pool-name-box">
          <div className="step-form-control">
            <label>Pool Name</label>
            <InputField
              name="poolName"
              placeholder="Pool Name"
              className="submit-form-control"
              onChange={(e) => handlePoolNameChange(e.target.value)}
            />
            <ErrorContainer>
              <ErrorMessage name="poolName" />
            </ErrorContainer>
          </div>
          <div className="step-form-control">
            <label>Pool Type</label>
            <div className="step-grid-pool">
              <button
                className="poll-select-box"
                type="button"
                onClick={() => handlePoolTypeSelect(PoolTypes.standard)}
              >
                <div className="poll-select-name">
                  {values.poolType === PoolTypes.standard && (
                    <div className="poll-select-img1"></div>
                  )}

                  {values.poolType !== PoolTypes.standard && (
                    <div className="poll-select-img"></div>
                  )}
                  <div className="poll-name-display">
                    Standard Pool
                    <div className="poll-name-recommended">
                      <span>Recommended</span>
                    </div>
                  </div>
                </div>
                <div className="poll-select-info">
                  Tokens are distributed at the end of the pool.
                  <span>Minimum raise limit required.</span>
                </div>
              </button>
              <button
                className="poll-select-box"
                type="button"
                onClick={() => handlePoolTypeSelect(PoolTypes.instant)}
              >
                <div className="poll-select-name">
                  {values.poolType === PoolTypes.instant && (
                    <div className="poll-select-img1"></div>
                  )}
                  {values.poolType !== PoolTypes.instant && (
                    <div className="poll-select-img"></div>
                  )}
                  <span className="poll-name-display">Instant Pool</span>
                </div>
                <span className="poll-select-info">
                  Tokens are distributed immediately.
                  <span>No minimum raise limit required.</span>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetPool;
