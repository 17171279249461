/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import "./styles.scss";

import { Modal, GameDetailCard } from "src/components";

import { useDispatch, useSelector } from "react-redux";
import {
  allTierIcon,
  PolygonIcon,
  Image01Icon,
  Image02Icon,
  Image03Icon,
  Image04Icon,
  Image05Icon,
  Image06Icon,
  Image07Icon,
  Image08Icon,
  Image09Icon,
  Image10Icon,
  Image11Icon,
  Image12Icon,
  Image13Icon,
  Image14Icon,
  Image15Icon,
  Image16Icon,
  EthIcon,
  BinanceIcon,
  BannerIcon,
  ImgIcon,
  Tier1,
  Tier2,
  Tier3,
  Tier4,
  Tier5,
  Frame,
  ImgCoverEllipse,
  RoadMapNew,
  HomeBannerSlide,
  SlideArrow,
  SubscribeTitleImg,
  StartNow,
} from "../../images";

import LoginModalContainer from "../../components/login-modal";
import { Game } from "../../core";
import { BlogItem } from "../../logic/actions";
import { Paths } from "src/utils";
import { toast } from "react-toastify";
import { Subscribe } from "src/core/models/subscribe";

const LandingZone = () => {
  const [showWallet, setshowWallet] = useState(false);
  const [latestGames, setLatestGames] = useState<any>([]);
  const [slide, setSlide] = useState<number>(1);
  const [emailValue, setEmailValue] = useState<any>("");
  const { userAddress, walletConnected } = useSelector(
    (state: any) => state.user
  );
  const { blogs } = useSelector((state: any) => state.blogs);

  const dispatch = useDispatch();

  useEffect(() => {}, [walletConnected, dispatch, userAddress]);

  const closeModal = () => {
    setshowWallet(false);
  };

  const getLatestGames = async () => {
    const result = await Game.findLatestGames();
    if (!result.error) {
      setLatestGames(result?.results);
    }
  };

  useEffect(() => {
    getLatestGames();
  }, [walletConnected, userAddress]);

  const tierItems = [
    {
      title: "Choose Your Tier",
      img: Tier1.default,
    },
    {
      title: "Buy A Ticket",
      img: Tier2.default,
    },
    {
      title: "Find a project",
      img: Tier3.default,
    },
    {
      title: "Invest",
      img: Tier4.default,
    },
    {
      title: "Claim Allocations",
      img: Tier5.default,
    },
  ];
  const handleSlide = () => {
    slide === 1 ? setSlide(2) : setSlide(1);
  };
  const handleSubscribe = async () => {
    if (!emailValue) {
      toast.error("Please enter the email");
      return;
    }
    const payload = {
      email: emailValue,
    };
    const result = await Subscribe.add(payload);
    if (!result.error) {
      toast("Subscribed successfully", { type: toast.TYPE.SUCCESS });
      setEmailValue("");
    } else {
      toast(result.error, { type: toast.TYPE.WARNING });
    }
  };
  return (
    <>
      <Modal show={showWallet} closeModal={closeModal}>
        <LoginModalContainer show={showWallet} closeModal={closeModal} />
      </Modal>
      <div className="landing-zone">
        <div className="home-wrapperbg">
          <div className="container">
            {slide === 1 && (
              <div className="home-banner-content">
                <h3>The Project is</h3>
                <img src={BannerIcon.default} alt="" />
              </div>
            )}
            {slide === 2 && (
              <div className="home-banner-content home-banner-content2">
                <div className="home-banner-content-left">
                  <h2>QUI EST DELECTUS EA IN</h2>
                  <p>
                    <b>Royale Finance</b> is an industry focused decentralized
                    lending protocol built with security, fairness, and
                    transparency, the core tenets of decentralization.
                  </p>
                  <button className="btn-action-link">BUY A TICKET</button>
                </div>
                <img src={HomeBannerSlide.default} alt="" />
              </div>
            )}

            <div className="home-banner-sliding">
              <div className="home-banner-sliding-wrapper">
                <img
                  src={SlideArrow.default}
                  alt=""
                  onClick={() => handleSlide()}
                />
                {[1, 2]?.map((item) => {
                  return (
                    <div
                      key={item}
                      className={`slide-point ${
                        slide === item ? "active" : ""
                      }`}
                    ></div>
                  );
                })}
                <img
                  src={SlideArrow.default}
                  style={{ transform: "rotate(180deg)" }}
                  alt=""
                  onClick={() => handleSlide()}
                />
              </div>
            </div>

            <div className="latest-games">
              <h4>Latest Projects</h4>
              <div className="latest-games-wrapper">
                {latestGames.length === 0 && (
                  <p>There is no active projects.</p>
                )}
                {latestGames.length > 0 &&
                  latestGames.map((item: any, index: number) => (
                    <GameDetailCard
                      key={index}
                      className="latest-row"
                      value={item}
                    />
                  ))}
              </div>
              <div className="social-bottom-info">
                <Link to={Paths.allProjects} className="btn-action-link">
                  VIEW ALL PROJECTS
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="social-media-wrapper">
          <div className="container">
            <div className="social-bottom-info">
              <div className="row">
                <div className="col-md-6">
                  <p>
                    <strong>Royale Finance</strong> is an industry focused
                    decentralized lending protocol built with security,
                    fairness, and transparency, the core tenets of
                    decentralization.
                  </p>
                </div>
                <div className="col-md-6">
                  <p>
                    We utilize cross-chain DeFi optimised liquidity to bankroll{" "}
                    <span>GameFi</span>, <span>PlayToEarn</span> &{" "}
                    <span>iGaming</span> Startups. Royale offers StableCoin,
                    Staking & NFT Rewards.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="tier-levels">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tier-levelsimg">
                  {/* <img src={allTierIcon.default} alt="" className="" /> */}
                </div>
              </div>
              <div className="col-md-12">
                <div className="tier-levels-info">
                  <h3>Start Investing Now</h3>
                  {/* <div className="tier-levels-info-list">
                    {tierItems?.map((item, key) => {
                      return (
                        <>
                          <div key={item?.title} className="tier-levels-info-list-item">
                            <p>{item.title}</p>
                            <div
                              className="tier-levels-info-list-item-imgCont"
                              style={{
                                backgroundImage: `url(${ImgCoverEllipse.default})`,
                                objectFit: "cover",
                              }}
                            >
                              <img src={item.img} alt="" />
                            </div>
                          </div>
                          {key < tierItems?.length - 1 && (
                            <img
                              src={Frame.default}
                              alt=""
                              className="item-img"
                            />
                          )}
                        </>
                      );
                    })}
                  </div>
                  <img src={Blog_img.default} alt="" /> */}

                  {/* new design */}
                  <div className="tier-levels-info-section">
                    <div className="tier-levels-info-list">
                      {tierItems?.map((item, key) => {
                        return (
                          <Fragment key={item?.title}>
                            <div className="tier-levels-info-list-item">
                              <p>{item.title}</p>
                              <div
                                className="tier-levels-info-list-item-imgCont"
                                style={{
                                  backgroundImage: `url(${ImgCoverEllipse.default})`,
                                  objectFit: "cover",
                                }}
                              >
                                <img src={item.img} alt="" />
                              </div>
                            </div>
                            {key < tierItems?.length - 1 && (
                              <img
                                src={Frame.default}
                                alt=""
                                className="item-img"
                              />
                            )}
                          </Fragment>
                        );
                      })}
                    </div>
                    <div className="tier-levels-info-rightSection">
                      {/* <img src={Blog_img.default} alt="" /> */}
                      <img src={allTierIcon.default} alt="" className="" />
                      <div className="latest-games-wrapper">
                        {/* {latestGames.length === 0 && (
                          <p>There is no active projects.</p>
                        )} */}
                        {latestGames.length > 0 &&
                          latestGames.map((item: any, index: number) => (
                            <GameDetailCard
                              key={index}
                              className="latest-row"
                              value={item}
                            />
                          ))}
                      </div>
                      <div className="tier-levels-info-claim-section">
                        <button className="btn-action-link">Claim</button>
                      </div>
                    </div>
                  </div>
                  <img
                    src={StartNow.default}
                    alt=""
                    className="tier-start-img"
                  />
                  <button>
                    <Link to={Paths.stakingLots} className="btn-action-link">
                      BUY A TICKET
                    </Link>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="roadmapwrap">
          <div className="heading-rowforpartners">
            <div className="headingbox">
              <h2>Royale Roadmap Future on BNBchain</h2>
            </div>
          </div>
          {/* <div className="roadmapline">
            <img src={roadMapIcon.default} alt="" />
          </div> */}
          {/* <div className="roadmapcontainer">
            <div className="roadmap-row">
              <div className="roadmap-col">
                <div className="roadmapbox">
                  <div className="roadmap-heading">2021 Q1</div>
                  <div className="roadmap-list-item">
                    <ul>
                      <li>Royale Web App 3.0 Launch</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="roadmap-col">
                <div className="roadmapbox">
                  <div className="roadmap-heading">2021 Q2</div>
                  <div className="roadmap-list-item">
                    <ul>
                      <li>
                        Polygon App <br></br>
                        Launch
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="roadmap-col">
                <div className="roadmapbox">
                  <div className="roadmap-heading">2021 Q3</div>
                  <div className="roadmap-list-item">
                    <ul>
                      <li>BSC App Launch</li>
                      <li>Web App Upgrade</li>
                      <li>First Gaming Company Liquidity Provision</li>
                      <li>First Distribution of Gaming</li>
                      <li>mROYA to ROYA setup</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="roadmap-col">
                <div className="roadmapbox">
                  <div className="roadmap-heading">2021 Q4</div>
                  <div className="roadmap-list-item">
                    <ul>
                      <li>Royale Farms</li>
                      <li>Royale App Enhancements</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="roadmap-col">
                <div className="roadmapbox">
                  <div className="roadmap-heading">2021 Q2</div>
                  <div className="roadmap-list-item">
                    <ul>
                      <li>RoyaleGameFi Launchpad</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="roadmap-col">
                <div className="roadmapbox">
                  <div className="roadmap-heading">2022 Q2</div>
                  <div className="roadmap-list-item">
                    <ul>
                      <li>
                        Royale Games Aggregator <br></br>
                        a. Game Mechanics <br></br>
                        b. Game Design
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
          <img src={RoadMapNew.default} alt="" />
        </div>
        <div className="partnerswrap">
          <div className="container">
            <div className="heading-row">
              <div className="headingbox">
                <h2>Partners</h2>
              </div>
            </div>
            <div className="partners-row">
              <div className="partners-col">
                <div className="partners-box">
                  <img src={PolygonIcon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image01Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image02Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image03Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image04Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image05Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image06Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image07Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image08Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image09Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image10Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image11Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image12Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image13Icon.default} alt="" />
                </div>
              </div>

              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image14Icon.default} alt="" />
                </div>
              </div>
              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image15Icon.default} alt="" />
                </div>
              </div>

              <div className="partners-col">
                <div className="partners-box">
                  <img src={Image16Icon.default} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="compabilitywrap">
          <div className="heading-row">
            <div className="headingbox">
              <h2>Cross-Chain Compatibility:</h2>
            </div>
          </div>
          <div className="compability-row">
            <div className="compability-col">
              <div className="compability-box">
                <div className="compability-iconbox">
                  <img src={EthIcon.default} alt="" />
                </div>

                <h4>Ethereum</h4>
              </div>
            </div>
            <div className="compability-col">
              <div className="compability-box">
                <div className="compability-iconbox">
                  <img src={PolygonIcon.default} alt="" />
                </div>
                <h4>Polygon Network</h4>
              </div>
            </div>
            <div className="compability-col">
              <div className="compability-box">
                <div className="compability-iconbox">
                  <img src={BinanceIcon.default} alt="" />
                </div>
                <h4>Binance Smart Chain BSC</h4>
              </div>
            </div>
          </div>
        </div>
        <div className=" page-container">
          <div className="dashboard-roya-wrap">
            <div className="heading-row">
              <div className="headingbox">
                <h2>
                  <a
                    href="https://medium.com/officialroyale"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Learn About ROYA
                  </a>
                </h2>
              </div>
            </div>

            <div className="dashboard-roya-row">
              {blogs.slice(0, 4).map(
                (blog: BlogItem, index: number) =>
                  blog.title !== "To Our Loyal Royale Community" && (
                    <div
                      key={index}
                      className="roya-article-box"
                      onClick={() => window.open(blog.link, "_blank")}
                    >
                      <div className="roya-article-img">
                        <img
                          src={
                            blog.thumbnail ? blog.thumbnail : ImgIcon.default
                          }
                          alt="medium blog"
                        />
                      </div>
                      <div className="roya-article-info">
                        <div className="title">{blog.title}</div>
                        <div
                          className="description"
                          dangerouslySetInnerHTML={{ __html: blog.content }}
                        ></div>
                      </div>
                    </div>
                  )
              )}
            </div>
          </div>
        </div>

        <div className="subscriptionwrap">
          <div className="subscriptionContainer">
            {/* <div className="subscriptionTitle">SUBSCRIBE NOW</div> */}
            <img src={SubscribeTitleImg.default} alt="" />
            <div className="subscriptionSubTitle">BECOME ROYALE</div>
            <div className="subscriptionBox">
              <label>
                The email newsletter guaranteed to bring you the latest news and
                updates
              </label>
              <input
                type="text"
                placeholder="Email"
                value={emailValue}
                onChange={(e) => setEmailValue(e.target.value)}
              />
              <button
                onClick={() => handleSubscribe()}
                className="btn-action-link"
              >
                SUBSCRIBE
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LandingZone;
