import {
  Header,
  LotsFormContainer,
  StakingLotsField,
  ErrorText,
  BtnContainerSecondary,
  BuyLotButton,
  CancelLotButton,
  MaxButton,
  LotName,
  RightArrowContainer,
  RoyaAmount,
  LotAmountText,
  LoadingText
} from '../style';
import { Form, Formik, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { RightArrow } from '../../images';
import { useSelector, useDispatch } from 'react-redux';
import { sellSettlerroyaLot } from '../../logic/actions';
import { Loader } from '..';
import {
  getConfig,
  StakingLotModals,
  LotPrice,
  BorderForBtn
} from '../../utils';
import { useState } from 'react';

const initialValues = {
  lotAmount: '1',
  nftLotAmount: 1,
  nftDiscount: ''
};

interface Props {
  closeModal: () => void;
  showLockModal: (show: boolean) => void;
  lotBought: number;
  nftLotBought: number;
}

const SellSettlerRoya = (props: Props) => {
  const { walletConnected, userAddress } = useSelector(
    (state: any) => state.user
  );

  const [royaSelected, setRoyaSelected] = useState(true);
  const { lotOperation, totalSroyaLot, totalSNFTLot } = useSelector(
    (state: any) => state.stakingLots
  );

  const dispatch = useDispatch();

  const { closeModal, showLockModal, lotBought, nftLotBought } = props;

  const handleSellSettlerRoya = (values: any) => {
    const { lotAmount, nftLotAmount } = values;
    if (royaSelected && lotBought < 7) {
      showLockModal(true);
      return;
    }
    if (!royaSelected && nftLotBought < 7) {
      showLockModal(true);
      return;
    }
    if (walletConnected) {
      if (royaSelected) {
        dispatch(
          sellSettlerroyaLot(userAddress, parseInt(lotAmount), royaSelected)
        );
      } else {
        dispatch(
          sellSettlerroyaLot(userAddress, parseInt(nftLotAmount), royaSelected)
        );
      }
    }
  };

  const renderRoyaText = (value: string): string => {
    if (!isNaN(parseInt(value))) {
      const lotAmount = parseInt(value);
      if (lotAmount >= 1) {
        return `${(lotAmount * LotPrice.settlerLot).toLocaleString()} ROYA`;
      }

      return '0 ROYA';
    } else {
      return '0 ROYA';
    }
  };

  const schema = Yup.object().shape({
    lotAmount: Yup.string()
      .required('Enter value')
      .test(
        'lowAmount',
        `Should be greater than or equal to 1`, //@ts-ignore
        (val) => parseFloat(val) >= 1
      )
      .test('InsufficientFunds', `Insufficient Lot`, (val) =>
        !royaSelected ? true : BigInt(totalSroyaLot) >= BigInt(val as string)
      ),
    nftLotAmount: Yup.string()
      .required('Enter value')
      .test(
        'lowAmount',
        `Should be greater than or equal to 1`, //@ts-ignore
        (val) => parseFloat(val) >= 1
      )
      .test(
        'InsufficientFunds',
        `Insufficient Balance`, //@ts-ignore
        (val) => (royaSelected ? true : totalSNFTLot >= parseInt(val as string))
      ),
    nftDiscount: Yup.string()
  });

  const rendertotalSroyaLots = () => {
    if (parseInt(totalSroyaLot) >= 0) {
      return parseInt(totalSroyaLot);
    }

    return 0;
  };

  return (
    <Formik
      validationSchema={schema}
      initialValues={initialValues}
      onSubmit={handleSellSettlerRoya}
    >
      {({ setFieldValue, values }) => (
        <Form>
          <Header>UNSTAKE Settler ROYA</Header>
          <LotsFormContainer>
            <label className="checkbox-label staking-lot-modal-check">
              <input
                type="checkbox"
                id="agree"
                alt=""
                checked={royaSelected}
                onClick={() => setRoyaSelected(true)}
              />
              <span className="mark2"></span>
            </label>
            <StakingLotsField
              name="lotAmount"
              value={values.lotAmount}
              onValueChange={(vals: any) =>
                setFieldValue('lotAmount', vals.value)
              }
              decimalScale={'0'}
            />

            <LotName>Settler ROYA</LotName>
            <RightArrowContainer>
              <img src={RightArrow.default} alt="arrow" />
            </RightArrowContainer>

            <RoyaAmount className="notranslate">
              {renderRoyaText(values.lotAmount)}
            </RoyaAmount>

            <MaxButton
              type="button"
              onClick={() =>
                setFieldValue('lotAmount', rendertotalSroyaLots().toString())
              }
            >
              MAX
            </MaxButton>
          </LotsFormContainer>
          <ErrorText>
            <ErrorMessage name="lotAmount" />
          </ErrorText>
          <LotAmountText>
            Your Balance:{' '}
            <span className="notranslate">
              {`${rendertotalSroyaLots()} Settler ROYA`}
            </span>
          </LotAmountText>

          <LotsFormContainer>
            <label className="checkbox-label staking-lot-modal-check">
              <input
                type="checkbox"
                id="agree"
                alt=""
                checked={!royaSelected}
                onClick={() => setRoyaSelected(false)}
              />
              <span className="mark2"></span>
            </label>
            <StakingLotsField
              name="nftLotAmount"
              value={values.nftLotAmount}
              onValueChange={(vals: any) =>
                setFieldValue('nftLotAmount', vals.value)
              }
              decimalScale={'0'}
            />

            <LotName>Settler NFT</LotName>
            <RightArrowContainer>
              <img src={RightArrow.default} alt="arrow" />
            </RightArrowContainer>

            <RoyaAmount className="notranslate">
              {values.nftLotAmount}
            </RoyaAmount>

            <MaxButton
              type="button"
              onClick={() =>
                setFieldValue('nftLotAmount', totalSNFTLot.toString())
              }
            >
              MAX
            </MaxButton>
          </LotsFormContainer>
          <ErrorText>
            <ErrorMessage name="nftLotAmount" />
          </ErrorText>
          <LotAmountText>
            Your Balance:{' '}
            <span className="notranslate">{`${totalSNFTLot} Settler NFT`}</span>
          </LotAmountText>

          <BtnContainerSecondary>
            <BuyLotButton
              type="submit"
              disabled={lotOperation > StakingLotModals.closed}
            >
              {lotOperation === StakingLotModals.sellSettler ? (
                <Loader />
              ) : (
                'Unstake'
              )}
            </BuyLotButton>
            <BorderForBtn>
              <CancelLotButton type="button" onClick={closeModal}>
                Cancel
              </CancelLotButton>
            </BorderForBtn>
          </BtnContainerSecondary>
          {lotOperation === StakingLotModals.sellSettler && (
            <LoadingText>{getConfig().transactionText}</LoadingText>
          )}
        </Form>
      )}
    </Formik>
  );
};

export default SellSettlerRoya;
