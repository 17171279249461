import styled from "styled-components";
import { Theme } from "../../../../utils";

export const AboutWrapper = styled.div`
  padding-top: 30px;
`;

export const AboutContainer = styled.div`
  padding: 30px;
  border-radius: 8px;
  background-color: ${Theme.headerBackground};
`;

export const AboutHeader = styled.div`
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.1px;
  text-transform: uppercase;
  color: #ffffff;
  font-family: "Open Sans", sans-serif;
`;

export const AboutText = styled.div`
  padding: 24px 0;
  font-size: 16px;
  line-height: 24px;
  font-family: "Open Sans", sans-serif;
  letter-spacing: 0.1px;
  color: #ffffff;
`;
