import React, { useState, useRef } from "react";
import axios from "axios";
import { toast } from "react-toastify";

import {  endpoint_api } from '../../lib/network/apiClient';
import clsxm from "src/lib/clsxm";
import { LogoRemoveIcon } from "../../images";
import { Storage } from "src/utils";

import "./styles.scss";

interface Props {
  className?: string;
  fileType?: string;
  fileUrl?: string;
  onUpload?: (fileUrl: string) => void;
  children: React.ReactNode;
}

const totalProgress = {};

export const UploadForm: React.FC<Props> = ({
  className = "",
  fileType = "image/png, image/gif, image/jpeg",
  fileUrl,
  onUpload = () => true,
  children,
}) => {
  const inputRef = useRef(null);

  const [dragActive, setDragActive] = useState(false);
  const [isUploading, setUploading] = useState(false);
  const [progress, setProgress] = useState(0);

  // handle drag events
  function handleDrag(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === "dragenter" || event.type === "dragover") {
      setDragActive(true);
    } else if (event.type === "dragleave") {
      setDragActive(false);
    }
  }

  // triggers when file is dropped
  function handleDrop(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
    event.stopPropagation();
    setDragActive(false);
    if (event.dataTransfer.files && event.dataTransfer.files[0]) {
      handleFile(event.dataTransfer.files);
    }
  }

  // triggers when file is selected with click
  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    event.preventDefault();
    if (event.target.files && event.target.files[0]) {
      handleFile(event.target.files);
    }
  }

  const getTotalProgress = (prog: any, totalCount: any) => {
    let sum = 0;
    Object.keys(prog).forEach((one) => {
      sum += prog[one];
    });
    setProgress(Math.round(sum / totalCount));
  };

  const handleFile = async (files: any) => {
    const fileName = files[0].name;
    const fileType = files[0].type;
    const fileSize = files[0].size;

    const requestHeaders = Storage.accessToken
      ? {
          authorization: `Bearer ${Storage.accessToken}`,
        }
      : null;

    try {
      const response = await axios.get(
        `${endpoint_api}/start-upload`,
        {
          params: {
            fileName,
            fileType,
          },
          headers: requestHeaders,
        }
      );

      const { uploadId } = response.data;

      if (uploadId) {
        setUploading(true);
      }

      let FILE_CHUNK_SIZE;
      // if file size is large than 2GB
      if (fileSize >= 2000000000) {
        FILE_CHUNK_SIZE = 20000000; // 20MB
      } else {
        // if file size is less than 2GB
        FILE_CHUNK_SIZE = 10000000; // 10MB
      }

      const NUM_CHUNKS = Math.floor(fileSize / FILE_CHUNK_SIZE) + 1;
      const promisesArray = [];
      let start;
      let end;
      let blob;

      /* eslint-disable no-await-in-loop */
      for (let index = 1; index < NUM_CHUNKS + 1; index += 1) {
        start = (index - 1) * FILE_CHUNK_SIZE;
        end = index * FILE_CHUNK_SIZE;
        blob =
          index < NUM_CHUNKS
            ? files[0].slice(start, end)
            : files[0].slice(start);

        // (1) Generate presigned URL for each part
        const getUploadUrlRes = await axios.get(
          `${endpoint_api}/presigned-url`,
          {
            params: {
              fileName,
              partNumber: index,
              uploadId,
            },
            headers: requestHeaders,
          }
        );

        const { presignedUrl } = getUploadUrlRes.data;

        // Puts each file part into the storage server
        const indexSub = index;
        const uploadRes = axios.put(presignedUrl, blob, {
          headers: { "Content-Type": fileType },
          onUploadProgress: (data) => {
            const percent = Math.round((data.loaded * 100) / data.total);
            // @ts-ignore
            totalProgress[indexSub] = percent;
            getTotalProgress(totalProgress, NUM_CHUNKS);
          },
        });

        promisesArray.push(uploadRes);
      }
      /* eslint-enable no-await-in-loop */

      const resolvedArray = await Promise.all(promisesArray);

      // @ts-ignore
      const uploadPartsArray = [];
      resolvedArray.forEach((resolvedPromise, index) => {
        uploadPartsArray.push({
          ETag: resolvedPromise.headers.etag,
          PartNumber: index + 1,
        });
      });

      // Calls the CompleteMultipartUpload endpoint in the backend server

      const completeUploadRes = await axios.post(
        `${endpoint_api}/complete-upload`,
        {
          params: {
            fileName,
            // @ts-ignore
            parts: uploadPartsArray,
            uploadId,
          },
        },
        {
          headers: requestHeaders,
        }
      );

      if (completeUploadRes.data) {
        onUpload(decodeURIComponent(completeUploadRes.data.data.Location));
        setUploading(false);
        toast("Uploaded file successfully.", {
          type: toast.TYPE.SUCCESS,
        });
      }
    } catch (err) {
      console.log(err);
      setUploading(false);
      alert("Please try again ");
    }
  };

  return (
    <div
      id="form-file-upload"
      className={clsxm(
        "upload-form",
        dragActive ? "drag-active" : "",
        fileUrl ? "has-file" : "no-file",
        className
      )}
      onDragEnter={(event) => handleDrag(event)}
    >
      <label className="upload-form-wrapper">
        <input
          ref={inputRef}
          // id="input-file-upload"
          type="file"
          accept={fileType}
          onChange={(event: any) => handleChange(event)}
        />
        {fileUrl ? (
          fileType === "application/pdf" ? (
            <a
              style={{ color: "white", textDecoration: "underline !important" }}
              href={fileUrl}
              target="_blank"
              rel="noreferrer"
            >
              {fileUrl.split("/")[4]}
            </a>
          ) : (
            <img className="result-thumbnail" src={fileUrl} alt="" />
          )
        ) : (
          !isUploading && <>{children}</>
        )}
      </label>
      {isUploading && (
        <div className="progress-bar-container">
          <div className="progress-bar-value">{progress} %</div>
          <div className="progress-bar">
            <div style={{ width: `${progress}%` }} />
          </div>
        </div>
      )}
      {dragActive && (
        <div
          id="drag-file-element"
          onDragEnter={(event) => handleDrag(event)}
          onDragLeave={(event) => handleDrag(event)}
          onDragOver={(event) => handleDrag(event)}
          onDrop={(event) => handleDrop(event)}
        />
      )}
      {fileUrl && (
        <button className="btn-clear" onClick={() => onUpload("")}>
          <img height={44} width={44} src={LogoRemoveIcon.default} alt="clear" />
        </button>
      )}
    </div>
  );
};
