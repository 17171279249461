/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ErrorMessage, useFormikContext } from 'formik';
import { ErrorContainer } from '../../style';
import React, { useEffect, useState } from 'react';

interface Props {
  setIsChanged: (val: boolean) => void;
  values?: any;
}

const SetDates: React.FC<Props> = ({ values, setIsChanged }) => {
  const formikProps = useFormikContext();
  const [yearStart, setYearStart] = useState<string>((new Date().getFullYear()).toString());
  const [monthStart, setMonthStart] = useState<string>((new Date().getMonth() + 1).toString());
  const [dayStart, setDayStart] = useState<string>((new Date().getDate()).toString());
  const [hourStart, setHourStart] = useState<string>((new Date().getHours()).toString());
  const [minStart, setMinStart] = useState<string>((new Date().getMinutes()).toString());
  const [secStart, setSecStart] = useState<string>((new Date().getSeconds()).toString());
  const [yearEnd, setYearEnd] = useState<string>((new Date().getFullYear()).toString());
  const [monthEnd, setMonthEnd] = useState<string>((new Date().getMonth() + 1).toString());
  const [dayEnd, setDayEnd] = useState<string>((new Date().getDate()).toString());
  const [hourEnd, setHourEnd] = useState<string>((new Date().getHours()).toString());
  const [minEnd, setMinEnd] = useState<string>((new Date().getMinutes()).toString());
  const [secEnd, setSecEnd] = useState<string>((new Date().getSeconds()).toString());

  useEffect(() => {
    setYearStart((new Date(values.startDate).getFullYear()).toString());
    setMonthStart((new Date(values.startDate).getMonth() + 1).toString());
    setDayStart((new Date(values.startDate).getDate()).toString());
    setHourStart((new Date(values.startDate).getHours()).toString());
    setMinStart((new Date(values.startDate).getMinutes()).toString());
    setSecStart((new Date(values.startDate).getSeconds()).toString());
    setYearEnd((new Date(values.endDate).getFullYear()).toString());
    setMonthEnd((new Date(values.endDate).getMonth() + 1).toString());
    setDayEnd((new Date(values.endDate).getDate()).toString());
    setHourEnd((new Date(values.endDate).getHours()).toString());
    setMinEnd((new Date(values.endDate).getMinutes()).toString());
    setSecEnd((new Date(values.endDate).getSeconds()).toString());
  }, []);

  const generateValues = (min: number, max: number, type: string, dateType: string) => {
    const items = [];
    if (dateType === 'start') {
      for (let i = min; i < max; i++) {
        const item = i.toString();
        items.push(
          <option
            key={i + '00'}
            value={item}
          >
            {item.length > 1 ? item : 0 + item}
          </option>
        );
      }
      return items;
    } else {
      for (let i = min; i < max; i++) {
        const item = i.toString();
        items.push(
          <option
            key={i + '00'}
            value={item}
          >
            {item.length > 1 ? item : 0 + item}
          </option>
        );
      }
      return items;
    }
  }

  const handleChange = (
    e: React.ChangeEvent<HTMLSelectElement>,
    methods: any
  ) => {
    methods(e.target.value);
    setIsChanged(true);
  };

  useEffect(() => {
    const startDate = new Date(
      +yearStart,
      +monthStart - 1,
      +dayStart,
      +hourStart,
      +minStart,
      +secStart,
      new Date().getMilliseconds()
    ).toISOString();
    formikProps.setFieldValue('startDate', startDate, true);
  }, [yearStart, monthStart, dayStart, hourStart, minStart, secStart]);

  useEffect(() => {
    const endDate = new Date(
      +yearEnd,
      +monthEnd - 1,
      +dayEnd,
      +hourEnd,
      +minEnd,
      +secEnd,
      new Date().getMilliseconds()
    ).toISOString();
    formikProps.setFieldValue('endDate', endDate, true);
  }, [yearEnd, monthEnd, dayEnd, hourEnd, minEnd, secEnd]);

  return (
    <div className="pool-step-01">
      <div className="pool-name-box">
        <div className="set-date-info-box">
          <div className="info-icon-box"></div>
          <span className="set-date-text">
            Start Date must be 60 min from now, and end date must be 60 mins
            from start date. Check with your timezone.
          </span>
        </div>
        <h5>Start Date</h5>

        <ErrorContainer>
          <ErrorMessage name="startDate" />
        </ErrorContainer>

        <div className="set-dates-grid">
          <div className="step-form-control ">
            <select
              className="submit-form-control"
              value={yearStart}
              onChange={(e) => handleChange(e, setYearStart)}
            >
              <option value="" selected disabled hidden>
                Year
              </option>
              <option value="2023">2023</option>
              <option value="2024">2024</option>
              <option value="2025">2025</option>
              <option value="2026">2026</option>
              <option value="2027">2027</option>
              <option value="2028">2028</option>
              <option value="2029">2029</option>
              <option value="2030">2030</option>
            </select>
          </div>
          <div className="step-form-control">
            <select
              className="submit-form-control"
              value={monthStart}
              onChange={(e) => handleChange(e, setMonthStart)}
            >
              <option value="" selected disabled hidden>
                Month
              </option>
              {generateValues(1, 13, 'month', 'start')}
            </select>
          </div>
          <div className="step-form-control">
            <select
              className="submit-form-control"
              value={dayStart}
              onChange={(e) => handleChange(e, setDayStart)}
            >
              <option value="" selected disabled hidden>
                Day
              </option>
              {generateValues(1, 32, 'day', 'start')}
            </select>
          </div>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={hourStart}
              onChange={(e) => handleChange(e, setHourStart)}
            >
              {generateValues(0, 24, 'hour', 'start')}
            </select>
          </div>
          <span className="delimiter">:</span>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={minStart}
              onChange={(e) => handleChange(e, setMinStart)}
            >
              {generateValues(0, 60, 'min', 'start')}
            </select>
          </div>
          <span className="delimiter">:</span>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={secStart}
              onChange={(e) => handleChange(e, setSecStart)}
            >
              {generateValues(0, 60, 'sec', 'start')}
            </select>
          </div>
        </div>
        <h5>UTC End Date</h5>

        <ErrorContainer>
          <ErrorMessage name="endDate" />
        </ErrorContainer>

        <div className="set-dates-grid">
          <div className="step-form-control">
            <select
              className="submit-form-control"
              value={yearEnd}
              onChange={(e) => handleChange(e, setYearEnd)}
            >
              <option value="" selected disabled hidden>
                Year
              </option>
              <option selected={new Date(values.endDate).getFullYear() === 2023} value="2023">2023</option>
              <option selected={new Date(values.endDate).getFullYear() === 2024} value="2024">2024</option>
              <option selected={new Date(values.endDate).getFullYear() === 2025} value="2025">2025</option>
              <option selected={new Date(values.endDate).getFullYear() === 2026} value="2026">2026</option>
              <option selected={new Date(values.endDate).getFullYear() === 2027} value="2027">2027</option>
              <option selected={new Date(values.endDate).getFullYear() === 2028} value="2028">2028</option>
              <option selected={new Date(values.endDate).getFullYear() === 2029} value="2029">2029</option>
              <option selected={new Date(values.endDate).getFullYear() === 2030} value="2030">2030</option>
            </select>
          </div>
          <div className="step-form-control">
            <select
              className="submit-form-control"
              value={monthEnd}
              onChange={(e) => handleChange(e, setMonthEnd)}
            >
              <option value="" selected disabled hidden>
                Month
              </option>
              {generateValues(1, 13, 'month', 'end')}
            </select>
          </div>
          <div className="step-form-control">
            <select
              className="submit-form-control"
              value={dayEnd}
              onChange={(e) => handleChange(e, setDayEnd)}
            >
              <option value="" selected disabled hidden>
                Day
              </option>
              {generateValues(1, 32, 'day', 'end')}
            </select>
          </div>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={hourEnd}
              onChange={(e) => handleChange(e, setHourEnd)}
            >
              {generateValues(0, 24, 'hour', 'end')}
            </select>
          </div>
          <span className="delimiter">:</span>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={minEnd}
              onChange={(e) => handleChange(e, setMinEnd)}
            >
              {generateValues(0, 60, 'min', 'end')}
            </select>
          </div>
          <span className="delimiter">:</span>
          <div className="step-form-control short">
            <select
              className="submit-form-control"
              value={secEnd}
              onChange={(e) => handleChange(e, setSecEnd)}
            >
              {generateValues(0, 60, 'sec', 'end')}
            </select>
          </div>
        </div>

        {/* <div>
          <div className="time-lock">
            <label htmlFor="myCheck" className="">
              <input
                type="checkbox"
                id="myCheck"
                checked={timeLock}
                onChange={timeLockHandle}
              />
              <span className="input"></span>
              Enable Time Lock
            </label>
            <div className="info-text">
              <span className="icon"></span>
              (Optional)
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default SetDates;
