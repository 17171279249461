import styled from 'styled-components';
import { Theme } from '../../../../utils';

export const DetailsWrapper = styled.div`
  padding-top: 10px;
`;

export const DetailContainer = styled.div`
  margin-top: 20px;
  padding: 30px 30px 23px 30px;
  background-color: ${Theme.headerBackground};
  border-radius: 8px;
  @media only screen and (max-width: 500px) {
    padding: 16px;
  }
`;

export const DetailHeader = styled.div`
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.1px;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  padding-bottom: 8px;
  @media only screen and (max-width: 500px) {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 10px;
  }
`;

export const DetailsBox = styled.div`
  display: flex;
  @media only screen and (max-width: 500px) {
    flex-direction: column;
  }
`;

export const DetailsBoxItem = styled.div`
  flex: 1;
  @media only screen and (max-width: 500px) {
    margin-bottom: 20px;
  }
`;

export const DetailsBoxItemHeaer = styled.div`
  font-size: 16px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: #7682a5;
  font-family: 'Open Sans', sans-serif;
  @media only screen and (max-width: 500px) {
    line-height: 24px;
  }
`;

export const DetailsBoxItemText = styled.div`
  margin-top: 7px;
  font-size: 20px;
  line-height: 40px;
  letter-spacing: 0.1px;
  color: #ffffff;
  font-family: 'Open Sans', sans-serif;
  @media only screen and (max-width: 500px) {
    margin-top: 4px;
    line-height: 26px;
  }
`;

export const DetailsBoxItemLine = styled.div`
  margin: 13px 0 6px 0;
  width: 100%;
  height: 1px;
  background: rgba(162, 184, 219, 0.15);
  @media only screen and (max-width: 500px) {
    display: none;
  }
`;
