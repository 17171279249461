import React, { useEffect, useState } from 'react';
import { CheckStatus, UncheckStatus } from '../../../images';
import { getPoolContract, getTokenContract } from '../../../utils';
import { useSelector } from 'react-redux';

interface Props {
  type?: number;
  status?: boolean;
  text?: string;
  url?: string;
  btnText?: string;
  iconStatus?: boolean;
  gameInfo: any;
  handleClick: (val: any) => void;
}

export const GameDetailContainer: React.FC<Props> = ({
  type,
  text,
  btnText,
  gameInfo,
  handleClick
}) => {
  const { userAddress, walletConnected } = useSelector(
    (store: any) => store.user
  );
  const [newButtonLabel, setButtonLabel] = useState('');
  const [newStatus, setNewStatus] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const updateSectionStatus = async () => {
    // const isBegin = await checkStarted();
    if (type === 1 && gameInfo.poolInfo && gameInfo.poolInfo?.poolAddress) {
      setButtonLabel('Deployed');
      setNewStatus(true);
      setButtonDisabled(true);
    }
    if (type === 2) {
      if (!gameInfo.isApproved) {
        setButtonDisabled(true);
      }
      // if (isBegin) {
      //   setButtonDisabled(true);
      // }
      setButtonLabel('Edit Pool');
      setNewStatus(true);
    }
    if (type === 3 && gameInfo.poolInfo && gameInfo.poolInfo?.tokenAddress) {
      const approval = await getTokenContract(gameInfo.poolInfo?.tokenAddress)
        .methods.allowance(userAddress, gameInfo.poolInfo?.poolAddress)
        .call();
        console.log('approval', approval);
      const fundStatus = await checkTokenBalanceStatus();
      if (approval > 0 || fundStatus) {
        setNewStatus(true);
        setButtonLabel('Done');
        setButtonDisabled(true);
      }
    }
    if (type === 4 && gameInfo.poolInfo && gameInfo.poolInfo?.poolAddress) {
      const fundStatus = await checkTokenBalanceStatus();
      if (fundStatus) {
        setNewStatus(true);
        setButtonLabel('Fund Added');
        setButtonDisabled(true);
      }
    }
    if (type === 5 && gameInfo.poolInfo && gameInfo.poolInfo?.poolAddress) {
      const setTierLimit = await getPoolContract(gameInfo.poolInfo?.poolAddress)
        .methods.isSetTierLimit()
        .call();
      if (setTierLimit) {
        setNewStatus(true);
        setButtonLabel('Already Set');
        setButtonDisabled(true);
      }
    }
    if (type === 6 && gameInfo.poolInfo && gameInfo.poolInfo?.treasureAddress) {
      setNewStatus(true);
      setButtonLabel('Already Set');
      setButtonDisabled(true);
    }
    // if (type === 7 && gameInfo.poolInfo && gameInfo.poolInfo?.feeAddress) {
    //   setNewStatus(true);
    //   setButtonLabel('Already Set');
    //   setButtonDisabled(true);
    // }
    // if (type === 8 && gameInfo.poolInfo && gameInfo.poolInfo?.feePercentage) {
    //   setNewStatus(true);
    //   setButtonLabel('Already Set');
    //   setButtonDisabled(true);
    // }
    if (
      (type === 9 || type === 10 || type === 11) &&
      gameInfo.poolInfo &&
      gameInfo.poolInfo?.poolAddress
    ) {
      const isBegin = await checkStarted();
      if (isBegin) {
        setNewStatus(true);
        setButtonLabel('View');
      }
    }
    if (
      (type === 12 || type === 13) &&
      gameInfo.poolInfo &&
      gameInfo.poolInfo?.poolAddress
    ) {
      const isFinished = await checkFinished();
      if (!isFinished) {
        setButtonDisabled(true);
      } else {
        setNewStatus(true);
        setButtonLabel('Withdraw');
      }
    }
  };

  const checkFinished = async () => {
    const isFinished = await getPoolContract(gameInfo.poolInfo?.poolAddress)
      .methods.hasFinalized()
      .call();

    return isFinished;
  };

  const checkStarted = async () => {
    const isBegin = await getPoolContract(gameInfo.poolInfo?.poolAddress)
      .methods.hasStarted()
      .call();

    return isBegin;
  };

  const checkTreasuerAddress = async () => {
    const treasureAddress = await getPoolContract(gameInfo.poolInfo?.poolAddress)
      .methods.treasurerAddress().call();

    return treasureAddress !== "" || treasureAddress !== "0x";
  }

  const checkTokenBalanceStatus = async () => {
    return await getPoolContract(gameInfo.poolInfo?.poolAddress)
      .methods.isSaleFunded()
      .call();
  };
  useEffect(() => {
    if (gameInfo && walletConnected) {
      updateSectionStatus();
    }
  }, [gameInfo, walletConnected]);
  return (
    <div className="game-detail-row">
      <div className="game-detail-left">
        <img
          src={newStatus === true ? CheckStatus.default : UncheckStatus.default}
          alt="uncheck status icon"
        />
        <p>{text}</p>
      </div>
      <div className="game-detail-right">
        <button
          disabled={buttonDisabled}
          onClick={() => handleClick(type)}
          className={`btn-action-link ${buttonDisabled && 'noncursor-pointer'}`}
        >
          {newStatus ? newButtonLabel : btnText}
        </button>
      </div>
    </div>
  );
};
