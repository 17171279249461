import { Fragment, useState } from "react";
import {
  Header,
  ConnectCont,
  Divider,
} from "./style";
import { IconContainer } from "../connect/style";
import Loader from "src/components/loader";
import { WalletName } from "./style";
import { sepoliaLogo } from "src/images";

interface Props {
  show: boolean;
  closeModal: () => void;
}

const WrongNetwork = (props: Props) => {
  const { closeModal } = props;
  const [loading, setLoading] = useState(false);

  const handleChangeNetwork = () => {
    setLoading(true)
    if (window.ethereum) {
      // @ts-ignore
      window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [
                  {
                      chainId:
                          process.env.REACT_APP_NETWORK_ID_HEX,
                  },
              ],
          })
          .then(() => {
            setLoading(false)
            closeModal();
          }).catch((error)=>{
            console.log(error)
          })
  }

  };

  return (
      <Fragment>
      <Header>Change Network</Header>
      <Divider/>
      <IconContainer className="flex-column align-items-center mt-3">
        <div className="alert alert-danger p-1 px-3" role="alert">
        Wrong Network
        </div>
        <img src={sepoliaLogo.default} alt="wallet icon" width={100} />
        <WalletName className="metamask-text mt-1">Connect to Base</WalletName>
      </IconContainer>
      <ConnectCont className="mt-3">
          {loading ? <Loader /> : <button data-text="Connect" onClick={handleChangeNetwork}  className="btn-action-link" >Connect</button>}
      </ConnectCont>
    </Fragment>
  );
};

export default WrongNetwork;
