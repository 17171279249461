import styled from 'styled-components';

export const PageContainer = styled.div`
  position: relative;
  padding: 18em 0 0 0;

  @media screen and (max-width: 991px) {
    padding-top: 12rem;
  }

  @media only screen and (max-width: 500px) {
    padding: 112px 17px;
  }
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1300px;
  margin: 0 auto;
  display: flex;
  gap: 40px;
  flex-wrap: wrap;
  justify-content: space-between;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PoolDetail = styled.div`
  flex: 0.48;

  @media screen and (max-width: 640px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

export const PoolInfoEl = styled.div`
  flex: 0.4;

  @media screen and (max-width: 640px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;
