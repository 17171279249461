import React from "react";
import axios from "axios";
import { LogoRemoveIcon, LogoUploadIcon } from "../../images";
import { endpoint_api } from "../../lib/network/apiClient";

interface Props {
  type?: "image" | "video";
  className?: string;
  imgUrl: string;
  label: string;
  options: Array<string>;
  text?: string;
  setMediaURL: (mediaURL: string) => void;
  mediaURL: string;
}

export const UploadLogoContainer: React.FC<Props> = ({
  className,
  label,
  options,
  text,
  setMediaURL,
  mediaURL,
}) => {
  const inputRef = React.useRef(null);

  const [dragActive, setDragActive] = React.useState(false);
  const [progress, setProgress] = React.useState(1);

  // handle drag events
  const handleDrag = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };

  // triggers when file is dropped
  const handleDrop = function (e: any) {
    e.preventDefault();
    e.stopPropagation();
    setDragActive(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files);
    }
  };

  // triggers when file is selected with click
  const handleChange = function (e: any) {
    e.preventDefault();
    if (e.target.files && e.target.files[0]) {
      handleFile(e.target.files);
    }
  };

  const handleFile = async (files: any) => {
    const formData = new FormData();
    formData.append("file", files[0]);
    formData.append("fileName", files[0].name);

    try {
      const result = await axios.post(
        `${endpoint_api}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: (data) => {
            //@ts-ignore Set the progress value to show the progress bar
            const percent = Math.round((data.loaded * 100) / data.total);
            setProgress(percent);
          },
        }
      );
      setMediaURL(decodeURIComponent(result.data));
    } catch (error) {
      alert("Please try again ");
    }
  };

  return (
    <>
      <div
        className={`upload-logo-container ${className ?? ""}`}
        id="form-file-upload"
        onDragEnter={handleDrag}
      >
        <div className="logo-container">
          {mediaURL ? (
            <>
              <div className="logo-container-media">
                <img src={mediaURL} alt="avatar" className="uploaded-img" />
                <img
                  src={LogoRemoveIcon.default}
                  alt="modal close icon"
                  height={24}
                  width={24}
                  onClick={() => {
                    setMediaURL("");
                    setProgress(1);
                  }}
                />
              </div>
            </>
          ) : (
            <label
              htmlFor="image-logo-upload"
              className={`logo-container-wrap ${
                dragActive ? "active" : ""
              }`}
            >
              {progress === 1 && (
                <img src={LogoUploadIcon.default} alt="upload logo" />
              )}
              <input
                ref={inputRef}
                type="file"
                id="image-logo-upload"
                multiple
                onChange={handleChange}
                accept="image/png, image/jpg, image/jpeg"
              />
              {progress > 1 && (
                <div className="container-wrap-progress">
                  <p>{progress} %</p>
                  <div className="progress">
                    <div
                      className="progress-bar"
                      style={{ width: `${progress}%` }}
                    />
                  </div>
                </div>
              )}
            </label>
          )}
        </div>
        <div className="right-container">
          <p className="upload-container-wrap-label">{label}</p>
          <div className="upload-container-wrap-option">
            {options &&
              options.map((item, index) => (
                <div
                  key={index}
                  className="upload-container-wrap-option-element"
                >
                  <div className="upload-container-wrap-option-rectangle"></div>
                  <p>{item}</p>
                </div>
              ))}
          </div>
          <p className="upload-container-wrap-text">{text}</p>
        </div>
        {dragActive && (
          <div
            id="drag-file-element"
            className="upload-container-drag absolute top-0 right-0 bottom-0 left-0 h-full w-full rounded"
            onDragEnter={handleDrag}
            onDragLeave={handleDrag}
            onDragOver={handleDrag}
            onDrop={handleDrop}
          />
        )}
      </div>
    </>
  );
};
