import {
  SELL_KNIGHT_ROYA_LOT_START,
  SELL_KNIGHT_ROYA_LOT_SUCCESS,
  SELL_KNIGHT_ROYA_LOT_FAILURE,
  SELL_ARCHON_ROYA_LOT_START,
  SELL_ARCHON_ROYA_LOT_SUCCESS,
  SELL_ARCHON_ROYA_LOT_FAILURE,
  SELL_MONARCH_ROYA_LOT_START,
  SELL_MONARCH_ROYA_LOT_SUCCESS,
  SELL_MONARCH_ROYA_LOT_FAILURE,
  BUY_KNIGHT_ROYA_LOT_SUCCESS,
  BUY_KNIGHT_ROYA_LOT_START,
  BUY_KNIGHT_ROYA_LOT_FAILURE,
  BUY_ARCHON_ROYA_LOT_START,
  BUY_ARCHON_ROYA_LOT_SUCCESS,
  BUY_ARCHON_ROYA_LOT_FAILURE,
  BUY_MONARCH_ROYA_LOT_START,
  BUY_MONARCH_ROYA_LOT_SUCCESS,
  BUY_MONARCH_ROYA_LOT_FAILURE,
  GET_USER_LOT_REWARD,
  GET_USER_TOTAL_LOTS_SUCCESS,
  CLAIM_QLOT_REWARD_START,
  CLAIM_QLOT_REWARD_SUCCESS,
  CLAIM_QLOT_REWARD_FAILURE,
  CLAIM_KLOT_REWARD_START,
  CLAIM_KLOT_REWARD_SUCCESS,
  CLAIM_KLOT_REWARD_FAILURE,
  CLAIM_FLOT_REWARD_START,
  CLAIM_FLOT_REWARD_SUCCESS,
  CLAIM_FLOT_REWARD_FAILURE,
  CLOSE_SUCCESS_MSG,
  LOGOUT_SUCCESS,
  GET_NFT_BALANCE_SUCCESS,
  SET_NFT_ID,
  GET_LOCKED_NFT_SUCCESS,
  GET_DISCOUNTED_QUEEN_LOT_SUCCESS,
  SELL_MERCHANT_ROYA_LOT_START,
  SELL_MERCHANT_ROYA_LOT_SUCCESS,
  SELL_MERCHANT_ROYA_LOT_FAILURE,
  BUY_MERCHANT_ROYA_LOT_START,
  BUY_MERCHANT_ROYA_LOT_SUCCESS,
  BUY_MERCHANT_ROYA_LOT_FAILURE,
  SELL_SETTLER_ROYA_LOT_START,
  SELL_SETTLER_ROYA_LOT_SUCCESS,
  SELL_SETTLER_ROYA_LOT_FAILURE,
  BUY_SETTLER_ROYA_LOT_START,
  BUY_SETTLER_ROYA_LOT_SUCCESS,
  BUY_SETTLER_ROYA_LOT_FAILURE,
  GET_LAST_BOUGHT_SETTLER_LOT_SUCCESS,
  GET_LAST_BOUGHT_MERCHANT_LOT_SUCCESS,
  GET_LAST_BOUGHT_KNIGHT_LOT_SUCCESS,
  GET_LAST_BOUGHT_ARCHON_LOT_SUCCESS,
  GET_LAST_BOUGHT_MONARCH_LOT_SUCCESS,
  GET_LAST_BOUGHT_ARCHON_NFT_LOT_SUCCESS,
  GET_LAST_BOUGHT_KNIGHT_NFT_LOT_SUCCESS,
  GET_LAST_BOUGHT_MERCHANT_NFT_LOT_SUCCESS,
  GET_LAST_BOUGHT_MONARCH_NFT_LOT_SUCCESS,
  GET_LAST_BOUGHT_SETTLER_NFT_LOT_SUCCESS,
} from "../actions";

import {
  SuccessMsgType,
  StakingLotModals,
  LotRewardProcess,
} from "../../utils";

const initialState = {
  totalKroyaLot: "0",
  totalMroyaLot: "0",
  totalAroyaLot: "0",
  totalSroyaLot: "0",
  totalMoroyaLot: "0",
  totalSNFTLot: "0",
  totalMNFTLot: "0",
  totalKNFTLot: "0",
  totalANFTLot: "0",
  totalMoNFTLot: "0",
  queenLotReward: "0",
  kinglotReward: "0",
  flushLotReward: "0",
  lotTypeBought: "",
  lotAmountBought: 0,
  lotTypeSold: "",
  lotAmountSold: 0,
  successMsgType: SuccessMsgType.hide,
  lotOperation: StakingLotModals.closed,
  claimingProcess: LotRewardProcess.idle,
  queenNft: [],
  nftId: 0,
  lockedNfts: [],
  sellQueenLotNftId: "",
  totalDiscountedQueenLot: "0",
  settlerLotbuy: "0",
  merchantLotbuy: "0",
  knightLotbuy: "0",
  archonLotbuy: "0",
  monarchLotbuy: "0",
  settlerNFTLotbuy: "0",
  merchantNFTLotbuy: "0",
  knightNFTLotbuy: "0",
  archonNFTLotbuy: "0",
  monarchNFTLotbuy: "0",
};

const stakingLotsReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case GET_LAST_BOUGHT_SETTLER_LOT_SUCCESS:
      return {
        ...state,
        settlerLotbuy: action.settlerlot,
      };

    case GET_LAST_BOUGHT_MERCHANT_LOT_SUCCESS:
      return {
        ...state,
        merchantLotbuy: action.merchantlot,
      };

    case GET_LAST_BOUGHT_KNIGHT_LOT_SUCCESS:
      return {
        ...state,
        knightLotbuy: action.knightlot,
      };

    case GET_LAST_BOUGHT_ARCHON_LOT_SUCCESS:
      return {
        ...state,
        archonLotbuy: action.archonlot,
      };

    case GET_LAST_BOUGHT_MONARCH_LOT_SUCCESS:
      return {
        ...state,
        monarchLotbuy: action.monarchlot,
      };

    case GET_LAST_BOUGHT_SETTLER_NFT_LOT_SUCCESS:
      return {
        ...state,
        settlerNFTLotbuy: action.settlerlot,
      };

    case GET_LAST_BOUGHT_MERCHANT_NFT_LOT_SUCCESS:
      return {
        ...state,
        merchantNFTLotbuy: action.merchantlot,
      };

    case GET_LAST_BOUGHT_KNIGHT_NFT_LOT_SUCCESS:
      return {
        ...state,
        knightNFTLotbuy: action.knightlot,
      };

    case GET_LAST_BOUGHT_ARCHON_NFT_LOT_SUCCESS:
      return {
        ...state,
        archonNFTLotbuy: action.archonlot,
      };

    case GET_LAST_BOUGHT_MONARCH_NFT_LOT_SUCCESS:
      return {
        ...state,
        monarchNFTLotbuy: action.monarchlot,
      };
    case SELL_KNIGHT_ROYA_LOT_START:
      return {
        ...state,
        lotTypeSold: "",
        lotAmountSold: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.sellKnight,
      };
    case SELL_MERCHANT_ROYA_LOT_START:
      return {
        ...state,
        lotTypeSold: "",
        lotAmountSold: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.sellMerchant,
      };
    case SELL_SETTLER_ROYA_LOT_START:
      return {
        ...state,
        lotTypeSold: "",
        lotAmountSold: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.sellSettler,
      };

    case SELL_ARCHON_ROYA_LOT_SUCCESS:
      return {
        ...state,
        lotTypeSold: "ARCHON ROYA",
        lotAmountSold: action.lotAmount,
        successMsgType: SuccessMsgType.showSellSuccess,
        lotOperation: StakingLotModals.closed,
        sellQueenLotNftId: "",
      };
    case SELL_MERCHANT_ROYA_LOT_SUCCESS:
      return {
        ...state,
        lotTypeSold: "MERCHNAT ROYA",
        lotAmountSold: action.lotAmount,
        successMsgType: SuccessMsgType.showSellSuccess,
        lotOperation: StakingLotModals.closed,
        sellQueenLotNftId: "",
      };

    case SELL_SETTLER_ROYA_LOT_SUCCESS:
      return {
        ...state,
        lotTypeSold: "SETTLER ROYA",
        lotAmountSold: action.lotAmount,
        successMsgType: SuccessMsgType.showSellSuccess,
        lotOperation: StakingLotModals.closed,
        sellQueenLotNftId: "",
      };

    case SELL_KNIGHT_ROYA_LOT_FAILURE:
      return {
        ...state,
        lotTypeSold: "",
        lotAmountSold: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.closed,
        sellQueenLotNftId: "",
      };
    case SELL_SETTLER_ROYA_LOT_FAILURE:
      return {
        ...state,
        lotTypeSold: "",
        lotAmountSold: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.closed,
        sellQueenLotNftId: "",
      };
    case SELL_MERCHANT_ROYA_LOT_FAILURE:
      return {
        ...state,
        lotTypeSold: "",
        lotAmountSold: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.closed,
        sellQueenLotNftId: "",
      };

    case SELL_ARCHON_ROYA_LOT_START:
      return {
        ...state,
        lotTypeSold: "",
        lotAmountSold: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.sellArchon,
      };

    case SELL_KNIGHT_ROYA_LOT_SUCCESS:
      return {
        ...state,
        lotTypeSold: "KNIGHT ROYA",
        lotAmountSold: action.lotAmount,
        successMsgType: SuccessMsgType.showSellSuccess,
        lotOperation: StakingLotModals.closed,
      };

    case SELL_ARCHON_ROYA_LOT_FAILURE:
      return {
        ...state,
        lotTypeSold: "",
        lotAmountSold: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.closed,
      };

    case SELL_MONARCH_ROYA_LOT_START:
      return {
        ...state,
        lotTypeSold: "",
        lotAmountSold: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.sellMonarch,
      };

    case SELL_MONARCH_ROYA_LOT_SUCCESS:
      return {
        ...state,
        lotTypeSold: "MONARCH ROYA",
        lotAmountSold: action.lotAmount,
        successMsgType: SuccessMsgType.showSellSuccess,
        lotOperation: StakingLotModals.closed,
      };

    case SELL_MONARCH_ROYA_LOT_FAILURE:
      return {
        ...state,
        lotTypeSold: "",
        lotAmountSold: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.closed,
      };

    case BUY_ARCHON_ROYA_LOT_START:
      return {
        ...state,
        lotTypeBought: "",
        lotAmountBought: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.buyArchon,
      };
    case BUY_MERCHANT_ROYA_LOT_SUCCESS:
      return {
        ...state,
        lotTypeBought: "MERCHANT ROYA",
        lotAmountBought: action.lotAmount,
        successMsgType: SuccessMsgType.showBuySuccess,
        lotOperation: StakingLotModals.closed,
      };

    case BUY_MERCHANT_ROYA_LOT_FAILURE:
      return {
        ...state,
        successMsgType: SuccessMsgType.hide,
        lotTypeBought: "",
        lotAmountBought: 0,
        lotOperation: StakingLotModals.closed,
      };

    case BUY_MERCHANT_ROYA_LOT_START:
      return {
        ...state,
        successMsgType: SuccessMsgType.hide,
        lotTypeBought: "",
        lotAmountBought: 0,
        lotOperation: StakingLotModals.buymerchant,
      };

    case BUY_SETTLER_ROYA_LOT_SUCCESS:
      return {
        ...state,
        lotTypeBought: "SETTLER ROYA",
        lotAmountBought: action.lotAmount,
        successMsgType: SuccessMsgType.showBuySuccess,
        lotOperation: StakingLotModals.closed,
      };

    case BUY_SETTLER_ROYA_LOT_FAILURE:
      return {
        ...state,
        successMsgType: SuccessMsgType.hide,
        lotTypeBought: "",
        lotAmountBought: 0,
        lotOperation: StakingLotModals.closed,
      };

    case BUY_SETTLER_ROYA_LOT_START:
      return {
        ...state,
        successMsgType: SuccessMsgType.hide,
        lotTypeBought: "",
        lotAmountBought: 0,
        lotOperation: StakingLotModals.buysettler,
      };

    case BUY_KNIGHT_ROYA_LOT_SUCCESS:
      return {
        ...state,
        lotTypeBought: "KNIGHT ROYA",
        lotAmountBought: action.lotAmount,
        successMsgType: SuccessMsgType.showBuySuccess,
        lotOperation: StakingLotModals.closed,
      };

    case BUY_KNIGHT_ROYA_LOT_FAILURE:
      return {
        ...state,
        successMsgType: SuccessMsgType.hide,
        lotTypeBought: "",
        lotAmountBought: 0,
        lotOperation: StakingLotModals.closed,
      };

    case BUY_KNIGHT_ROYA_LOT_START:
      return {
        ...state,
        successMsgType: SuccessMsgType.hide,
        lotTypeBought: "",
        lotAmountBought: 0,
        lotOperation: StakingLotModals.buyKnight,
      };

    case BUY_ARCHON_ROYA_LOT_SUCCESS:
      return {
        ...state,
        successMsgType: SuccessMsgType.showBuySuccess,
        lotTypeBought: "ARCHON ROYA",
        lotAmountBought: action.lotAmount,
        lotOperation: StakingLotModals.closed,
      };

    case BUY_ARCHON_ROYA_LOT_FAILURE:
      return {
        ...state,
        successMsgType: SuccessMsgType.hide,
        lotTypeBought: "",
        lotAmountBought: 0,
        lotOperation: StakingLotModals.closed,
      };

    case BUY_MONARCH_ROYA_LOT_START:
      return {
        ...state,
        successMsgType: SuccessMsgType.hide,
        lotTypeBought: "",
        lotAmountBought: 0,
        lotOperation: StakingLotModals.buyMonarch,
      };

    case BUY_MONARCH_ROYA_LOT_SUCCESS:
      return {
        ...state,
        successMsgType: SuccessMsgType.showBuySuccess,
        lotTypeBought: "MONARCH ROYA",
        lotAmountBought: action.lotAmount,
        lotOperation: StakingLotModals.closed,
      };

    case BUY_MONARCH_ROYA_LOT_FAILURE:
      return {
        ...state,
        lotTypeBought: "",
        lotAmountBought: 0,
        successMsgType: SuccessMsgType.hide,
        lotOperation: StakingLotModals.closed,
      };

    case GET_USER_LOT_REWARD:
      return {
        ...state,
        queenLotReward: action.queenLotReward,
        kinglotReward: action.kinglotReward,
        flushLotReward: action.flushLotReward,
      };

    case GET_USER_TOTAL_LOTS_SUCCESS:
      return {
        ...state,
        totalKroyaLot: action.qLot,
        totalAroyaLot: action.kLot,
        totalMoroyaLot: action.fLot,
        totalSroyaLot: action.sLot,
        totalMroyaLot: action.mLot,
        totalSNFTLot: action.sNFTLot,
        totalMNFTLot: action.mNFTLot,
        totalKNFTLot: action.kNFTLot,
        totalANFTLot: action.aNFTLot,
        totalMoNFTLot: action.moNFTLot,
      };

    case CLOSE_SUCCESS_MSG:
      return {
        ...state,
        successMsgType: SuccessMsgType.hide,
      };

    case CLAIM_QLOT_REWARD_START:
      return {
        ...state,
        claimingProcess: LotRewardProcess.qlotReward,
      };

    case CLAIM_QLOT_REWARD_SUCCESS:
      return {
        ...state,
        claimingProcess: LotRewardProcess.idle,
      };

    case CLAIM_QLOT_REWARD_FAILURE:
      return {
        ...state,
        claimingProcess: LotRewardProcess.idle,
      };

    case CLAIM_KLOT_REWARD_START:
      return {
        ...state,
        claimingProcess: LotRewardProcess.klotReward,
      };

    case CLAIM_KLOT_REWARD_SUCCESS:
      return {
        ...state,
        claimingProcess: LotRewardProcess.idle,
      };

    case CLAIM_KLOT_REWARD_FAILURE:
      return {
        ...state,
        claimingProcess: LotRewardProcess.idle,
      };

    case CLAIM_FLOT_REWARD_START:
      return {
        ...state,
        claimingProcess: LotRewardProcess.flotReward,
      };

    case CLAIM_FLOT_REWARD_SUCCESS:
      return {
        ...state,
        claimingProcess: LotRewardProcess.idle,
      };

    case CLAIM_FLOT_REWARD_FAILURE:
      return {
        ...state,
        claimingProcess: LotRewardProcess.idle,
      };

    case GET_NFT_BALANCE_SUCCESS:
      return {
        ...state,
        queenNft: action.queenNft,
      };

    case LOGOUT_SUCCESS:
      return {
        ...state,
        totalKroyaLot: "0",
        totalAroyaLot: "0",
        totalMoroyaLot: "0",
        totalSroyaLot: "0",
        totalMroyaLot: "0",
        totalQNFTLot: "0",
        totalKNFTLot: "0",
        totalFNFTLot: "0",
        totalSNFTLot: "0",
        totalMNFTLot: "0",
        queenNft: [],
        nftId: 0,
      };

    case SET_NFT_ID:
      return {
        ...state,
        nftId: action.nftId,
      };

    case GET_LOCKED_NFT_SUCCESS:
      return {
        ...state,
        lockedNfts: action.lockedNfts,
      };

    case GET_DISCOUNTED_QUEEN_LOT_SUCCESS:
      return {
        ...state,
        totalDiscountedQueenLot: action.totalDiscountedQueenLot,
      };

    default:
      return state;
  }
};

export default stakingLotsReducer;
